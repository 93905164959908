import React from "react";
import Modal from "../../../../../common-components/Modal";
import backArrow from "../../../../../../images/back_arrow.svg";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";

const SelectedOPconsultation = ({
  isSelectedRecord,
  setIsSelectedRecord,
  opConsultation,
  name,
}) => {
  const {
    allergy,
    dateCreated,
    diagnosis,
    followUpDate,
    history,
    observationVitals,
    prescription,
    symptoms,
    treatmentPlan,
  } = opConsultation || {};

  return (
    <div>
      <Modal
        showModal={isSelectedRecord}
        onClose={() => setIsSelectedRecord(false)}
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
      >
        <div className="m-4 text-sm text-[#2D2E33]">
          <div className="flex justify-between w-[60%]">
            <img src={backArrow} alt="backArrow" />
            <div>{name} (OP Consultation)</div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Vaccination</div>

            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex">
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Allergy : </div>
              <div className="ms-2">{allergy}</div>
            </div>
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">History : </div>
              <div className="ms-2">{history}</div>
            </div>
          </div>
          <div className="flex my-2">
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Symptoms : </div>
              <div className="ms-2">{symptoms}</div>
            </div>
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Observations/Vitals : </div>
              <div className="ms-2">{observationVitals}</div>
            </div>
          </div>

          <div className="flex my-2">
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Diagnosis : </div>
              <div className="ms-2">{diagnosis}</div>
            </div>
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Treatment Plan : </div>
              <div className="ms-2">{treatmentPlan}</div>
            </div>
          </div>
          <div className="flex my-2">
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Prescription : </div>
              <div className="ms-2">{prescription}</div>
            </div>
            <div className="flex w-1/2 flex-wrap">
              <div className="text-[#5E6066]">Follow up Date : </div>
              <div className="ms-2">{followUpDate}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectedOPconsultation;

import React from "react";
import MedicalNote from "../../../../images/Note.svg";
import Vital from "../../../../images/Vital.svg";
import Rx from "../../../../images/Rx.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDoctorTab } from "../../../../redux-store/slice/activeDoctorTab";

const DoctorSideBar = () => {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.activeDoctorTab.activeTab);

  const handleActiveTab = (tab) => {
    dispatch(setActiveDoctorTab(tab));
  };

  return (
    <div
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
      className="bg-white border-l-[#E7F4FF] border mt-4 border-t-0 border-r-0 ml-5 h-full"
    >
      <div
        className={`p-4 flex justify-center ${
          activeTab === "medical-note"
            ? "bg-[#F3F4F6] border-[#FACA15] border-l-2"
            : ""
        }`}
      >
        <button
          onClick={() =>
            handleActiveTab(
              activeTab === "medical-note" ? null : "medical-note"
            )
          }
        >
          <img src={MedicalNote} alt="medical-note" />
        </button>
      </div>
      <div
        className={`p-4 flex justify-center ${
          activeTab === "vital"
            ? "bg-[#F3F4F6] border-[#E74694] border-l-2"
            : ""
        }`}
      >
        <button
          onClick={() =>
            handleActiveTab(activeTab === "vital" ? null : "vital")
          }
        >
          <img src={Vital} alt="Vital" />
        </button>
      </div>
      <div
        className={`p-4  items-center flex justify-center ${
          activeTab === "Rx" ? "bg-[#F3F4F6] border-[#5850EC] border-l-2" : ""
        }`}
      >
        <button
          onClick={() => handleActiveTab(activeTab === "Rx" ? null : "Rx")}
        >
          <img src={Rx} alt="Rx" />
        </button>
      </div>
      <hr className="px-4 mt-1"></hr>
      {/* <div
        className={`p-4 flex justify-center ${
          activeTab === "user" ? "bg-[#F3F4F6] border-[#1C64F2] border-l-2" : ""
        }`}
      >
        <button
          onClick={() => handleActiveTab(activeTab === "user" ? null : "user")}
        >
          <img src={User} alt="User" />
        </button>
      </div> */}
    </div>
  );
};

export default DoctorSideBar;

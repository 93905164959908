import React, { useState } from "react";

import moment from "moment";
import Modal from "../../../../common-components/Modal";
import useUpdateDoctorData from "./hooks/useUpdateDoctorData";

const AddAwardsModal = ({ isAddAward, setIsAddAward }) => {
  const [addAward, setAddAward] = useState({
    title: "",
    institution: "",
    yearOfRecognition: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    if (name === "yearOfRecognition") {
      const newValue = moment(value).format("yyyy-MM");
      setAddAward((prev) => ({ ...prev, [name]: newValue }));
    } else {
      setAddAward((prev) => ({ ...prev, [name]: value }));
    }
  };

  const { onAddExperiance = () => {}, isAddExperiance = false } =
    useUpdateDoctorData() || {};

  return (
    <div>
      <Modal
        showModal={isAddAward}
        modalWidth="w-1/2"
        modalHeight="min-h-1/2"
        onClose={() => setIsAddAward(false)}
      >
        <div className="px-5 py-3 font-semibold text-[#222222]">Awards</div>
        <div className="px-5">
          <div className="text-sm mb-2 w-full flex gap-4">
            <div className="w-1/2">
              <div className="text-[#111928]">Title</div>
              <div className="mt-1">
                <input
                  name="title"
                  value={addAward.title}
                  onChange={handleChange}
                  type="text"
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-[#111928]">Institution</div>
              <div className="mt-1">
                <input
                  type="text"
                  name="hospitalName"
                  onChange={handleChange}
                  value={addAward.hospitalName}
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
          </div>
          <div className="text-sm mb-2 w-full flex gap-4">
            <div className="w-1/2">
              <div className="text-[#111928]">Year Of Recognition</div>
              <div className="mt-1 flex gap-5">
                <input
                  name="yearOfRecognition"
                  value={addAward.yearOfRecognition}
                  onChange={handleChange}
                  type="month"
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
          </div>

          <div className="flex py-5 text-sm justify-between">
            <button
              onClick={() => setAddAward(false)}
              className="border-[#D1D5DB] border rounded-lg text-[#444444] px-5 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => onAddExperiance(addAward)}
              disabled={isAddExperiance}
              className="bg-[#3F83F8] rounded-lg disabled:text-[#6B7280] text-white px-5 py-2"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddAwardsModal;

import React, { memo, useContext } from "react";
import Modal from "../../../../common-components/Modal";
import ParchaaHIP from "../../../../../images/parchaaHip.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Context } from "./DoctorAccount";

const DoctorPreview = ({ openPreview, setOpenPreview }) => {
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};
  const {
    aboutMe,
    patientInteraction,
    firstName,
    lastName,
    professionalDeatils,
  } = doctorData || {};
  const {
    experiences = [],
    qualifications = [],
    awards: award = [],
    speciality,
    // language,
    registrationNumber,
  } = professionalDeatils || {};

  const { services = [], offices = [] } = patientInteraction || {};

  return (
    <div>
      <Modal
        modalHeight="h-[95%]"
        modalWidth="w-[50%]"
        showModal={openPreview}
        onClose={() => setOpenPreview(false)}
      >
        <div className="border-t flex mt-11 overflow-y-scroll">
          <div className="w-[60%] mx-6 py-4">
            <div className="flex gap-4">
              <div>
                <img src={ParchaaHIP} alt="ParchaaHIP" height={50} width={50} />
              </div>
              <div>
                <div className="text-[#111928] text-lg font-semibold">
                  {firstName} {lastName}
                </div>
                <div className="text-sm text-[#6B7280] font-light">
                  MD - General Physician
                </div>
                <div className="text-sm text-[#6B7280] font-light">
                  ID : {registrationNumber}
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">About me</div>
              <div className="text-[#6B7280] text-xs font-light mt-1">
                {aboutMe}
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">Experience</div>
              <div className="mt-1">
                {(experiences || []).map((item, index) => {
                  const { title, type, endYear, location, startYear } =
                    item || {};
                  return (
                    <div key={index} className="text-sm pb-1 last:border-b">
                      <div>{title}</div>
                      <div className="text-[#6B7280] text-xs font-light">
                        {type}
                      </div>
                      <div className="text-[#6B7280] text-xs font-light">
                        {location}
                      </div>
                      <div className="text-[#6B7280] text-xs font-light">
                        {startYear} - {endYear}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">Education</div>
              <div className="mt-1">
                {(qualifications || []).map((item, index) => {
                  const { degree, institutionName, yearOfCompletion } =
                    item || {};
                  return (
                    <div key={index} className="text-sm pb-1 last:border-b">
                      <div> {institutionName}</div>
                      <div className="text-[#6B7280] text-xs font-light">
                        {degree} - {yearOfCompletion}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">
                Awards and Recognization
              </div>
              <div className="mt-1">
                {(award || []).map((item, index) => {
                  const { institution, title, yearOfRecognition } = item || {};
                  return (
                    <div key={index} className="text-sm pb-1 last:border-b">
                      <div> {title}</div>
                      <div className="text-[#6B7280] text-xs font-light">
                        {institution} - {yearOfRecognition}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">Specialties</div>
              <div className="mt-1">
                {(speciality || []).map((item, index) => {
                  return (
                    <div key={index} className="text-sm pb-1 last:border-b">
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-[40%] mx-6">
            <div className="my-4">
              <div className="text-[#111928] font-medium">Services</div>
              <div className="mt-1">
                {(services || []).map((item, index) => {
                  // const { serviceType } = item || {};
                  return (
                    <div
                      key={index}
                      className="text-sm w-fit bg-[#E1EFFE] text-[#1E429F] rounded-md px-2 last:border-b"
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-4 ">
              <div className="text-[#111928] font-medium">Office</div>
              <div className="mt-1">
                {(offices || []).map((item, index) => {
                  const { buildingName, addressLine, officePhoneNo } =
                    item || {};
                  return (
                    <div key={index} className="text-sm pb-1 last:border-b">
                      <div> {buildingName}</div>
                      <div className="text-[#6B7280] mt-1 items-center flex gap-2 text-xs font-light">
                        <FontAwesomeIcon icon={faPhone} />
                        Contact
                      </div>
                      <div className="text-[#111928] flex gap-2 text-xs font-light">
                        {officePhoneNo}
                      </div>
                      <div className="text-[#6B7280] mt-1 items-center flex gap-2 text-xs font-light">
                        <FontAwesomeIcon icon={faLocationDot} /> Address
                      </div>
                      <div className="text-[#111928] flex gap-2 text-xs font-light">
                        {addressLine}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-4">
              <div className="text-[#111928] font-medium">Language Spoken</div>
              <div className="mt-1">
                <div className="text-sm pb-1 last:border-b">
                  {/* {language} */}
                  English, Hindi
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(DoctorPreview);

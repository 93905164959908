import React, { createContext, useState } from "react";
import defaultDoctorPhoto from "../../../../../images/parchaaHip.svg";
import badge from "../../../../../images/badge-check.svg";
import eye from "../../../../../images/eye.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import DoctorMyProfile from "./DoctorMyProfile";
import ProfessionalDetails from "./ProfessionalDetails";
import PatientInteraction from "./PatientInteraction";
import DoctorPreview from "./DoctorPreview";
import useGetDoctor from "./hooks/useGetDoctor";

export const Context = createContext();

const DoctorAccount = () => {
  const [activeAccountTab, setActiveAccountTab] = useState("my-profile");
  const [openPreview, setOpenPreview] = useState(false);
  const { isDcotorDataLoading, doctorData } = useGetDoctor();
  const { firstName, lastName } = doctorData || {};

  return (
    <Context.Provider value={{ isDcotorDataLoading, doctorData }}>
      <div>
        <div
          className="m-8 px-4 pt-4 rounded-md"
          style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
        >
          <div className="flex gap-5">
            <div className="ms-4">
              <img
                src={defaultDoctorPhoto}
                alt="defaultDoctorPhoto"
                height={100}
                width={100}
              />
            </div>
            <div>
              <div className="text-[#111928] flex gap-2 items-center font-medium">
                Dr. {firstName} {lastName}
                <img src={badge} alt="badge" />
              </div>
              <div className="text-[#6B7280] text-sm font-normal">
                General Physician
              </div>
              <div className="flex w-full gap-4 mt-3">
                <button className=" bg-[#1A56DB] disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] border border-[#1A56DB] px-3 py-1.5 rounded-lg text-sm text-[#FFFFFF]">
                  <FontAwesomeIcon icon={faCloudArrowUp} className="me-2" />
                  Change picture
                </button>
                <button
                  onClick={() => setOpenPreview(true)}
                  className="flex border items-center justify-center
                    bg-white gap-2 border-[#E5E7EB] item-center px-3 py-1.5 rounded-lg
                    text-sm text-[#1F2A37]"
                >
                  <img src={eye} alt="eye" />
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 ms-3">
            <button
              className={`${
                activeAccountTab === "my-profile"
                  ? "text-[#1C64F2] border-b border-[#1C64F2]"
                  : "text-[#6B7280]"
              } text-sm w-[15%] me-3 py-3`}
              onClick={() => setActiveAccountTab("my-profile")}
            >
              My Profile
            </button>
            <button
              className={`${
                activeAccountTab === "professional-details"
                  ? "text-[#1C64F2] border-b border-[#1C64F2]"
                  : "text-[#6B7280]"
              } text-sm w-[15%] me-3 py-3`}
              onClick={() => setActiveAccountTab("professional-details")}
            >
              Professional Details
            </button>
            <button
              className={`${
                activeAccountTab === "patient-interaction"
                  ? "text-[#1C64F2] border-b border-[#1C64F2]"
                  : "text-[#6B7280]"
              } text-sm w-[15%] me-3 py-3`}
              onClick={() => setActiveAccountTab("patient-interaction")}
            >
              Patient Interaction
            </button>
          </div>
        </div>
        {activeAccountTab === "my-profile" && <DoctorMyProfile />}
        {activeAccountTab === "professional-details" && <ProfessionalDetails />}
        {activeAccountTab === "patient-interaction" && <PatientInteraction />}
        {openPreview ? (
          <DoctorPreview
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
          />
        ) : null}
      </div>
    </Context.Provider>
  );
};

export default DoctorAccount;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Navbar from "../../navbar/Navbar";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import PatientTab from "./PatientTab";
import RequestConsentModal from "./RequestConsentModal";
import AbhaRecords from "./AbhaRecords";
import useGetPatientDetails from "./hooks/useGetPatientDetails";
import Notes from "./notes/Notes";
import Invoice from "./invoice/Invoice";

const PatientProfile = () => {
  const navigate = useNavigate();
  const [activeProfileTab, setActiveProfileTab] = useState("Overview");
  const [requestConsent, setRequestConsent] = useState(false);

  const { patientData } = useGetPatientDetails();

  return (
    <div className=" relative">
      <div className="p-4 z-50 bg-white flex fixed w-full border-b shadow-sm">
        <button onClick={() => navigate("/welcome")}>
          <FontAwesomeIcon icon={faLongArrowLeft} color="#6B7280" />
        </button>
        <div className="ms-12 text-[#111928]"> Patient Profile</div>
      </div>
      <div className="flex bg-white z-0 py-14">
        <div
          className="border-l border h-screen fixed"
          style={{ boxShadow: "0 0 1px rgb(44 62 80 / 20%)" }}
        >
          <Navbar />
        </div>
        <div className="ml-auto w-[95.8%]">
          <PatientTab
            setRequestConsent={setRequestConsent}
            activeProfileTab={activeProfileTab}
            patientData={patientData}
            setActiveProfileTab={setActiveProfileTab}
          />
          {activeProfileTab === "abha" ? <AbhaRecords /> : null}
          {activeProfileTab === "Notes" ? <Notes /> : null}
          {activeProfileTab === "Invoice" ? <Invoice /> : null}
        </div>
      </div>
      {requestConsent ? (
        <RequestConsentModal
          requestConsent={requestConsent}
          setRequestConsent={setRequestConsent}
        />
      ) : null}
    </div>
  );
};

export default PatientProfile;

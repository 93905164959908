import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setDashboardDrawer } from "../../../../../../../redux-store/slice/checkInDrawerSlice";

const useAddPatientWithoutAbha = ({
  patientInfo,
  setPatientInfo = () => {},
  setPatient = () => {},
}) => {
  const dispatch = useDispatch();
  const [isAddPatientLoading, setIsAddPatientLoading] = useState(false);

  const onAddNew = async (e) => {
    e.preventDefault();
    setIsAddPatientLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/naruto/patient/register/withoutAbhaId`,
        patientInfo,
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );
      setPatient(res.data);
      toast.success("Patient added successfully !", { position: "top-center" });
      setTimeout(() => {
        setPatientInfo({
          name: "",
          phoneNumber: "",
          age: "",
          dateOfBirth: "",
          addressLine: "",
          district: "",
          city: "",
          pincode: "",
          email: "",
          gender: "",
          referBy: "",
          referByPhone: "",
          bloodGroup: "",
        });
      }, 1000);
      setTimeout(() => {
        dispatch(
          setDashboardDrawer({
            skipABHA: false,
            checkInDrawer: true,
            userDrawer: false,
          })
        );
      }, 1000);
    } catch (error) {
      console.error(error, "error");
      toast.error("Opps somthing went wrong");
    }
    setIsAddPatientLoading(false);
  };
  return {
    onAddNew,
    isAddPatientLoading,
  };
};

export default useAddPatientWithoutAbha;

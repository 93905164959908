import React from "react";
import Content from "./Content";
import Navbar from "../navbar/Navbar";
import Header from "../hip/header/Header";

const Welcome = () => {
  return (
    <div className="w-full h-screen relative">
      <div className="relative z-50">
        <Header />
      </div>
      <div className="flex pt-14">
        <div className="fixed w-fit h-screen shadow">
          <Navbar />
        </div>
        <div className="w-[95.8%] ml-auto">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default Welcome;

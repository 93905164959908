import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetDischargeSummary = ({ setIsSelectedRecord = () => {} }) => {
  const [selectedDischargeSummary, setSelectedDischargeSummary] = useState({});
  const [isDischargeLoading, setIsDischargeLoading] = useState(false);

  const getSelectedDischargeSummary = async (id) => {
    setIsDischargeLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/discharge-summaries/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setSelectedDischargeSummary(res.data);
      setIsSelectedRecord(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsDischargeLoading(false);
  };

  return {
    selectedDischargeSummary,
    isDischargeLoading,
    getSelectedDischargeSummary,
  };
};

export default useGetDischargeSummary;

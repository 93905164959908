import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faVideo } from "@fortawesome/free-solid-svg-icons";
import "react-loading-skeleton/dist/skeleton.css";
import MessageCaption from "../../../../images/message-caption.svg";
import { useSelector } from "react-redux";
import useGetPatientDetails from "../hooks/useGetPatientDetails";
import { startCase } from "../../../utils/startCase";
import Skeleton from "react-loading-skeleton";
import ActivePatientTab from "./ActivePatientTab";
import VitalIcon from "../../../../images/Vital.svg";

const PatientDetails = ({
  activePatientDetailsTab = "",
  setActivePatientDetailsTab = () => {},
  wellnessData = [],
  isWellDataLoading,
}) => {
  const { isPatientDetailsLoading } = useGetPatientDetails();

  const patientData = useSelector((state) => state.patientDetails);

  const {
    abhaId,
    age,
    bloodGroup,
    gender,
    name,
    mrNo,
    abhaNo = "",
    profilePhoto,
  } = patientData || {};

  const lastWellnessData = wellnessData?.[0] || {};
  const {
    diastolicPressure,
    systolicPressure,
    breathRate,
    height,
    temperature,
    weight,
  } = lastWellnessData;

  return (
    <div
      className="mt-4 px-6 pt-4 w-full bg-white"
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="flex items-center  gap-16">
        <div className="flex">
          <div className="mx-4">
            {profilePhoto === undefined ? (
              <FontAwesomeIcon icon={faCircleUser} size="7x" color="#999999" />
            ) : (
              <img
                src={profilePhoto}
                alt="Jp"
                className=" rounded-full w-28 h-28 object-cover"
              />
            )}
          </div>
          <div className="ms-6">
            <div className="font-medium text-xl pb-4 text-[#2D2E33]">
              {isPatientDetailsLoading ? <Skeleton /> : name}
            </div>
            <div className="flex mb-1 gap-2">
              <span className="text-sm  flex flex-shrink-0 text-[#5E6066]">
                MR No. :
              </span>
              <span className="text-sm text-[#2D2E33]">
                {isPatientDetailsLoading ? <Skeleton width={30} /> : mrNo}
              </span>
            </div>
            <div className="flex mb-1 gap-2">
              <span className="text-sm  flex flex-shrink-0 text-[#5E6066]">
                ABHA No. :
              </span>
              <span className="text-sm text-[#2D2E33]">
                {isPatientDetailsLoading ? (
                  <Skeleton width={30} />
                ) : (
                  abhaNo || "-"
                )}
              </span>
            </div>
            <div className="flex mb-1 gap-2">
              <span className="text-sm flex  flex-shrink-0  text-[#5E6066]">
                ABHA Address:
              </span>
              <span className="text-sm flex  flex-shrink-0 text-[#2D2E33]">
                {isPatientDetailsLoading ? (
                  <Skeleton width={50} />
                ) : (
                  abhaId || "-"
                )}
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="flex gap-3 pb-2 item-center">
            <div className="bg-[#EDEBFE] w-fit h-fit py-1 px-2.5 rounded-lg">
              <FontAwesomeIcon icon={faVideo} color="#9061F9" />
            </div>
            <div className="bg-[#E1EFFE] w-fit h-fit py-1 px-2 rounded-lg">
              <img src={MessageCaption} alt="MessageCaption" />
            </div>
          </div>
          <div className="flex mb-1 gap-2">
            <span className="text-sm  text-[#5E6066]">Age:</span>
            <span className="text-sm flex gap-1 text-[#2D2E33]">
              {isPatientDetailsLoading ? <Skeleton width={20} /> : age} Years
              Old
            </span>
          </div>
          <div className="flex mb-1 gap-2">
            <span className="text-sm  text-[#5E6066]">Sex:</span>
            <span className="text-sm text-[#2D2E33]">
              {isPatientDetailsLoading ? (
                <Skeleton width={50} />
              ) : (
                startCase(gender)
              )}
            </span>
          </div>
          <div className="flex mb-1 gap-2">
            <span className="text-sm flex flex-shrink-0  text-[#5E6066]">
              Blood Group:
            </span>
            <span className="text-sm text-[#2D2E33]">
              {isPatientDetailsLoading ? (
                <Skeleton width={40} />
              ) : (
                bloodGroup || "-"
              )}
            </span>
          </div>
        </div>
        <div>
          <div className="flex gap-1 pb-4 items-center">
            <h1 className="text-[#2D2E33] text-sm font-medium">
              Current Vital
            </h1>
            <img src={VitalIcon} alt="vital" />
          </div>
          <div className="flex gap-16">
            <div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">Temperature:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? (
                    <Skeleton width={50} />
                  ) : (
                    temperature || "--"
                  )}
                </span>
              </div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">Pulse:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? (
                    <Skeleton width={50} />
                  ) : (
                    breathRate || "--"
                  )}
                </span>
              </div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">Blood Pressure:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? (
                    <Skeleton width={50} />
                  ) : (
                    <>
                      {systolicPressure === undefined
                        ? "--"
                        : `${systolicPressure}/${diastolicPressure}` || "--"}
                    </>
                  )}
                </span>
              </div>
            </div>
            <div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">Weight:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? <Skeleton width={50} /> : weight || "--"}
                </span>
              </div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">BMI:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? (
                    <Skeleton width={50} />
                  ) : (
                    (
                      weight?.replace(/\D/g, "") /
                      ((height?.replace(/\D/g, "") / 100) *
                        (height?.replace(/\D/g, "") / 100))
                    ).toFixed(2) || "--"
                  )}
                </span>
              </div>
              <div className="flex mb-1 gap-2">
                <span className="text-sm  text-[#5E6066]">Height:</span>
                <span className="text-sm text-[#2D2E33]">
                  {isWellDataLoading ? <Skeleton width={50} /> : height || "--"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActivePatientTab
        setActivePatientDetailsTab={setActivePatientDetailsTab}
        activePatientDetailsTab={activePatientDetailsTab}
      />
    </div>
  );
};

export default PatientDetails;

import React from 'react'

function HealthPage() {
  return (
    <div>
       <h3>Hey There!!! The App is Healthy Testing</h3>
    </div>
  )
}

export default HealthPage
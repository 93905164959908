// import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBack from "../../../../images/back_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriptionData } from "../../../../redux-store/slice/prescriptionDataSlice";
import PrimaryButton from "../../../common-components/Buttons/PrimaryButton";
import SearchInput from "../../../common-components/SearchInput";
import Bell from "../../../../images/bell.svg";
import ParchaaHip from "../../../../images/parchaaHip.svg";
import ReferUser from "../../../../images/refer-arrow1.svg";

const DoctorHeader = ({
  setReferalModal = () => {},
  setNotifications = () => {},
  setSettingModal = () => {},
}) => {
  const navigate = useNavigate();

  const patientData = useSelector((state) => state.patientDetails);

  const dispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(
      setPrescriptionData({
        ...patientData,
        chiefComplaint: patientData.chiefComplaint,
      })
    );
    navigate("/doctor/createPrescription", {
      state: { visitId: patientData.visitId },
    });
  };

  return (
    <div
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
      className="flex w-full items-center justify-between ps-5 gap-10 text-[#111928] bg-white py-3  text-sm"
    >
      <div className="w-1/6">
        <button onClick={() => navigate("/welcome")}>
          <img src={ArrowBack} alt="ArrowBack" />
        </button>
      </div>
      {/* <span className="text-[#1C64F2]">Overview</span>
      <span>Demographics</span>
      <span>Radiology</span>
      <span>Lab Reports</span>
      <span>Cardiac</span>
      <span>Procedures</span>
      <span>Wrap Up</span>
      <span>
        More <FontAwesomeIcon icon={faAngleDown} />
      </span> */}

      <div className="gap-6 w-1/2 flex">
        <div className="w-[56%]">
          <SearchInput />
        </div>
        <PrimaryButton
          onClick={handleNavigate}
          width="w-fit"
          showIcon
          buttonName="Create Prescription"
        />
      </div>
      <div className="items-center w-[10%] flex gap-5">
        <button
          onClick={() => setReferalModal(true)}
          className="flex justify-center"
        >
          <img src={ReferUser} alt="ReferUser" />
        </button>
        <button
          onClick={() => setNotifications((prev) => !prev)}
          className="flex justify-center"
        >
          <img src={Bell} alt="Bell" />
        </button>
        <button
          onClick={() => setSettingModal(true)}
          className="flex justify-center"
        >
          <img src={ParchaaHip} alt="ParchaaHip" height={28} width={28} />
        </button>
      </div>
    </div>
  );
};

export default DoctorHeader;

import {
  faPersonWalking,
  faPlus,
  faTrashCan,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useContext, useState } from "react";
import { Context } from "./DoctorAccount";

const PatientInteraction = () => {
  const [activeTab, setActiveTab] = useState("inVisit");
  const [activeDays, setActiveDays] = useState([]);
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};
  const { patientInteraction } = doctorData || {};
  const { offices = [], services = [] } = patientInteraction || {};

  const handleChange = (e, index, label) => {
    const { name, value } = e.target || {};
    console.log(name, value);
  };

  const selectDay = (day) => {
    if (activeDays.includes(day)) {
      const updatedDays = activeDays.filter((item) => item !== day);
      setActiveDays(updatedDays);
      // dispatch(
      //   setPatientInteraction({
      //     ...patientInteraction,
      //     appointmentSlot: {
      //       ...patientInteraction.appointmentSlot,
      //       availablityDay: updatedDays,
      //     },
      //   })
      // );
    } else {
      setActiveDays((prev) => [...prev, day]);
      // dispatch(
      //   setPatientInteraction({
      //     ...patientInteraction,
      //     appointmentSlot: {
      //       ...patientInteraction.appointmentSlot,
      //       availablityDay: [
      //         ...patientInteraction.appointmentSlot.availablityDay,
      //         day,
      //       ],
      //     },
      //   })
      // );
    }
  };

  const selectVisit = (visit) => {
    setActiveTab(visit);
    // dispatch(
    //   setPatientInteraction({
    //     ...patientInteraction,
    //     appointmentSlot: {
    //       ...patientInteraction.appointmentSlot,
    //       visitType: visit,
    //     },
    //   })
    // );
  };

  return (
    <div>
      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 font-medium flex justify-between">
          <div>Services</div>

          <button
            // onClick={addServiceType}
            className="flex border gap-2 items-center justify-center bg-white border-[#E5E7EB] item-center px-3 py-1.5 active:bg-[#1C64F2] rounded-lg text-sm text-[#1F2A37]"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        {services.map((item, index) => {
          return (
            <div key={index} className="text-sm mb-2 w-full flex gap-4">
              <div className="w-1/2">
                <div className="text-[#111928]">Service type</div>
                <div className="mt-1 flex gap-5">
                  <input
                    name="serviceType"
                    disabled
                    value={item}
                    onChange={handleChange}
                    type="text"
                    className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                  />
                  {patientInteraction.services.length > 1 && (
                    <button
                      className="border px-2.5 hover:border-[#E02424] active:animate-bounce rounded-lg border-[#E7F4FF]"
                      // onClick={() => removeServiceField(index)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 font-medium flex justify-between">
          Appointment Slot
        </div>
        <div className="flex mb-4 text-sm">
          <button
            className={`${
              activeTab === "inVisit" ? "bg-[#1C64F2] text-white" : "border"
            } flex items-center border-r-0 gap-2 px-8 py-2 rounded-lg rounded-r-none`}
            onClick={() => selectVisit("inVisit")}
          >
            <FontAwesomeIcon icon={faPersonWalking} /> In Visit
          </button>
          <button
            className={`${
              activeTab === "teleconsult" ? "bg-[#1C64F2] text-white" : "border"
            }  flex items-center gap-2 px-3 py-2 rounded-l-none rounded-lg`}
            onClick={() => selectVisit("teleconsult")}
          >
            <FontAwesomeIcon icon={faVideo} /> Teleconsult
          </button>
        </div>
        <div className="text-sm my-4">
          <div className="text-[#111928] my-2">Select</div>
          <div className="flex gap-5">
            <button
              onClick={() => selectDay("M")}
              className={`${
                activeDays.includes("M")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              M
            </button>
            <button
              onClick={() => selectDay("T")}
              className={`${
                activeDays.includes("T")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              T
            </button>
            <button
              onClick={() => selectDay("W")}
              className={`${
                activeDays.includes("W")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              W
            </button>
            <button
              onClick={() => selectDay("TH")}
              className={`${
                activeDays.includes("TH")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              T
            </button>
            <button
              onClick={() => selectDay("F")}
              className={`${
                activeDays.includes("F")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              F
            </button>
            <button
              onClick={() => selectDay("SA")}
              className={`${
                activeDays.includes("SA")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              S
            </button>
            <button
              onClick={() => selectDay("S")}
              className={`${
                activeDays.includes("S")
                  ? "border-[#1A56DB] text-[#1A56DB] bg-[#E7F4FF]"
                  : " border-[#E7F4FF] text-[#6B7280]"
              } rounded-lg px-4 py-3 border`}
            >
              S
            </button>
          </div>
        </div>
        <div className="text-sm mb-2 w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-[#111928]">Session Time From</div>
            <div className="mt-1 flex gap-5">
              <input
                name="sessionTimefrom"
                value="10:00 AM"
                onChange={handleChange}
                disabled
                type="text"
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-[#111928]">Session Time To</div>
            <div className="mt-1 flex gap-5">
              <input
                name="sessionTimeTo"
                value="02:00 PM"
                onChange={handleChange}
                disabled
                type="text"
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] flex justify-between font-medium">
          Office Details
          <button
            // onClick={addOffices}
            className="flex border gap-2 items-center justify-center bg-white border-[#E5E7EB] item-center px-3 py-1.5 active:bg-[#1C64F2] rounded-lg text-sm text-[#1F2A37]"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        {offices.map((item, index) => {
          // console.log(item, "item");
          return (
            <div className="mb-3" key={index}>
              <div className="text-[#111928] mb-1 flex justify-between">
                Office {index + 1}
              </div>
              <div className="text-sm w-full flex gap-4">
                <div className="w-1/2">
                  <div className="text-[#111928]">Clinic Name</div>
                  <div className="mt-1">
                    <input
                      disabled
                      type="text"
                      name="clinicName"
                      onChange={(e) => handleChange(e, index, "offices")}
                      value={item.buildingName}
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-[#111928]">Address </div>
                  <div className="mt-1">
                    <input
                      name="address"
                      disabled
                      value={item.addressLine}
                      onChange={(e) => handleChange(e, index, "offices")}
                      type="text"
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
              </div>
              <div className="text-sm mt-2 w-full flex gap-4">
                <div className="w-1/2">
                  <div className="text-[#111928]">State</div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="state"
                      disabled
                      onChange={(e) => handleChange(e, index, "offices")}
                      value={item.state}
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-[#111928]">City </div>
                  <div className="mt-1">
                    <input
                      name="city"
                      disabled
                      value={item.city}
                      onChange={(e) => handleChange(e, index, "offices")}
                      type="text"
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
              </div>
              <div className="text-sm w-full mt-2 flex gap-4">
                <div className="w-1/2">
                  <div className="text-[#111928]">Pin Code</div>
                  <div className="mt-1">
                    <input
                      type="text"
                      disabled
                      name="pinCode"
                      onChange={(e) => handleChange(e, index, "offices")}
                      value={item.pinCode}
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-[#111928]">Phone Number </div>
                  <div className="mt-1">
                    <input
                      name="phone"
                      disabled
                      value={item.officePhoneNo}
                      onChange={(e) => handleChange(e, index, "offices")}
                      type="text"
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
              </div>
              <div className="text-sm w-full mt-2 flex gap-4">
                <div className="w-1/2">
                  <div className="text-[#111928]">Email</div>
                  <div className="mt-1">
                    <input
                      type="text"
                      disabled
                      name="email"
                      onChange={(e) => handleChange(e, index, "offices")}
                      value={item.workMail}
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-[#111928]">In Visit Fees </div>
                  <div className="mt-1">
                    <input
                      name="inVisitFee"
                      disabled
                      value={item.inVisitFee}
                      onChange={(e) => handleChange(e, index, "offices")}
                      type="text"
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
              </div>
              <div className="text-sm w-full mt-2 flex gap-4">
                <div className="w-1/2">
                  <div className="text-[#111928]">Follow-Up Fees</div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="followUpFee"
                      disabled
                      // onChange={(e) => handleChange(e, index, "offices")}
                      value={item.followUpFee}
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-[#111928]">Teleconsultation Fees</div>
                  <div className="mt-1 flex gap-5 w-full">
                    <input
                      name="teleConsultationFee"
                      disabled
                      value={item.teleConsultationFee}
                      onChange={(e) => handleChange(e, index, "offices")}
                      type="text"
                      className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                    />
                    {patientInteraction.offices.length > 1 && (
                      <button
                        className="border px-2.5 hover:border-[#E02424] active:animate-bounce rounded-lg border-[#E7F4FF]"
                        // onClick={() => removeOfficeField(index)}
                      >
                        <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(PatientInteraction);

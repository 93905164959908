import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import file from "../../../../../../images/file.svg";
import useGetDischargeSummary from "../hooks/useGetDischargeSummary";
import SelectedDischarge from "./SelectedDischarge";

const DischargeSummaryReport = ({
  DischargeSummary = [],
  handleCheckBoxChange = () => {},
  selectedItem,
  setSelectedItem,
}) => {
  const [isSelectedRecord, setIsSelectedRecord] = useState(false);
  const {
    selectedDischargeSummary,
    isDischargeLoading,
    getSelectedDischargeSummary,
  } = useGetDischargeSummary({ setIsSelectedRecord });
  const [selectedName, setSelectedName] = useState(null);
  return (
    <div className="ms-10">
      <div className="flex items-center mt-3 ">
        <div className="flex items-center gap-3 w-[95%]">
          <button
            onClick={() =>
              setSelectedItem((prev) => ({
                ...prev,
                isDischargeSummary: !prev.isDischargeSummary,
              }))
            }
          >
            <FontAwesomeIcon
              icon={selectedItem.isDischargeSummary ? faAngleUp : faAngleDown}
            />
          </button>
          <div>
            <input type="checkbox" />
          </div>
          <div>Discharge Summary</div>
        </div>
        <div className="">{DischargeSummary.length}</div>
      </div>
      {selectedItem.isDischargeSummary ? (
        <div>
          {DischargeSummary?.map((discharge, index) => {
            const { id, display, linked, fhirId } = discharge || {};
            return (
              <div className="p-4 items-center flex border-b" key={index}>
                <div className="text-[#4C6AF7]  w-[78.5%] gap-3 items-center flex ms-6 border-[#D8E3FF]">
                  <div>
                    {!linked && (
                      <input
                        type="checkbox"
                        onChange={() => handleCheckBoxChange(id)}
                      />
                    )}
                  </div>
                  <div className="mb-1">
                    <img src={file} alt="file" />
                  </div>
                  <button
                    onClick={() => {
                      getSelectedDischargeSummary(fhirId);
                      setSelectedName(id);
                    }}
                    disabled={isDischargeLoading}
                  >
                    {id} ({display})
                  </button>
                </div>
                <div>{linked === true ? "Linked" : "Un Linked"}</div>
              </div>
            );
          })}
        </div>
      ) : null}
      {isSelectedRecord ? (
        <SelectedDischarge
          isSelectedRecord={isSelectedRecord}
          setIsSelectedRecord={setIsSelectedRecord}
          selectedDischargeSummary={selectedDischargeSummary}
          name={selectedName}
        />
      ) : null}
    </div>
  );
};

export default DischargeSummaryReport;

import React from "react";

const MedicalHistory = () => {
  return (
    <div className="w-full">
      <div className="p-2 font-light">No Data Found !</div>
      {/* <div className="flex w-full py-3 text-[#374151] text-sm">
        <div className="w-[70%]">
          <div> Annual physical exam </div>
        </div>
        <div className="w-[30%] flex justify-center">17/05/2024</div>
      </div>
      <div className="flex w-full text-[#374151] text-sm">
        <div className="w-[70%]">
          <div className="flex items-center">
            Diabetes
            <span className="ms-1 font-light">(HCC Risk Code)</span>
          </div>
        </div>
        <div className="w-[30%] flex justify-center">17/05/2024</div>
      </div> */}
    </div>
  );
};

export default MedicalHistory;

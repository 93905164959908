import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useAddWellnessRecord = ({
  wellnessRecordInfo = {},
  getWellnessRecord = () => {},
  setActiveHealthTab = () => {},
  getAvailableRecord = () => {},
}) => {
  const [isAddingWellness, setIsAddingWellness] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint } = patientData || {};

  const {
    height = "",
    weight = "",
    temperature = "",
    pulseRate = "",
    breathRate = "",
    systolicPressure = "",
    diagnosticPressure = "",
    doYouSmoke = "",
    sleepingHours = "",
  } = wellnessRecordInfo || {};

  const onSubmittingWellness = async () => {
    setIsAddingWellness(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/wellness-records`,
        {
          mrNo: mrNo,
          height: height,
          weight: weight,
          temperature: temperature,
          breathRate: breathRate,
          systolicPressure: systolicPressure,
          diastolicPressure: diagnosticPressure,
          doYouSmoke: doYouSmoke,
          hoursOfSleep: sleepingHours,
          pulseRate: pulseRate,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: res.data.id,
              hiType: "WellnessRecord",
              display: "Wellness Record",
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chiefComplaint,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      toast.success("Wellness record is submitted successfully !");
      setTimeout(() => {
        getWellnessRecord();
        getAvailableRecord();
        setActiveHealthTab("");
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsAddingWellness(false);
  };

  return { isAddingWellness, onSubmittingWellness };
};

export default useAddWellnessRecord;

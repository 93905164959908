import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faList,
  faUserDoctor,
  faFlask,
  faGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../common-components/Tooltip";

const Navbar = () => {
  return (
    <div>
      <ul>
        <li className="m-3 p-2 flex justify-center rounded-lg bg-[#E1EFFE]">
          <Tooltip content="Home" placement="bottom">
            <FontAwesomeIcon icon={faHouse} color="#1A56DB" />
          </Tooltip>
        </li>
        <li className="m-3 p-2 flex justify-center rounded-lg">
          <FontAwesomeIcon icon={faList} color="#6B7280" />
        </li>
        <li className="m-3 p-2 flex justify-center rounded-lg">
          <FontAwesomeIcon icon={faUserDoctor} color="#6B7280" />
        </li>
        <li className="m-3 p-2 flex justify-center rounded-lg">
          <FontAwesomeIcon icon={faFlask} color="#6B7280" />
        </li>
        <li className="m-3 p-2 flex justify-center rounded-lg">
          <FontAwesomeIcon icon={faUserGroup} color="#6B7280" />
        </li>
        <li className="m-3 p-2 flex justify-center rounded-lg">
          <FontAwesomeIcon icon={faGear} color="#6B7280" />
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

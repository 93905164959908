import React, { useState } from "react";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import FileUpload from "../../../../../../images/file_upload.svg";
import TertiaryButton from "../../../../../common-components/Buttons/TertiaryButton";
import PrimaryButton from "../../../../../common-components/Buttons/PrimaryButton";
import Modal from "../../../../../common-components/Modal";
import { useSelector } from "react-redux";
import useAddDischargeSummary from "../hooks/useAddDischargeSummary";

const AddDischargeSummary = ({
  setActiveHealthTab = () => {},
  activeHealthTab = "",
  getAvailableRecord = () => {},
}) => {
  const patientData = useSelector((state) => state.patientDetails);
  const { chiefComplaint } = patientData || {};

  const [dischargeSummaryInfo, setDischargeSummaryInfo] = useState({
    dateOfAdmission: "",
    timeOfAdmission: "",
    dateOfDischarge: "",
    timeOfDischarge: "",
    chiefComplaint: chiefComplaint,
    diagnosis: "",
    proceduresPerformed: "",
    medication: "",
    statusWhileDischarge: "",
    followUpDate: "",
    adviceOnDischarge: "",
  });

  const handleDischargeSummaryChange = (e) => {
    const { name, value } = e.target || {};
    setDischargeSummaryInfo((prev) => ({ ...prev, [name]: value }));
  };

  const onReset = () => {
    setDischargeSummaryInfo({
      dateOfAdmission: "",
      timeOfAdmission: "",
      dateOfDischarge: "",
      timeOfDischarge: "",
      chiefComplaint: chiefComplaint,
      diagnosis: "",
      proceduresPerformed: "",
      medication: "",
      statusWhileDischarge: "",
      followUpDate: "",
      adviceOnDischarge: "",
    });
  };

  const { onDischargeSummary, isAddingDischarge } = useAddDischargeSummary({
    dischargeSummaryInfo,
    getAvailableRecord,
    setActiveHealthTab,
  });

  return (
    <div className="text-sm text-[#2D2E33">
      <Modal
        showModal={activeHealthTab === "discharge_summary" ? true : false}
        onClose={() => setActiveHealthTab("")}
        modalHeight="min-h-1/2"
        modalWidth="w-[90%]"
      >
        <div className="py-4 px-6">
          <h1 className="text-[#2D2E33 text-base  font-medium">
            Discharge Summary
          </h1>
          <div className="flex py-4">
            <div className="w-8/12">
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Date & Time
                </h1>
                <div className="flex  mt-2 ps-4 flex-wrap">
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2">Date of Admission</div>
                    <div>
                      <input
                        type="date"
                        name="dateOfAdmission"
                        value={dischargeSummaryInfo.dateOfAdmission}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 uppercase placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Date"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2">Time of Admission</div>
                    <div>
                      <input
                        type="time"
                        name="timeOfAdmission"
                        value={dischargeSummaryInfo.timeOfAdmission}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="00:00 AM"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2">Date of Discharge</div>
                    <div>
                      <input
                        type="date"
                        name="dateOfDischarge"
                        value={dischargeSummaryInfo.dateOfDischarge}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 uppercase placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Date"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2">Time of Discharge</div>
                    <div>
                      <input
                        type="time"
                        name="timeOfDischarge"
                        value={dischargeSummaryInfo.timeOfDischarge}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="00:00 AM"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Details
                </h1>
                <div className="flex ps-4 mt-2 flex-wrap">
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">Chief Complaint</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Chief Complaint"
                        value={dischargeSummaryInfo.chiefComplaint}
                        onChange={handleDischargeSummaryChange}
                        name="chiefComplaint"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">Diagnosis</div>
                    <div>
                      <input
                        type="text"
                        name="diagnosis"
                        value={dischargeSummaryInfo.diagnosis}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Diagnosis"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">
                      Procedures Performed
                    </div>
                    <div>
                      <input
                        type="text"
                        name="proceduresPerformed"
                        value={dischargeSummaryInfo.proceduresPerformed}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Procedures"
                        onChange={handleDischargeSummaryChange}
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">Medication</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Medication"
                        name="medication"
                        value={dischargeSummaryInfo.medication}
                        onChange={handleDischargeSummaryChange}
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">
                      Status while Discharging
                    </div>
                    <div>
                      <input
                        type="text"
                        name="statusWhileDischarge"
                        value={dischargeSummaryInfo.statusWhileDischarge}
                        onChange={handleDischargeSummaryChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Status"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">
                      Advice on Discharge
                    </div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Type Advice"
                        value={dischargeSummaryInfo.adviceOnDischarge}
                        name="adviceOnDischarge"
                        onChange={handleDischargeSummaryChange}
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/3">
                    <div className="text-[#5E6066] mb-2.5">Follow Up Date</div>
                    <div>
                      <input
                        type="date"
                        className="pb-1 uppercase placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Date"
                        name="followUpDate"
                        value={dischargeSummaryInfo.followUpDate}
                        onChange={handleDischargeSummaryChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-5 justify-end">
                <TertiaryButton
                  buttonName="Reset"
                  width="w-fit"
                  onClick={onReset}
                />
                <PrimaryButton
                  buttonName="Submit"
                  width="w-fit"
                  onClick={onDischargeSummary}
                  disabled={isAddingDischarge}
                />
              </div>
            </div>
            <div className="text-[#7F8185] flex flex-col items-center w-[10%] justify-center">
              <div className="flex flex-col items-center">
                <div className="border-l h-52"></div>
                <div>or</div>
                <div className="border-l h-52"></div>
              </div>
            </div>
            <div className="border-dashed h-fit border gap-3 flex flex-col rounded-lg py-6 px-12 bg-[#F9FAFB] border-[#C6C7C9] justify-center items-center">
              <div className="flex gap-2 justify-center flex-col">
                <img
                  src={FileUpload}
                  alt="FileUpload"
                  className="h-12 w-12 mx-auto"
                />
                <h1 className="text-[#2D2E33] text-sm">
                  Drag & Drop File to Upload
                </h1>
                <h1 className="text-[#7F8185] mx-auto">.png/ .jpg/ .pdf</h1>
              </div>
              <div>or</div>
              <SecondaryButton buttonName="Browse Files" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDischargeSummary;

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useLogin = ({ formData }) => {
  const [loginData, setLoginData] = useState({});
  const [loginError, setLoginError] = useState({});
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoginLoading(true);
    try {
      const res = await axios.post(`/v2/susanoo/auth/login`, formData);
      const token = res.data.token;
      localStorage.setItem("authToken", token);
      setLoginData(res.data);
      toast.success("Login successfully !");
      navigate("/welcome");
    } catch (err) {
      console.log(err, "err");
      setLoginError(err);
      toast.error(
        err?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsLoginLoading(false);
  };

  return {
    handleSubmit,
    isLoginLoading,
    loginData,
    loginError,
  };
};

export default useLogin;

import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGetDoctor = () => {
  const [isDcotorDataLoading, setIsDoctorLoading] = useState(true);
  const [doctorData, setDoctorData] = useState({});

  const getDoctorData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/doctor/getDoctor`, {
        headers: {
          Authorization: token,
        },
      });
      setDoctorData(res.data);
    } catch (error) {
      console.error(error, "error");
      toast.error(
        error?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsDoctorLoading(false);
  };

  useEffect(() => {
    getDoctorData();
  }, []);

  return { isDcotorDataLoading, doctorData };
};

export default useGetDoctor;

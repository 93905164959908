import React from "react";
import Modal from "../../../../../common-components/Modal";
import moment from "moment";
import { startCase } from "../../../../../utils/startCase";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";
import backArrow from "../../../../../../images/back_arrow.svg";

const SelectedPrescription = ({
  isSelectedRecord,
  setIsSelectedRecord,
  selectedPrescriptionData,
  slectedName,
}) => {
  const {
    chiefComplaint = "",
    diagnosis = [],
    rxList = [],
    labInvestigations = [],
    instructions = [],
    lifeStyleRecommendations = [],
    followUp = "",
    followUpDate = "",
    dateCreated,
  } = selectedPrescriptionData || {};
  return (
    <div>
      <Modal
        showModal={isSelectedRecord}
        onClose={() => setIsSelectedRecord(false)}
        modalWidth="w-[55%]"
        modalHeight="h-[90%]"
      >
        <div className="bg-white mt-4  mb-20 px-6">
          <div className="flex justify-between w-[75%]">
            <img src={backArrow} alt="backArrow" />
            <div>{slectedName} (Prescription Record)</div>
          </div>
          <div className="p-2  mt-5 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Prescription</div>
            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex my-2">
            <div className="font-semibold">Chief Complaint:</div>
            <span className="font-light gap-2 flex ms-1">{chiefComplaint}</span>
          </div>
          {diagnosis?.length > 0 && (
            <div className="flex my-2">
              <div className="font-semibold">Diagnosis:</div>
              <span className="font-light flex-wrap gap-2 flex ms-1">
                {(diagnosis || []).map((compliants, index) => (
                  <span className="flex flex-wrap" key={index}>
                    {startCase(compliants)}
                    <span
                      className={`${
                        diagnosis.length - 1 === index ? "hidden" : ""
                      }`}
                    >
                      ,
                    </span>
                  </span>
                ))}
              </span>
            </div>
          )}
          {rxList[0].drugName !== "" && (
            <div className="my-4">
              <div className="flex w-full bg-[#F3F4F6] py-2 px-4">
                <div className="w-[50%]">Medicine</div>
                <div className="w-[20%]">Timing</div>
                <div className="w-[20%]">Frequency</div>
                <div className="w-[10%]">Duration</div>
              </div>
              {rxList.map((item, index) => {
                const {
                  drugName,
                  dose,
                  duration,
                  frequency,
                  measurement,
                  timing,
                  notes,
                  companyLingo,
                } = item || {};
                return (
                  <div
                    className="border-b w-full text-sm px-4 py-2  justify-start font-light"
                    key={index}
                  >
                    <div className="flex w-full gap-2">
                      <div className="w-[50%] pe-4">
                        <div
                          className={`gap-1  ${
                            drugName.length < 30 ? "flex flex-wrap" : ""
                          } items-center`}
                        >
                          <span className="font-medium flex flex-wrap">
                            {companyLingo === "" || companyLingo === null
                              ? drugName
                              : companyLingo}
                          </span>
                          <span>{dose}</span>
                          <span>{measurement}</span>
                        </div>
                      </div>
                      <div className="w-[20%] pe-4">{startCase(timing)}</div>
                      <div className="w-[20%] pe-4">{frequency}</div>
                      <div className="w-[10%]">{duration}</div>
                    </div>
                    {companyLingo === "" || companyLingo === null ? null : (
                      <div>
                        <span className="w-[10%]"> Composition</span>:{" "}
                        {drugName} {dose} {measurement}
                      </div>
                    )}
                    {notes === "" || notes === null ? null : (
                      <div className="w-full">
                        <span>Note</span>: {notes}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {labInvestigations?.length > 0 && (
            <div className="my-2 gap-2">
              <div className="font-semibold">Lab Investigations: </div>
              <div className="font-light">
                {(labInvestigations || []).map((item, index) => (
                  <div className="" key={index}>
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {instructions !== "" && (
            <div className="my-2">
              <div className="font-semibold">Instructions:</div>
              <div className="font-light ">
                {(instructions || []).map((item, index) => (
                  <div className="flex items-center" key={index}>
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {lifeStyleRecommendations !== "" && (
            <div className="my-2">
              <div className="font-semibold">Lifestyle Recommendations:</div>
              <div className="font-light ">
                {(lifeStyleRecommendations || []).map((item, index) => (
                  <div className="flex items-center" key={index}>
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {followUp !== "" && (
            <div className="my-2 gap-2">
              <div className="font-semibold">Follow Up:</div>
              <div className="font-light ">
                {followUp} ({moment(followUpDate).format("DD-MM-yyyy")})
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SelectedPrescription;

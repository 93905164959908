import React, { useContext } from "react";
import SearchInput from "../../../../common-components/SearchInput";
import TertiaryButton from "../../../../common-components/Buttons/TertiaryButton";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { filterListContext } from "./HIPdashboard";
import AppliedFilters from "./AppliedFilters";
import FilterModal from "./FilterModal";

const HIPdashboardHeader = () => {
  const filterState = useContext(filterListContext);
  const {
    selectedFilters,
    setIsFilterModalOpen,
    isFilterModalOpen,
    modalRef,
    handleInputChange,
    searchInput,
  } = filterState || {};

  const {
    visitStatus = [],
    visitType = [],
    tags = [],
    department = [],
    serviceType = [],
  } = selectedFilters || {};

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex w-[79%] items-center">
        <div className="w-[40%] me-3">
          <SearchInput
            placeholder="Search ..."
            inputValue={searchInput}
            onChange={handleInputChange}
          />
        </div>
        {visitStatus.length > 0 ||
        visitType.length > 0 ||
        tags.length > 0 ||
        department.length > 0 ||
        serviceType.length > 0 ? (
          <div className="flex items-center w-[73%]">
            <div className="text-[#6B7280] flex-shrink-0 font-medium">
              Applied Filters:
            </div>
            <div className="w-[93%] overflow-x-auto">
              <AppliedFilters />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex ms-2 gap-3">
        <div>
          <TertiaryButton
            buttonName="Filters"
            icon={faFilter}
            showIcon
            onClick={() => setIsFilterModalOpen((prev) => !prev)}
          />
          {isFilterModalOpen && (
            <div className="" ref={modalRef}>
              <FilterModal />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HIPdashboardHeader;

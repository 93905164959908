import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useGetAllDiagnosis = () => {
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState([]);
  const [isdiagnosisLoading, setIsDiagnoisLoading] = useState(true);
  const prescriptionData = useSelector((state) => state.prescriptionData);

  const { chiefComplaint } = prescriptionData || {};

  const getAllDiagnosis = useCallback(
    async (inputValue = "") => {
      const token = localStorage.getItem("authToken");
      try {
        // const res = await axios.get(`/v2/cortex/diagnosis/search`, {
        const res = await axios.get(
          `/v2/cortex/diagnosis`,
          {
            params: { query: inputValue, chiefComplaint: chiefComplaint },
            headers: {
              Authorization: token,
            },
          }
        );
        setDifferentialDiagnosis(res.data?.diagnosis);
      } catch (error) {
        console.error(error, "error");
        toast.error(error?.response?.data?.message, {
          position: "top-center",
        });
      }
      setIsDiagnoisLoading(false);
    },
    [chiefComplaint]
  );

  useEffect(() => {
    getAllDiagnosis();
  }, [getAllDiagnosis]);

  return {
    differentialDiagnosis,
    isdiagnosisLoading,
    getAllDiagnosis,
  };
};

export default useGetAllDiagnosis;

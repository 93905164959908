import React from "react";
import useGetHPIrecord from "../patient-details/hooks/useGetHPIrecord";
import Skeleton from "react-loading-skeleton";

const HPIquestionare = () => {
  const { isHPIloading, hpiData } = useGetHPIrecord();
  const hopi = hpiData[hpiData.length - 1] || {};
  const { risk } = hopi || {};
  const { responses, questions } = risk || {};
  const { question_options } = questions || [];

  // Helper function to check if an option is selected based on responses
  const isOptionSelected = (questionId, optionKey) => {
    const response = responses?.find(
      (response) => response?.question_id === questionId
    );
    return response.options.some((option) => option[optionKey]);
  };

  return (
    <div>
      <div className="w-full mt-4 text-sm font-light text-[#1F2A37] ">
        {isHPIloading ? (
          <div>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <Skeleton key={index} />
            ))}
          </div>
        ) : (
          <div className="gap-y-2">
            <div>
              {question_options === undefined ||
              question_options.length === 0 ? (
                <div> No Data found ! </div>
              ) : (
                <div>
                  {question_options?.map((questions, index) => {
                    return (
                      <div key={index} className="flex flex-col mb-2">
                        <div className="flex">
                          <div>{index + 1}</div>
                          <div className="ml-3">{questions?.question}</div>
                        </div>
                        <div className="flex gap-x-4 mt-2">
                          {questions?.options.map((option, optionIndex) => {
                            const optionKey = `option${optionIndex + 1}`; // Dynamically get option1, option2, etc.
                            return (
                              <div
                                key={optionIndex}
                                className="flex items-center mb-2"
                              >
                                <input
                                  type="checkbox"
                                  id={`option-${index}-${optionIndex}`}
                                  className="mr-2"
                                  checked={isOptionSelected(
                                    questions.question_id,
                                    optionKey
                                  )} // Check if this option should be selected
                                  readOnly // Makes it read-only since it's pre-selected based on responses
                                />
                                <label
                                  htmlFor={`option-${index}-${optionIndex}`}
                                >
                                  {option[optionKey]}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HPIquestionare;

import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Profile = ({ setOpenProfile = () => {}, openProfile = false }) => {
  const navigate = useNavigate();
  const dropDownRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target) &&
        event.target.tagName !== "IMG"
      ) {
        setOpenProfile(false);
      }
    },
    [setOpenProfile]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openProfile, handleClickOutside]);

  return (
    openProfile && (
      <div
        ref={dropDownRef}
        style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
        className="text-[#374151] bg-white text-sm right-0 top-10 rounded-md absolute"
      >
        <div className="py-2 px-3 flex flex-col">
          <span className="text-[#1F2A37] font-semibold">Dr. Saumya Rawat</span>
          <span className="cursor-pointer font-normal">Head of surgen</span>
        </div>
        <div
          className="px-3 cursor-pointer hover:text-[#1A56DB] hover:underline"
          onClick={() => navigate("/myAccount")}
        >
          My Account
        </div>
        <div
          onClick={() => navigate("/settings")}
          className="py-2 px-3 cursor-pointer hover:text-[#1A56DB] hover:underline"
        >
          Setting & Preferences
        </div>
        <div className="border-t py-2 hover:underline text-[#F05252] cursor-pointer px-3">
          Switch Account
        </div>
      </div>
    )
  );
};

export default Profile;

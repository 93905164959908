import React from "react";
import useGetHPIrecord from "../patient-details/hooks/useGetHPIrecord";
import Skeleton from "react-loading-skeleton";

const PresentIllnesHistory = () => {
  const { isHPIloading, hpiData } = useGetHPIrecord();
  // const hpiDataLength = hpiData.length;
  const hopi = hpiData[hpiData.length - 1];
  const { risk } = hopi || {};
  const { synopsis } = risk || {};
  // const startIndex =
  //   synopsis?.indexOf("Medical Case Synopsis:") +
  //   "Medical Case Synopsis:".length; // Start after the key;
  // const result = synopsis?.substring(startIndex);

  return (
    <div className="mt-2 text-sm font-light text-[#1F2A37]">
      {isHPIloading ? (
        <div>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Skeleton key={index} />
          ))}
        </div>
      ) : (
        <div className=" whitespace-pre-line max-h-52 overflow-y-scroll">
          {synopsis || "No Data found !"}
        </div>
      )}
    </div>
  );
};

export default PresentIllnesHistory;

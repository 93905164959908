import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useGetAllHandoubt = () => {
  const [allHandoubts, setAllHandubts] = useState([]);
  const [isAllHandoubtsLoading, setIsAllHandoubtsLoading] = useState(false);

  const prescriptionData = useSelector((state) => state.prescriptionData);
  const { medicalHandoubts = [] } = prescriptionData || {};

  const getAllHandoubts = useCallback(async () => {
    try {
      setIsAllHandoubtsLoading(true); // Set loading to true before the API call
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/getVeiwUrl/${medicalHandoubts[0]?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAllHandubts(res.data);
    } catch (error) {
      console.error("Error fetching handouts:", error); // Log the error more clearly
    } finally {
      setIsAllHandoubtsLoading(false); // Set loading to false after the API call
    }
  }, [medicalHandoubts]);

  useEffect(() => {
    if (medicalHandoubts[0] !== undefined) {
      getAllHandoubts();
    }
  }, [medicalHandoubts, getAllHandoubts]);

  return {
    allHandoubts,
    isAllHandoubtsLoading,
  };
};

export default useGetAllHandoubt;

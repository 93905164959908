import React, { useState } from "react";
import Modal from "../../../../../../common-components/Modal";
import Select from "../../../../../../common-components/Select";
import { HI_TYPES } from "../../../../../../utils/purposeOfRequestConsent";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import PrimaryButton from "../../../../../../common-components/Buttons/PrimaryButton";
import useCreateRecord from "./useCreateRecord";

const CreateRecord = ({
  getAvailableRecord,
  isCreateRecordModal,
  setIsCreateRecordModal,
}) => {
  const [recordInfo, setRecordInfo] = useState({
    displayName: "",
    hiTypes: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    setRecordInfo((prev) => ({ ...prev, [name]: value }));
  };

  const { onRecordCreate, isRecordCreating } = useCreateRecord({
    recordInfo,
    getAvailableRecord,
    setIsCreateRecordModal,
  });
  return (
    <div>
      <Modal
        modalWidth="w-[50%]"
        modalHeight="h-1/2"
        showModal={isCreateRecordModal}
        onClose={() => setIsCreateRecordModal(false)}
      >
        <div className="text-sm py-4 px-6 text-[#2D2E33]">
          <div className="font-medium">Create New Record</div>
          <div className="flex ps-4 mt-2 flex-wrap">
            <div className="my-2 w-1/2">
              <div className="text-[#5E6066] mb-2.5">Display Name</div>
              <div>
                <input
                  type="text"
                  name="displayName"
                  onChange={handleChange}
                  value={recordInfo.displayName}
                  className="pb-1 w-10/12 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Display Name"
                />
              </div>
            </div>
            <div className="my-2 w-1/2">
              <div className="text-[#5E6066] mb-2.5">HI Types</div>
              <div className="w-10/12">
                <Select
                  options={HI_TYPES}
                  onChange={handleChange}
                  name="hiTypes"
                  upIcon={faAngleUp}
                  downIcon={faAngleDown}
                  className="pb-1.5 w-full placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <PrimaryButton
              buttonName="Submit"
              onClick={onRecordCreate}
              disabled={isRecordCreating}
              width="w-24"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateRecord;

import React, { useState } from "react";
import useGetConsentRecord from "../../../consent-manager/hooks/useGetConsentRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../../../../../../common-components/Buttons/PrimaryButton";
import { formatDateTime } from "../../../../../../utils/formatDateTime";
import useGetConsentStatus from "../../../consent-manager/hooks/useGetConsentStatus";
import { ToastContainer } from "react-toastify";
import file from "../../../../../../../images/file.svg";
import Skeleton from "react-loading-skeleton";
import NewConsentRequest from "../../../consent-manager/NewConsentRequest";
import { useSelector } from "react-redux";

const RequestedRecord = () => {
  const { consentData, isConsentDataLoading, getConsentData } =
    useGetConsentRecord();
  const {
    isConsentStatus,
    onConsentStatus,
    onFetchDocuments,
    isDocumentFetching,
  } = useGetConsentStatus({
    getConsentData,
  });
  const [selectedConsentItem, setSelectedConsentItem] = useState(null);
  const [consentModal, setConsentModal] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);
  const { abhaId } = patientData || {};

  const STATUS_COLOR_MAPPING = {
    DENIED: "bg-[#FDE8E8] text-[#9B1C1C]",
    REQUESTED: "bg-[#FDF6B2] text-[#723B13]",
    APPROVED: "bg-[#DEF7EC] text-[#03543F]",
    GRANTED: "bg-[#DEF7EC] text-[#03543F]",
    REVOKED: "bg-[#FDE8E8] text-[#9B1C1C]",
  };

  const [statusId, setStatusId] = useState(null);

  return (
    <div
      className="bg-white text-[#2D2E33] mt-4 p-6 rounded-lg text-sm overflow-x-scroll"
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <ToastContainer />
      <div className="text-[#2D2E33] flex justify-between  mb-2">
        <div className="font-medium"> Requested Consent Records</div>
        {abhaId === null ? null : (
          <div>
            <PrimaryButton
              buttonName="New Consent Request"
              onClick={() => setConsentModal(true)}
            />
          </div>
        )}
      </div>
      {isConsentDataLoading && (
        <div>
          <Skeleton borderRadius={10} className="w-full h-16 rounded-lg" />
        </div>
      )}
      {consentData.map((item, index) => {
        const { artifacts = [], id, expiredAt, createdAt, status } = item || {};
        return (
          <div
            key={index}
            className="border border-[#8EA9FF] cursor-pointer rounded-lg my-4"
            onClick={() =>
              setSelectedConsentItem((prev) => (prev === id ? null : id))
            }
          >
            <div className="flex items-center p-3">
              <div className="w-9/12">
                Consent Records
                <span className="text-[#5E6066] ms-1">
                  (Raised on {formatDateTime(createdAt)})
                </span>
              </div>
              <div className="flex w-3/12 justify-between">
                <button
                  onClick={() => {
                    onConsentStatus(id);
                    setStatusId(id);
                  }}
                  disabled={isConsentStatus}
                  className={`${
                    isConsentStatus && statusId === id ? "animate-spin" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </button>
                <div
                  className={`${STATUS_COLOR_MAPPING[status]} flex justify-center items-center h-7 w-32 px-2 rounded-lg`}
                >
                  {status}
                </div>

                <div>
                  <div className="text-[#5E6066]"> Date of Expiry </div>
                  <div>{formatDateTime(expiredAt)}</div>
                </div>
              </div>
            </div>
            {artifacts.length > 0 && selectedConsentItem === id
              ? artifacts.map((artifact) => {
                  const { patientReferences, artifactId, hospitalName, hipId } =
                    artifact || {};
                  return (
                    <div className="px-5 py-2">
                      <div className="font-medium justify-between bg-[#ECECED]  py-2 rounded-lg px-5 flex ">
                        <h1>Reference Name</h1>
                        <h1>No. of Documents</h1>
                      </div>
                      <div>
                        <div className="flex p-4 items-center justify-between">
                          <div className="flex gap-3">
                            <button>
                              <FontAwesomeIcon icon={faAngleDown} />
                            </button>
                            {patientReferences.length === 0 ? (
                              <div> {artifactId}</div>
                            ) : (
                              <div>
                                {hospitalName === null ? hipId : hospitalName}
                                {/* {artifact.artifactId} */}
                              </div>
                            )}
                          </div>
                          {patientReferences.length === 0 ? (
                            <div>
                              <PrimaryButton
                                buttonName="Fetch Documents"
                                onClick={() => onFetchDocuments(artifactId)}
                                disabled={isDocumentFetching}
                              />
                            </div>
                          ) : (
                            <div className="flex justify-center w-[10%]">
                              {patientReferences.length}
                            </div>
                          )}
                        </div>
                        {patientReferences.length > 0 ? (
                          <div className="ps-16">
                            {patientReferences.map((patientRefer) => {
                              const { patientReference, careContexts = [] } =
                                patientRefer || {};
                              return (
                                <div>
                                  <div className="flex gap-3">
                                    <button>
                                      <FontAwesomeIcon icon={faAngleDown} />
                                    </button>
                                    {patientReference}
                                  </div>
                                  {careContexts.length > 0 && (
                                    <div>
                                      {careContexts.map((care) => {
                                        return (
                                          <div className="ms-5">
                                            <div className="flex gap-3 border-b p-3">
                                              <div>
                                                <img src={file} alt="file" />
                                              </div>
                                              <div>
                                                {care.careContextReference}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        );
      })}
      {consentModal ? (
        <NewConsentRequest
          consentModal={consentModal}
          setConsentModal={setConsentModal}
          getConsentData={getConsentData}
        />
      ) : null}
    </div>
  );
};

export default RequestedRecord;

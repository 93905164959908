import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetSelectedDiagnostic = ({ setIsSelectedReport = () => {} }) => {
  const [diagnosticData, setDiagnosticData] = useState({});
  const [isDiagnosticeLoading, setIsDiagnosticLoading] = useState(false);

  const getSelectedDiagnostic = async (id) => {
    setIsDiagnosticLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/diagnostic-reports/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setDiagnosticData(res.data);
      setIsSelectedReport(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsDiagnosticLoading(false);
  };

  return {
    diagnosticData,
    isDiagnosticeLoading,
    getSelectedDiagnostic,
  };
};

export default useGetSelectedDiagnostic;

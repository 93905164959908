import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useSavePrescription = ({ method = "post", contentRef }) => {
  const [isPrescriptionSaving, setIsPrescriptionSaving] = useState(false);
  const prescriptionData = useSelector((state) => state.prescriptionData);
  const [pastPrescription, setPastPrescription] = useState([]);
  const patientData = useSelector((state) => state.patientDetails);

  const { chiefComplaint, name } = patientData || {};

  const onSavePrescription = useCallback(
    async (mrNo) => {
      setIsPrescriptionSaving(true);
      const token = localStorage.getItem("authToken");
      try {
        const res = await axios[method](
          `/v2/danzo/prescriptions`,
          method === "post"
            ? {
                mrNo: mrNo,
                ...prescriptionData,
              }
            : {
                params: {
                  mrNo: mrNo,
                },
                headers: {
                  Authorization: token,
                  "ngrok-skip-browser-warning": "hgs",
                },
              },
          {
            headers: {
              Authorization: token,
              "ngrok-skip-browser-warning": "hgs",
            },
          }
        );
        // const newData = await res.data?.reverse();
        // setPastPrescription(newData);
        if (method === "post") {
          await axios.post(
            `/v2/danzo/record/create`,
            {
              patientMrNo: mrNo,
              careContexts: [
                {
                  fhirId: res.data.id,
                  hiType: "Prescription",
                  display: "Prescription",
                },
              ],
              visitDate: moment(new Date()).format("yyyy-MM-DD"),
              hospitalName: "Parchaa Healthcare AI",
              chiefComplaint: chiefComplaint,
            },
            {
              headers: {
                Authorization: token,
                "ngrok-skip-browser-warning": "hgs",
              },
            }
          );
          toast.success("Prescription Generated Successfully!", {
            className: "w-max",
            position: "top-center",
          });
          const element = contentRef.current;
          const opt = {
            margin: 6,
            filename: `${name}_prescription.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: {
              orientation: "p",
              unit: "mm",
              format: "a4",
              putOnlyUsedFonts: true,
              floatPrecision: 16, // or "smart", default is 16
            },
          };

          html2pdf().set(opt).from(element).save();
        }
        const newData = await res.data?.reverse();
        setPastPrescription(newData);
      } catch (error) {
        toast.error(
          // error?.response?.data?.message || "Oops! somthing went wrong !",
          method === "get"
            ? { className: "w-max top-20", position: "top-center" }
            : {
                className: "w-max",
                position: "top-center",
              }
        );
      }
      setIsPrescriptionSaving(false);
    },
    // eslint-disable-next-line
    [method]
  );

  return { onSavePrescription, isPrescriptionSaving, pastPrescription };
};

export default useSavePrescription;

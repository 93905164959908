import React from "react";
import Modal from "../../../../../common-components/Modal";
import backArrow from "../../../../../../images/back_arrow.svg";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";

const SelectedDischarge = ({
  isSelectedRecord,
  setIsSelectedRecord,
  selectedDischargeSummary,
  name,
}) => {
  const {
    dateCreated,
    proceduresPerformed,
    statusWhileDischarge,
    dateOfAdmission,
    dateOfDischarge,
    timeOfAdmission,
    timeOfDischarge,
    diagnosis,
    adviceOnDischarge,
    medication,
  } = selectedDischargeSummary || {};
  return (
    <div>
      <Modal
        showModal={isSelectedRecord}
        onClose={() => setIsSelectedRecord(false)}
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
      >
        <div className="m-4 text-sm">
          <div className="flex justify-between w-[60%]">
            <img src={backArrow} alt="backArrow" />
            <div>{name} (Discharge Summary)</div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Date & Time</div>
            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex my-3">
            <div className="w-1/2 flex">
              <div className="text-[#5E6066]"> Date of Admission : </div>
              <div className="ms-4"> {dateOfAdmission}</div>
            </div>
            <div className="w-1/2 flex">
              <div className="text-[#5E6066]">Date Of Discharge</div>
              <div className="ms-4">{dateOfDischarge}</div>
            </div>
          </div>
          <div className="flex my-3">
            <div className="w-1/2 flex">
              <div className="text-[#5E6066]"> Time of Admission : </div>
              <div className="ms-4"> {timeOfAdmission}</div>
            </div>
            <div className="w-1/2 flex">
              <div className="text-[#5E6066]">Time Of Discharge</div>
              <div className="ms-4">{timeOfDischarge}</div>
            </div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Details</div>
          </div>
          <div className="flex my-3 flex-wrap">
            <div className="w-1/2 flex flex-wrap">
              <div className="text-[#5E6066]"> Diagnosis : </div>
              <div className="ms-4"> {diagnosis}</div>
            </div>
            <div className="w-1/2 flex flex-wrap">
              <div className="text-[#5E6066]">Procedures Performed : </div>
              <div className="ms-4">{proceduresPerformed}</div>
            </div>
          </div>
          <div className="flex my-3 flex-wrap">
            <div className="w-1/2 flex flex-wrap">
              <div className="text-[#5E6066]"> Medication : </div>
              <div className="ms-4"> {medication}</div>
            </div>
            <div className="w-1/2 flex flex-wrap">
              <div className="text-[#5E6066]">Status While Discharge : </div>
              <div className="ms-4">{statusWhileDischarge}</div>
            </div>
          </div>
          <div className="flex my-3 flex-wrap">
            <div className="text-[#5E6066]">Advice on Discharging : </div>
            <div className="ms-4">{adviceOnDischarge || "--"}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectedDischarge;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetSelectedPrescriptionRecord = ({
  setIsSelectedRecord = () => {},
}) => {
  const [isPrescriptionLoading, setIsPrescriptionLoading] = useState(false);
  const [selectedPrescriptionData, setSelectedPrescriptionData] = useState({});

  const getSelectedPrescription = async (id) => {
    setIsPrescriptionLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/prescriptions/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setSelectedPrescriptionData(res.data);
      setIsSelectedRecord(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsPrescriptionLoading(false);
  };
  return {
    selectedPrescriptionData,
    getSelectedPrescription,
    isPrescriptionLoading,
  };
};

export default useGetSelectedPrescriptionRecord;

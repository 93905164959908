import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useAddOPconsultation = ({
  opConsultationInfo = {},
  getAvailableRecord = () => {},
  setActiveHealthTab = () => {},
}) => {
  const [isOPConsultationLoading, setIsOPConsultationLoading] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint } = patientData || {};
  const {
    allergey,
    history,
    symptoms,
    vitals,
    diagnosis,
    treatmentPlan,
    prescreption,
    followupDate,
  } = opConsultationInfo || {};

  const onOPconsultation = async () => {
    setIsOPConsultationLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/op-consultations`,
        {
          mrNo: mrNo,
          allergy: allergey,
          symptoms: symptoms,
          diagnosis: diagnosis,
          prescription: prescreption,
          history: history,
          observationVitals: vitals,
          treatmentPlan: treatmentPlan,
          followUpDate: followupDate,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: res.data.id,
              hiType: "OPConsultation",
              display: "OP Consultation",
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chiefComplaint,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      toast.success("OP Consultation report is submitted successfully !");
      setTimeout(() => {
        getAvailableRecord();
        setActiveHealthTab("");
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsOPConsultationLoading(false);
  };

  return { isOPConsultationLoading, onOPconsultation };
};

export default useAddOPconsultation;

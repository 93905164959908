import React from "react";
import Modal from "../../../../common-components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const CancellAppointment = ({ showModal, onClose }) => {
  return (
    <div>
      <Modal showModal={showModal} onClose={onClose} modalHeight="h-1/4">
        <div className="flex items-center h-full justify-center flex-col">
          <div className="my-2">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size="2x"
              color="#9CA3AF"
            />
          </div>
          <div className="my-2 text-[#6B7280]">
            Are you sure you want to
            <div>cancel the appointment?</div>
          </div>
          <div className="my-2 gap-4 flex">
            <button className="bg-[#C81E1E] px-2 py-1 disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] flex justify-center border border-[#C81E1E] item-center rounded-lg text-sm text-[#FFFFFF]">
              Yes, I'm sure
            </button>
            <button
              onClick={() => onClose()}
              className="flex border px-2 py-1 items-center justify-center bg-white border-[#E5E7EB] item-center rounded-lg text-sm text-[#1F2A37]"
            >
              No, cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancellAppointment;

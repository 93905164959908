import axios from "axios";
import { useCallback, useState } from "react";
// import { toast } from "react-toastify";

const useGetPatientList = () => {
  const [patientList, setPatientList] = useState([]);
  const [isPatientListLoading, setIsPatientListLoading] = useState(false);

  const getPatientList = useCallback(async (inputValue) => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/naruto/patient/search`, {
        params: {
          query: inputValue,
        },
        headers: {
          Authorization: token,
          // `Bearer ${token}`,
          // "ngrok-skip-browser-warning": "hgs",
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      setPatientList(res.data);
    } catch (error) {
      // toast.error("Oops somthing went wrong !", error);
      console.error(error, "error");
    }
    setIsPatientListLoading(false);
  }, []);

  return {
    patientList,
    getPatientList,
    isPatientListLoading,
  };
};

export default useGetPatientList;

import React, { memo, useState } from "react";
import Modal from "../../../../common-components/Modal";
import PrimaryButton from "../../../../common-components/Buttons/PrimaryButton";
import Select from "../../../../common-components/Select";
import { PURPOSE_OF_REQUEST } from "../../../../utils/purposeOfRequestConsent";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import useCreateConsentRequest from "./hooks/useCreateConsentRequest";
import { useSelector } from "react-redux";

const NewConsentRequest = ({
  consentModal,
  setConsentModal,
  getConsentData,
}) => {
  const patientData = useSelector((state) => state.patientDetails);
  const { abhaId } = patientData || {};
  const [consentInfo, setConsentInfo] = useState({
    abhaAddress: abhaId,
    purposeOfRequest: "",
    recordsFrom: "",
    recordsTo: "",
    expiryDate: "",
    healthInfoType: [],
  });

  const handleChange = (e, checkboxValue) => {
    const { value, name } = e.target || {};
    if (checkboxValue === undefined) {
      setConsentInfo((prev) => ({ ...prev, [name]: value }));
    } else {
      if (consentInfo.healthInfoType.includes(checkboxValue)) {
        const newHealthInfo = consentInfo.healthInfoType.filter(
          (item) => item !== checkboxValue
        );
        setConsentInfo((prev) => ({ ...prev, healthInfoType: newHealthInfo }));
      } else {
        setConsentInfo((prev) => ({
          ...prev,
          healthInfoType: [...prev.healthInfoType, checkboxValue],
        }));
      }
    }
  };

  const { onCreateConsent, isConsentCreating } = useCreateConsentRequest({
    consentInfo,
    setConsentModal,
    getConsentData,
  });

  return (
    <div>
      <Modal
        modalWidth="w-[90%]"
        modalHeight="min-h-1/2"
        showModal={consentModal}
        onClose={() => setConsentModal(false)}
      >
        <div className="text-sm py-4 px-6 text-[#2D2E33]">
          <div className="font-medium">Raise New Request</div>
          <div className="flex ps-4 mt-2 justify-between flex-wrap">
            <div className="my-2 w-1/4">
              <div className="text-[#5E6066] mb-2.5">ABHA Address</div>
              <div>
                <input
                  type="text"
                  name="abhaAddress"
                  onChange={handleChange}
                  value={consentInfo.abhaAddress}
                  className="pb-1 w-10/12 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="ABHA Address"
                />
              </div>
            </div>
            <div className="my-2 w-1/4">
              <div className="text-[#5E6066] mb-2.5">Purpose of Request</div>
              <div className="w-10/12">
                <Select
                  options={PURPOSE_OF_REQUEST}
                  onChange={handleChange}
                  name="purposeOfRequest"
                  upIcon={faAngleUp}
                  downIcon={faAngleDown}
                  className="pb-1.5 w-full placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Select"
                />
              </div>
            </div>
            <div className="my-2 w-1/4">
              <div className="text-[#5E6066] mb-2.5">Access Records From</div>
              <div>
                <input
                  type="date"
                  name="recordsFrom"
                  value={consentInfo.recordsFrom}
                  onChange={handleChange}
                  className="uppercase pb-1 w-10/12 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                />
              </div>
            </div>
            <div className="my-2 w-1/4">
              <div className="text-[#5E6066] mb-2.5">Access Records To</div>
              <div>
                <input
                  type="date"
                  value={consentInfo.recordsTo}
                  name="recordsTo"
                  onChange={handleChange}
                  className="uppercase pb-1 w-10/12 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Date"
                />
              </div>
            </div>
          </div>

          <div className="text-[#5E6066] ps-4 mt-5">Health Info Type</div>
          <div className="flex ps-4 flex-wrap">
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div className="">
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="ABHA Address"
                  onChange={(e) => handleChange(e, "WellnessRecord")}
                />
              </div>
              <div className="text-[#5E6066]">Wellness Records</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Select"
                  onChange={(e) => handleChange(e, "ImmunizationRecord")}
                />
              </div>
              <div className="text-[#5E6066]">Immunisation Records</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  onChange={(e) => handleChange(e, "DiagnosticReport")}
                />
              </div>
              <div className="text-[#5E6066]">Diagnostic Reports</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  onChange={(e) => handleChange(e, "DischargeSummary")}
                />
              </div>
              <div className="text-[#5E6066]">Discharge Summary</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  onChange={(e) => handleChange(e, "Prescription")}
                />
              </div>
              <div className="text-[#5E6066]">Prescriptions</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="checkbox"
                  onChange={(e) => handleChange(e, "HealthDocumentRecord")}
                />
              </div>
              <div className="text-[#5E6066]">Health Documents</div>
            </div>
            <div className="my-2 gap-2 items-center flex w-1/4">
              <div>
                <input
                  type="checkbox"
                  className="mt-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                  placeholder="Date"
                  onChange={(e) => handleChange(e, "OPConsultation")}
                />
              </div>
              <div className="text-[#5E6066]">OP Consultation Notes</div>
            </div>
          </div>
          <div className="p-4  w-1/4">
            <div className="text-[#5E6066] mb-2.5">Expiry Date</div>
            <div>
              <input
                type="date"
                name="expiryDate"
                onChange={handleChange}
                value={consentInfo.expiryDate}
                className="pb-1 w-11/12 uppercase placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                placeholder="Date"
              />
            </div>
          </div>
          <div className="flex justify-end pe-1 pb-3">
            <PrimaryButton
              buttonName="Submit Request"
              onClick={onCreateConsent}
              disabled={
                consentInfo.healthInfoType.length === 0 || isConsentCreating
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(NewConsentRequest);

import React, { useState } from "react";
import ParchaaLogo from "../../../../images/parchaa.svg";
import PrimaryButton from "../../../common-components/Buttons/PrimaryButton";
import SecondaryButton from "../../../common-components/Buttons/SecondaryButton";
import ParchaaHip from "../../../../images/parchaaHip.svg";
import SearchInput from "../../../common-components/SearchInput";
import DatePicker from "../../../common-components/DatePicker";
import Profile from "./Profile";
import { useDispatch, useSelector } from "react-redux";
import { setPatientVisitDate } from "../../../../redux-store/slice/patientVisitDate";
import moment from "moment";
import { setGlobalSearch } from "../../../../redux-store/slice/globalSearchSlice";
import { setDashboardDrawer } from "../../../../redux-store/slice/checkInDrawerSlice";
const Header = () => {
  const dispatch = useDispatch();
  const [openProfile, setOpenProfile] = useState(false);
  const currentDate = useSelector((state) => state.patientVisitDate);
  const globalSearch = useSelector((state) => state.globalSearch);

  const handleChange = (e) => {
    dispatch(setGlobalSearch(e.target.value));
  };

  const handleDateChange = (e) => {
    const value = moment(e.target.value).format("yyyy-MM-DD");
    dispatch(setPatientVisitDate(value));
  };

  const onPreviousDate = () => {
    const newDate = moment(currentDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    dispatch(setPatientVisitDate(newDate));
  };

  const onNextDate = () => {
    const newDate = moment(currentDate).add(1, "days").format("YYYY-MM-DD");
    dispatch(setPatientVisitDate(newDate));
  };

  const onAddPatient = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setDashboardDrawer({
        checkInDrawer: false,
        userDrawer: true,
        skipABHA: false,
        createABHAbyAadhaar: false,
        createABHAaddress: false,
      })
    );
  };
  return (
    <div className="fixed border-b w-full bg-white p-1">
      <div className="flex relative  mx-4 items-center">
        <div className="w-[4%]">
          <img src={ParchaaLogo} alt="parcha-logo" />
        </div>
        <div className="flex w-[40%]">
          <div>
            <DatePicker
              onChange={handleDateChange}
              value={currentDate}
              onPreviousDate={onPreviousDate}
              onNextDate={onNextDate}
            />
          </div>
          <div className="ms-3">
            <SecondaryButton
              buttonName="Today"
              onClick={() =>
                dispatch(
                  setPatientVisitDate(moment(new Date()).format("yyyy-MM-DD"))
                )
              }
            />
          </div>
        </div>
        <div className="flex w-1/2">
          <div className="w-7/12">
            <SearchInput onChange={handleChange} inputValue={globalSearch} />
          </div>
          <div className="ms-3">
            <PrimaryButton
              onClick={(e) => {
                onAddPatient(e);
              }}
              showIcon={true}
              buttonName="Add Patient"
            />
          </div>
        </div>
        <div className="flex w-1/12 items-center justify-end">
          {/* <div>
            <FontAwesomeIcon icon={faDownload} color="#6B7280" size="lg" />
          </div>
          <div>
            <FontAwesomeIcon icon={faBell} color="#6B7280" size="lg" />
          </div> */}
          <button
            className=" cursor-pointer"
            onClick={() => {
              setOpenProfile((prev) => !prev);
            }}
          >
            <img src={ParchaaHip} alt="ParchaaHip" />
          </button>
        </div>
        <Profile openProfile={openProfile} setOpenProfile={setOpenProfile} />
      </div>
    </div>
  );
};

export default Header;

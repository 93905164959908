import React, { useState } from "react";
import HIPdashboard from "../hip/dashboard/visit-list/HIPdashboard";
import CreateAppointment from "../hip/dashboard/create-appointment/CreateAppointment";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardDrawer } from "../../../redux-store/slice/checkInDrawerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import AddNewPatientWithoutAbha from "../hip/dashboard/add-new-patient-options/skip-abha/AddNewPatientWithoutAbha";
import CreateAbhaAddress from "../hip/dashboard/create-abha-address/CreateAbhaAddress";
// import CreateABHAnumber from "../hip/dashboard/create-abha-number/CreateABHAnumber";
import AddNewPatient from "../hip/dashboard/add-new-patient-options/with-abha/AddNewPatient";
import Tooltip from "../../common-components/Tooltip";
import CreateABHAnumberOptions from "../hip/dashboard/create-abha-number/CreateABHAnumberOptions";

const Content = () => {
  const dashboardDrawer = useSelector((state) => state.dashboardDrawer);
  const [patient, setPatient] = useState({});
  const dispatch = useDispatch();

  const handleCheckInDrawer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setDashboardDrawer({
        userDrawer: false,
        checkInDrawer: !dashboardDrawer.checkInDrawer,
        skipABHA: false,
        createABHAbyAadhaar: false,
        createABHAaddress: false,
      })
    );
  };
  const handleUserDrawer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setDashboardDrawer({
        checkInDrawer: false,
        userDrawer: !dashboardDrawer.userDrawer,
        skipABHA: false,
        createABHAbyAadhaar: false,
        createABHAaddress: false,
      })
    );
  };

  return (
    <div className="flex w-full border border-t-0 justify-between">
      <div className="w-[96%] border-r">
        <HIPdashboard />
      </div>
      <div
        className={
          dashboardDrawer.checkInDrawer ||
          dashboardDrawer.userDrawer ||
          dashboardDrawer.skipABHA ||
          dashboardDrawer.createABHAbyAadhaar ||
          dashboardDrawer.createABHAaddress
            ? "w-[34%] flex"
            : "w-[4%] flex flex-col items-center"
        }
      >
        <div className="mx-2 mt-2">
          <Tooltip content="Add Patient">
            <button
              className={`${
                dashboardDrawer.userDrawer ||
                dashboardDrawer.skipABHA ||
                dashboardDrawer.createABHAbyAadhaar ||
                dashboardDrawer.createABHAaddress
                  ? "bg-[#D8E3FF]"
                  : ""
              } flex py-2.5 px-2 rounded-lg hover:text-[#4C6AF7]`}
              onClick={(e) => handleUserDrawer(e)}
            >
              <FontAwesomeIcon
                icon={faUserPlus}
                color={
                  dashboardDrawer.userDrawer ||
                  dashboardDrawer.skipABHA ||
                  dashboardDrawer.createABHAbyAadhaar ||
                  dashboardDrawer.createABHAaddress
                    ? "#4C6AF7"
                    : ""
                }
              />
            </button>
          </Tooltip>
          <Tooltip content="Book Appointment">
            <button
              className={`${
                dashboardDrawer.checkInDrawer ? "bg-[#D8E3FF]" : ""
              } flex p-2 mt-2.5 rounded-lg`}
              onClick={(e) => {
                handleCheckInDrawer(e);
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`fill-current ${
                  dashboardDrawer.checkInDrawer
                    ? "text-[#4C6AF7]"
                    : "text-[#1F2A37]"
                } hover:text-[#4C6AF7]`}
              >
                <path
                  id="Path 3587"
                  d="M1.68774 18C1.46598 17.9999 1.24641 17.9561 1.04157 17.8711C0.83674 17.7861 0.650649 17.6616 0.493928 17.5047C0.337206 17.3478 0.212923 17.1616 0.128175 16.9566C0.0434271 16.7517 -0.000126845 16.5321 2.77474e-07 16.3103V1.68677C0.000256842 1.23932 0.178185 0.81029 0.494671 0.493986C0.811156 0.177682 1.24029 -7.35547e-08 1.68774 0H16.3103C16.7579 -6.60755e-07 17.1873 0.17762 17.5041 0.493868C17.8209 0.810117 17.9992 1.23915 18 1.68677V16.3103C17.9992 16.758 17.8209 17.1872 17.5041 17.5036C17.1874 17.82 16.758 17.9978 16.3103 17.9981L1.68774 18ZM1.68774 16.3123H9.8429V6.18678H1.68774V16.3123ZM16.3103 4.50581V1.68677H1.68774V4.49903L16.3103 4.50581Z"
                />
              </svg>
            </button>
          </Tooltip>
        </div>
        {dashboardDrawer.checkInDrawer && (
          <div className="border-l w-full">
            <CreateAppointment patient={patient} />
          </div>
        )}
        {dashboardDrawer.userDrawer ? (
          <div className="border-l w-full">
            <AddNewPatient setPatient={setPatient} />
          </div>
        ) : null}
        {dashboardDrawer.skipABHA ? (
          <div className="border-l w-full">
            <AddNewPatientWithoutAbha setPatient={setPatient} />
          </div>
        ) : null}
        {dashboardDrawer.createABHAbyAadhaar && (
          <div className="border-l w-full">
            {/* <CreateABHAnumber setPatient={setPatient} /> */}
            <CreateABHAnumberOptions setPatient={setPatient} />
          </div>
        )}

        {dashboardDrawer.createABHAaddress && (
          <div className="border-l w-full">
            <CreateAbhaAddress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;

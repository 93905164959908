import React, { useState } from "react";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import FileUpload from "../../../../../../images/file_upload.svg";
import TertiaryButton from "../../../../../common-components/Buttons/TertiaryButton";
import PrimaryButton from "../../../../../common-components/Buttons/PrimaryButton";
import Modal from "../../../../../common-components/Modal";
import { formatDateTime } from "../../../../../utils/formatDateTime";
import useAddImmunisationRecord from "../hooks/useAddImmunisationRecord";

const AddImmunisationRecord = ({
  setActiveHealthTab = () => {},
  activeHealthTab,
  getAvailableRecord,
}) => {
  const [immunisationInfo, setImmunisationInfo] = useState({
    dateOfVaccination: formatDateTime(new Date()),
    vaccineCode: "",
    manufacturer: "",
    lotNumber: "",
    doseNumber: 0,
    route: "",
  });

  const handleImmunisationChange = (e) => {
    const { name, value } = e.target || {};
    setImmunisationInfo((prev) => ({ ...prev, [name]: value }));
  };

  const { isAddImmunisationLoading, onAddImmunisation } =
    useAddImmunisationRecord({
      immunisationInfo,
      getAvailableRecord,
      setActiveHealthTab,
    });

  const onReset = () => {
    setImmunisationInfo({
      dateOfVaccination: formatDateTime(new Date()),
      vaccineCode: "",
      manufacturer: "",
      lotNumber: "",
      doseNumber: 0,
      route: "",
    });
  };

  return (
    <div className="text-sm text-[#2D2E33">
      <Modal
        showModal={activeHealthTab === "add_immunisation_record" ? true : false}
        onClose={() => setActiveHealthTab("")}
        modalHeight="min-h-1/2"
        modalWidth="w-[90%]"
      >
        <div className="py-4 px-6">
          <h1 className="text-[#2D2E33 text-base  font-medium">
            Immunisation Record
          </h1>
          <div className="flex py-4">
            <div className="w-8/12">
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Vaccination
                </h1>
                <div className="flex ps-4 mt-2 flex-wrap">
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2">
                      Date of Vaccination
                    </div>
                    <div>
                      <input
                        type="date"
                        placeholder="DD/MM/YYYY"
                        onChange={handleImmunisationChange}
                        value={immunisationInfo.dateOfVaccination}
                        name="dateOfVaccination"
                        className="pb-1 uppercase placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Vaccine Code</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Vaccine Code"
                        name="vaccineCode"
                        value={immunisationInfo.vaccineCode}
                        onChange={handleImmunisationChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Manufacturer</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Name"
                        name="manufacturer"
                        value={immunisationInfo.manufacturer}
                        onChange={handleImmunisationChange}
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Lot Number</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Lot Number"
                        value={immunisationInfo.lotNumber}
                        name="lotNumber"
                        onChange={handleImmunisationChange}
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Dose Number</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Dose Number"
                        onChange={handleImmunisationChange}
                        value={immunisationInfo.doseNumber}
                        name="doseNumber"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Route</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Route"
                        name="route"
                        value={immunisationInfo.route}
                        onChange={handleImmunisationChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-end pt-5">
                <TertiaryButton
                  buttonName="Reset"
                  width="w-fit"
                  onClick={onReset}
                />
                <PrimaryButton
                  buttonName="Submit"
                  width="w-fit"
                  onClick={onAddImmunisation}
                  disabled={isAddImmunisationLoading}
                />
              </div>
            </div>
            <div className="text-[#7F8185] flex flex-col items-center w-[10%] justify-center">
              <div className="flex flex-col items-center">
                <div className="border-l h-28"></div>
                <div>or</div>
                <div className="border-l h-28"></div>
              </div>
            </div>
            <div className="border-dashed h-fit border gap-3 flex flex-col rounded-lg py-6 px-12 bg-[#F9FAFB] border-[#C6C7C9] justify-center items-center">
              <div className="flex gap-2 justify-center flex-col">
                <img
                  src={FileUpload}
                  alt="FileUpload"
                  className="h-12 w-12 mx-auto"
                />
                <h1 className="text-[#2D2E33] text-sm">
                  Drag & Drop File to Upload
                </h1>
                <h1 className="text-[#7F8185] mx-auto">.png/ .jpg/ .pdf</h1>
              </div>
              <div>or</div>
              <SecondaryButton buttonName="Browse Files" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddImmunisationRecord;

import React, { useState } from "react";
import DoctorHeader from "./header/DoctorHeader";
import PatientDetails from "./patient-details/PatientDetails";
import PatientMedicalDetails from "./patient-details/PatientMedicalDetails";
import { useSelector } from "react-redux";
import PatientDetailInModal from "./patient-details-in-modal/PatientDetailInModal";
import ReferalModal from "./ReferalModal";
import Notifications from "./Notifications";
import DoctorNotesModal from "./side-bar/DoctorNotesModal";
import PatientHealthRecord from "./patient-details/patient-health-records/PatientHealthRecord";
import ConsentManager from "./patient-details/consent-manager/ConsentManager";
import useGetWellnessRecord from "./patient-details/patient-health-records/hooks/useGetWellnessRecord";

const Doctor = () => {
  const [showExpandModal, setShowExpandModal] = useState("");
  const [referalModal, setReferalModal] = useState(false);
  const [consentModal, setConsentModal] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const isDoctorNotes = useSelector((state) => state.doctorNotes);
  const [activePatientDetailsTab, setActivePatientDetailsTab] =
    useState("patient_details");
  const { wellnessData, isWellDataLoading, getWellnessRecord } =
    useGetWellnessRecord();

  return (
    <div className="w-full relative">
      <div className="w-full border-b border-[#D8E3FF] bg-white fixed">
        <DoctorHeader
          setReferalModal={setReferalModal}
          setNotifications={setNotifications}
        />
      </div>
      <div className="w-full">
        <div style={{ paddingTop: "47px" }} className="flex w-full">
          <div
            className="w-full"
            // className={`${activeTab === null ? "w-[96%]" : "w-[71%]"} mr-auto`}
          >
            <div className="w-full fixed">
              <PatientDetails
                activePatientDetailsTab={activePatientDetailsTab}
                setActivePatientDetailsTab={setActivePatientDetailsTab}
                wellnessData={wellnessData}
                isWellDataLoading={isWellDataLoading}
              />
            </div>
            <div className="w-full mt-44 p-8">
              {activePatientDetailsTab === "patient_details" && (
                <PatientMedicalDetails
                  setShowExpandModal={setShowExpandModal}
                />
              )}
              {activePatientDetailsTab === "health_records" && (
                <PatientHealthRecord getWellnessRecord={getWellnessRecord} />
              )}
              {activePatientDetailsTab === "consent_manager" && (
                <ConsentManager
                  consentModal={consentModal}
                  setConsentModal={setConsentModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showExpandModal !== "" ? (
        <PatientDetailInModal
          showExpandModal={showExpandModal}
          setShowExpandModal={setShowExpandModal}
        />
      ) : null}
      {referalModal ? (
        <ReferalModal
          referalModal={referalModal}
          setReferalModal={setReferalModal}
        />
      ) : null}
      {notifications ? (
        <div className="fixed w-1/4 z-50 top-24 right-6">
          <Notifications
            notifications={notifications}
            setNotifications={setNotifications}
          />
        </div>
      ) : null}
      {isDoctorNotes ? (
        <div>
          <DoctorNotesModal />
        </div>
      ) : null}
    </div>
  );
};

export default Doctor;

import React from "react";
import FileUpload from "../../../../../../images/file_upload.svg";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import Modal from "../../../../../common-components/Modal";

const AddHealthDocument = ({
  setActiveHealthTab = () => {},
  activeHealthTab = "",
}) => {
  return (
    <div
      className="bg-white p-4 rounded-lg text-sm text-[#2D2E33"
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <Modal
        showModal={activeHealthTab === "health_document" ? true : false}
        onClose={() => setActiveHealthTab("")}
        modalWidth="w-[80%]"
      >
        <div className="py-4 px-5">
          <h1 className="text-[#2D2E33 pb-2 text-base  font-medium">
            Health Document
          </h1>
          <div className="border-dashed h-fit border gap-12 text-sm flex rounded-lg py-8 px-7 bg-[#F9FAFB] border-[#C6C7C9] justify-center items-center">
            <div className="flex justify-center flex-col">
              <img
                src={FileUpload}
                alt="FileUpload"
                className="h-12 w-12 mx-auto"
              />
              <h1 className="mt-1 text-[#2D2E33] text-sm">
                Drag & Drop File to Upload
              </h1>
              <h1 className="text-[#7F8185] mx-auto">.png/ .jpg/ .pdf</h1>
            </div>
            <div>or</div>
            <SecondaryButton buttonName="Browse Files" width="min-w-32" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddHealthDocument;

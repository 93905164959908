import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../../common-components/Dropdown";
import { VIEW_MORE_OPTIONS } from "../../../../constants/Constant";
import CancellAppointment from "./CancellAppointment";
import { useNavigate } from "react-router-dom";
import ListLoader from "../../../../common-components/ListLoader";
import { startCase } from "../../../../utils/startCase";
import { ToastContainer } from "react-toastify";
import cretePrescIcon from "../../../../../images/createPrescription.svg";
import useGetAllVisit from "./hooks/useGetAllVisit";

const STATUS_COLOR_MAPPING = {
  UPCOMING: "bg-[#DEF7EC] text-[#03543F]",
  COMPLETED: "bg-[#EDEBFE] text-[#5521B5]",
  CANCELLED: "bg-#FDE8E8 text-[#9B1C1C]",
  INQUEUE: "bg-[#FDF6B2] text-[#723B13]",
};
const FilteredList = ({ patientVisitList = [] }) => {
  const [selctedValue, setSelectedValue] = useState("");

  const navigate = useNavigate();

  const { isPatientVisitLoading, getAllPatientVisit, params } =
    useGetAllVisit();

  useEffect(() => {
    getAllPatientVisit();
  }, [params, getAllPatientVisit]);

  const onSelect = (option, id) => {
    setSelectedValue(option.value);
    // setSelectedVisitId(id);
    navigate(option.value === "view_details" && "/patientProfile", {
      state: { visitId: id },
    });
    console.log(option, "option");
  };

  return (
    <div className="w-full">
      <ToastContainer />
      <div className="flex w-full uppercase bg-[#F9FAFB] py-3 ps-3 text-sm font-medium text-gray-800">
        <div className="w-[14%]">Token No.</div>
        <div className="w-[16%]">Name</div>
        <div className="w-[16%]">ABHA ID / PARCHAA ID</div>
        <div className="w-[16%]">Visit type</div>
        <div className="w-[14%]">Visit Status</div>
        <div className="w-[20%]">Actions</div>
        <div className="w-[4%]"></div>
      </div>
      {isPatientVisitLoading ? (
        <ListLoader />
      ) : (
        <>
          {patientVisitList.length > 0 ? (
            (patientVisitList || []).map((item) => {
              const {
                visitId,
                name,
                visitType,
                abhaId,
                mrno,
                visitStatus,
                tokenNumber,
              } = item || {};
              return (
                <div
                  key={visitId}
                  className="w-full border-b  py-3 ps-3  text-sm text-gray-700"
                >
                  <div className="flex w-full" key={visitId}>
                    <div className="w-[14%]">{tokenNumber}</div>
                    <div
                      className="w-[16%]"
                      // onClick={() =>
                      //   navigate("/patientProfile", {
                      //     state: { visitId: visitId },
                      //   })
                      // }
                    >
                      {startCase(name)}
                    </div>
                    <div className="w-[16%]">{abhaId || `PAR${mrno}`}</div>
                    <div className="w-[16%]">
                      {visitType === "INPERSON" ? "In-Person" : visitType}
                    </div>
                    <div className="w-[14%]">
                      <span
                        className={`${STATUS_COLOR_MAPPING[visitStatus]} px-2 py-1 rounded-lg`}
                      >
                        {startCase(visitStatus)}
                      </span>
                    </div>
                    <div className="w-[20%]">
                      <button
                        onClick={() =>
                          navigate("/doctor", {
                            state: { visitId: visitId },
                          })
                        }
                        className="bg-[#4C6AF7] disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] gap-2 flex items-center text-white px-2 py-1 rounded-md"
                      >
                        <img src={cretePrescIcon} alt="cretePrescIcon" />
                        Create Prescription
                      </button>
                    </div>
                    <div className="w-[4%]">
                      <Dropdown
                        onSelect={onSelect}
                        options={VIEW_MORE_OPTIONS}
                        icon={faEllipsisVertical}
                        id={visitId}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center p-2 text-red-600">
              Oops no results found !
            </div>
          )}
        </>
      )}
      {selctedValue === "cancel_appointment" ? (
        <CancellAppointment
          showModal={selctedValue === "cancel_appointment" ? true : false}
          onClose={() => setSelectedValue("")}
        />
      ) : null}
    </div>
  );
};

export default FilteredList;

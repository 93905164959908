import React, { memo } from "react";
import Modal from "../../../../../common-components/Modal";
import backArrow from "../../../../../../images/back_arrow.svg";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";

const SelectedDiagnosticReport = ({
  isSelectedReport,
  setIsSelectedReport,
  diagnosticData,
  name,
}) => {
  const {
    dateCreated,
    reportConclusion,
    testName,
    reportType,
    reportCategory,
    reportCreatedDate,
  } = diagnosticData || {};
  return (
    <div>
      <Modal
        showModal={isSelectedReport}
        onClose={() => setIsSelectedReport(false)}
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
      >
        <div className="m-4">
          <div className="flex justify-between w-[60%]">
            <img src={backArrow} alt="backArrow" />
            <div>{name} (Diagnostic Reports)</div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Report Type</div>

            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex justify-between mx-3">
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Report Type : </div>
              <div>{reportType}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] "> Report Created Date</div>
              <div>{reportCreatedDate}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] "> Report Category</div>
              <div>{reportCategory}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Test name</div>
              <div>{testName}</div>
            </div>
          </div>
          <div className="p-2 mt-3 flex bg-[#ECECED] rounded">Details</div>
          <div className="m-1">{reportConclusion}</div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(SelectedDiagnosticReport);

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setPatientDetails } from "../../../../redux-store/slice/patientDetails";
import { setPrescriptionData } from "../../../../redux-store/slice/prescriptionDataSlice";
// import { toast } from "react-toastify";

const useGetPatientDetails = () => {
  const dispatch = useDispatch();
  const stateData = useLocation();
  const prescriptionData = useSelector((state) => state.prescriptionData);
  const [isPatientDetailsLoading, setIsPatientDetailsLoading] = useState(true);
  const { state } = stateData || {};
  const { visitId } = state || {};

  const getPatientDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/shizune/visit`, {
        params: {
          id: visitId,
        },
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      dispatch(setPatientDetails(res.data));
      if (res.data.abhaId !== null) {
        const profilePhoto = await axios.get(
          `/v2/naruto/patient/profilePhoto/${res.data.mrNo}`,
          {
            headers: {
              Authorization: token,
              "ngrok-skip-browser-warning": "hgs",
            },
            responseType: "blob",
          }
        );

        dispatch(
          setPatientDetails({
            ...res.data,
            profilePhoto:
              profilePhoto.data?.size === 0
                ? undefined
                : URL.createObjectURL(profilePhoto.data),
          })
        );
      }
      dispatch(
        setPrescriptionData({
          ...prescriptionData,
          chiefComplaint: res.data.chiefComplaint,
        })
      );
      setIsPatientDetailsLoading(false);
    } catch (error) {
      // toast.error("Oops somthing went error", error);
      console.error(error, "error");
      setIsPatientDetailsLoading(false);
    }
    // eslint-disable-next-line
  }, [visitId, dispatch]);

  useEffect(() => {
    getPatientDetails();
  }, [visitId, getPatientDetails]);

  return { isPatientDetailsLoading };
};

export default useGetPatientDetails;

import React, { useContext, useState } from "react";
import { Context } from "../DoctorAccount";
import specialitiesOfDoctor from "../../../../../../images/specialitiesOfDoctor.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DeleteSpeciality from "./DeleteSpeciality";

const Specialities = () => {
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};
  const { professionalDeatils } = doctorData || {};
  const { speciality = ["skdk", "jp"] } = professionalDeatils || {};
  const [isDeleteSpeciality, setIsDeleteSpeciality] = useState(false);

  return (
    <div>
      {speciality.map((item, index) => {
        const { id } = item || {};
        return (
          <div
            key={index}
            className="flex last:border-none py-4 border-b items-center w-full"
          >
            <div className="flex justify-between items-center">
              <img src={specialitiesOfDoctor} alt="specialitiesOfDoctor" />
              <div className="text-[#111928] text-sm font-medium">{item}</div>
            </div>

            <div className="flex gap-4">
              <button className="flex items-center active:animate-bounce justify-center bg-white item-center px-2 py-2 rounded-lg text-sm text-[#1F2A37]">
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button
                className="px-2.5 py-1.5 active:animate-bounce rounded-lg"
                onClick={() => setIsDeleteSpeciality(true)}
              >
                <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
              </button>
            </div>
            {isDeleteSpeciality && (
              <DeleteSpeciality
                onClose={() => setIsDeleteSpeciality(false)}
                showModal={isDeleteSpeciality}
                id={id}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Specialities;

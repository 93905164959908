import React from "react";

const ActivePatientTab = ({
  setActivePatientDetailsTab = () => {},
  activePatientDetailsTab = "",
}) => {
  return (
    <div className="flex font-medium text-sm">
      <button
        className={`${
          activePatientDetailsTab === "patient_details"
            ? "text-[#4C6AF7] border-b-2 border-[#6C8BFC]"
            : "text-[#7F8185]"
        } text-sm w-[15%] me-3 py-3`}
        onClick={() => setActivePatientDetailsTab("patient_details")}
      >
        Patient's Details
      </button>
      <button
        className={`${
          activePatientDetailsTab === "health_records"
            ? "text-[#4C6AF7] border-b-2 border-[#6C8BFC]"
            : "text-[#7F8185]"
        } text-sm w-[15%] me-3 py-3`}
        onClick={() => setActivePatientDetailsTab("health_records")}
      >
        Health Records
      </button>
      {/* <button
        className={`${
          activePatientDetailsTab === "consent_manager"
            ? "text-[#4C6AF7] border-b-2 border-[#6C8BFC]"
            : "text-[#7F8185]"
        } text-sm w-[15%] me-3 py-3`}
        onClick={() => setActivePatientDetailsTab("consent_manager")}
      >
        Consent Manager
      </button> */}
    </div>
  );
};

export default ActivePatientTab;

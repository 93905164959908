import React from "react";
import useGetWellnessRecord from "../patient-details/patient-health-records/hooks/useGetWellnessRecord";
import Skeleton from "react-loading-skeleton";
import { formatDateTime } from "../../../utils/formatDateTime";

const PastVitals = () => {
  const { wellnessData, isWellDataLoading } = useGetWellnessRecord();

  return (
    <div>
      {isWellDataLoading ? (
        [1, 2, 3, 4, 5].map((_, index) => <Skeleton key={index} />)
      ) : (
        <>
          {wellnessData.length === 0 ? (
            <div className="font-light p-2">No data found ! </div>
          ) : (
            <div className="flex items-center mt-2 w-full overflow-x-scroll">
              <div className="text-[#6B7280] w-full">
                <div className=" text-gray-900 flex pb-2">Date & Time</div>
                <div className="flex pb-2">BP</div>
                <div className="flex pb-2 ">Breate Rate</div>
                <div className="flex pb-2 ">Temp</div>
                <div className="flex pb-2">Pulse Rate</div>
                <div className="flex pb-2">Weight</div>
                <div className="flex pb-2 ">Height</div>
                <div className="flex pb-2">BMI</div>
              </div>
              {wellnessData.map((wellness, index) => {
                const {
                  dateCreated,
                  systolicPressure,
                  diastolicPressure,
                  height,
                  weight,
                  pulseRate,
                  breathRate,
                  temperature,
                } = wellness || {};
                return (
                  <div
                    className="w-full text-[#6B7280] pb-2 overflow-x-scroll"
                    key={index}
                  >
                    <div className="pb-2 text-gray-900">
                      {formatDateTime(dateCreated)}
                    </div>

                    <div className="w-full text-[#E02424] flex pb-2">
                      {systolicPressure}/{diastolicPressure}
                    </div>

                    <div className=" text-[#1F2A37] pb-2 ">{breathRate}</div>

                    <div className=" text-[#1F2A37] pb-2">{temperature}</div>

                    <div className=" text-[#1F2A37] pb-2">
                      {pulseRate || "--"}
                    </div>
                    <div className=" text-[#1F2A37] pb-2">{weight}</div>
                    <div className=" text-[#1F2A37] pb-2">{height}</div>
                    <div className=" text-[#1F2A37] pb-2">
                      {(
                        weight?.replace(/\D/g, "") /
                        ((height?.replace(/\D/g, "") / 100) *
                          (height?.replace(/\D/g, "") / 100))
                      ).toFixed(2) || "--"}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PastVitals;

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useGetAllInvestigations = () => {
  const [labInvestigations, setLabInvestigations] = useState([]);
  const [isLabInvestigationLoading, setIsLabInvestigationLoading] =
    useState(true);
  const prescriptionData = useSelector((state) => state.prescriptionData);

  const { diagnosis = [] } = prescriptionData || {};

  const getAllInvestigations = useCallback(
    async (inputValue = "") => {
      const token = localStorage.getItem("authToken");
      try {
        // const res = await axios.get(`/v2/cortex/investigations/search`, {
        const res = await axios.get(
          `/v2/cortex/investigations`,
          {
            params: { query: inputValue, diagnosis: diagnosis[0] },
            headers: { Authorization: token },
          }
        );
        setLabInvestigations(res.data?.investigations);
      } catch (error) {
        console.error(error, "error");
        toast.error(error?.response?.data?.message, {
          position: "top-center",
        });
      }
      setIsLabInvestigationLoading(false);
    },
    [diagnosis]
  );

  useEffect(() => {
    getAllInvestigations();
  }, [getAllInvestigations]);

  return {
    labInvestigations,
    isLabInvestigationLoading,
    getAllInvestigations,
  };
};

export default useGetAllInvestigations;

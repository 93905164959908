import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetSelectedWellnessRecord = ({ setIsSelectedRecord = () => {} }) => {
  const [wellnessData, setWellnessData] = useState({});
  const [isWellnessDataLoading, setIsWellnessDataLoading] = useState(false);

  const getSelectedWellnessData = async (id) => {
    setIsWellnessDataLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/wellness-records/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setWellnessData(res.data);
      setIsSelectedRecord(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsWellnessDataLoading(false);
  };

  return {
    wellnessData,
    isWellnessDataLoading,
    getSelectedWellnessData,
  };
};

export default useGetSelectedWellnessRecord;

import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useAddDiagnosticReport = ({
  diagnosticReportInfo = {},
  getAvailableRecord = () => {},
  setActiveHealthTab = () => {},
}) => {
  const {
    reportType,
    reportCreatedDate,
    reportCategory,
    testName,
    reportConclusion,
  } = diagnosticReportInfo || {};
  const [isAddingDiagnostic, setIsAddingDiagnostic] = useState(false);

  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint } = patientData || {};

  const onDianosticReport = async () => {
    setIsAddingDiagnostic(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/diagnostic-reports`,
        {
          mrNo: mrNo,
          reportType: reportType,
          reportCreatedDate: reportCreatedDate,
          reportCategory: reportCategory,
          testName: testName,
          reportConclusion: reportConclusion,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: res.data.id,
              hiType: "DiagnosticReport",
              display: "Diagnostic Report",
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chiefComplaint,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      toast.success("Diagnostic Report is submitted successfully !");
      setTimeout(() => {
        getAvailableRecord();
        setActiveHealthTab("");
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsAddingDiagnostic(false);
  };

  return { onDianosticReport, isAddingDiagnostic };
};

export default useAddDiagnosticReport;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetSelectedImmunisitaionRecord = ({ setIsSelectedRecord }) => {
  const [immunisationData, setImmunisationData] = useState({});
  const [isImmunisationLoading, setIsImmunisationLoading] = useState(false);

  const getImmunisationData = async (id) => {
    setIsImmunisationLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/immunization-records/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setImmunisationData(res.data);
      setIsSelectedRecord(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsImmunisationLoading(false);
  };

  return {
    immunisationData,
    isImmunisationLoading,
    getImmunisationData,
  };
};

export default useGetSelectedImmunisitaionRecord;

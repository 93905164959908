import React, { useState } from "react";
import SearchInput from "../../../../common-components/SearchInput";
import NotesList from "./NotesList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateNewNoteModal from "./CreateNewNoteModal";

const Notes = () => {
  const [createNewNoteModal, setCreateNewNoteModal] = useState(false);
  return (
    <div>
      <div
        className="m-8  pt-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div>
          <div className="w-full justify-between flex px-4">
            <div className="w-1/3">
              <SearchInput />
            </div>
            <div>
              <button
                onClick={() => setCreateNewNoteModal(true)}
                className="bg-[#1C64F2] gap-2 items-center disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] flex justify-center border border-[#1A56DB] item-center px-3 py-1.5 rounded-lg text-sm text-[#FFFFFF]"
              >
                <FontAwesomeIcon icon={faPlus} />
                Add New Note
              </button>
            </div>
          </div>
          <div className="pt-4">
            <NotesList />
          </div>
        </div>
      </div>
      {createNewNoteModal ? (
        <CreateNewNoteModal
          createNewNoteModal={createNewNoteModal}
          setCreateNewNoteModal={setCreateNewNoteModal}
        />
      ) : null}
    </div>
  );
};

export default Notes;

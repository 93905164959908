import React, { useState } from "react";
import AvailableRecords from "./available-record/AvailableRecords";
import RequestedRecord from "./available-record/requested-record/RequestedRecord";
import AddWellnessRecord from "./wellness-record/AddWellnessRecord";
import AddHealthDocument from "./health-document/AddHealthDocument";
import AddImmunisationRecord from "./immunisation/AddImmunisationRecord";
import AddOPconsultation from "./op-consultation/AddOPconsultation";
import AddDiagnosticReport from "./diagnostic-report/AddDiagnosticReport";
import AddDischargeSummary from "./discharge-summary/AddDischargeSummary";
import useGetAvailableRecord from "../patient-health-records/hooks/useGetAvailableRecord";

const PatientHealthRecord = ({ getWellnessRecord = () => {} }) => {
  const [careContextsId, setCareContextsId] = useState([]);
  const [activeHealthTab, setActiveHealthTab] = useState("");
  const MODAL_COMPONENT_MAPPING = {
    wellness_record: AddWellnessRecord,
    health_document: AddHealthDocument,
    add_immunisation_record: AddImmunisationRecord,
    op_consultation: AddOPconsultation,
    diagnostic_report: AddDiagnosticReport,
    discharge_summary: AddDischargeSummary,
  };

  const MODAL_COMPONENT_PROPS_MAPPING = {
    wellness_record: { getWellnessRecord },
  };

  const SubmitRecordComponent =
    MODAL_COMPONENT_MAPPING[activeHealthTab] || null;

  const submitRecordComponentProps =
    MODAL_COMPONENT_PROPS_MAPPING[activeHealthTab] || null;

  const {
    availableRecordData,
    isRecordLoading,
    onLinkRecord,
    isRecordLinking,
    getAvailableRecord,
  } = useGetAvailableRecord({ careContextsId });

  return (
    <div>
      <AvailableRecords
        setActiveHealthTab={setActiveHealthTab}
        availableRecordData={availableRecordData}
        isRecordLoading={isRecordLoading}
        onLinkRecord={onLinkRecord}
        isRecordLinking={isRecordLinking}
        getAvailableRecord={getAvailableRecord}
        careContextsId={careContextsId}
        setCareContextsId={setCareContextsId}
      />

      <RequestedRecord />

      {SubmitRecordComponent ? (
        <SubmitRecordComponent
          activeHealthTab={activeHealthTab}
          setActiveHealthTab={setActiveHealthTab}
          getAvailableRecord={getAvailableRecord}
          {...submitRecordComponentProps}
        />
      ) : null}
    </div>
  );
};

export default PatientHealthRecord;

import { useState } from "react";
import ListLoader from "../../../../common-components/ListLoader";
import RenderTooltipComponent from "../../../../common-components/RenderTooltipComponent";
import { startCase } from "../../../../utils/startCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../../common-components/Pagination";

const STATUS_COLOR_MAPPING = {
  Granted: "bg-[#DEF7EC] text-[#03543F]",
  COMPLETED: "bg-[#EDEBFE] text-[#5521B5]",
  CANCELLED: "bg-#FDE8E8 text-[#9B1C1C]",
  requested: "bg-[#FDF6B2] text-[#723B13]",
};

const InvoiceList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const invoiceData = [{}];
  const isInvoiceLoading = false;

  return (
    <div className="text-sm">
      <div className="flex w-full uppercase bg-[#F9FAFB] py-3 ps-4 text-sm font-medium text-[#6B7280]">
        <div className="w-[15%]">Invoice ID</div>
        <div className="w-[15%]">Billing date</div>
        <div className="w-[15%]">Pending</div>
        <div className="w-[15%]">Total amount</div>
        <div className="w-[20%]">Description</div>
        <div className="w-[10%]">Status</div>
        <div className="w-[10%]">Actions</div>
      </div>
      {isInvoiceLoading ? (
        <ListLoader />
      ) : (
        <>
          {invoiceData.length > 0 ? (
            invoiceData.map((item, index) => {
              const { purposeOfRequest, reportType, description, status } =
                item || {};
              return (
                <div
                  key={index}
                  className="w-full border-b  py-3 ps-5  text-sm text-gray-700"
                >
                  <div className="flex items-center w-full">
                    <div className="w-[15%] overflow-x-scroll">
                      {purposeOfRequest}
                    </div>
                    <div className="w-[15%] overflow-x-scroll">
                      {reportType}
                    </div>
                    <div className="w-[15%] overflow-x-scroll">
                      <FontAwesomeIcon icon={faIndianRupeeSign} />
                      <span className="ms-1"> 10,0000</span>
                    </div>
                    <div className="w-[15%] overflow-x-scroll">
                      <FontAwesomeIcon icon={faIndianRupeeSign} />
                      <span className="ms-1"> 10,0000</span>
                    </div>
                    <div className="w-[20%] overflow-x-scroll">
                      <RenderTooltipComponent
                        content={description}
                        maxLength={36}
                        maxWidth={500}
                      />
                    </div>
                    <div className="w-[10%] overflow-x-scroll">
                      <span
                        className={`${STATUS_COLOR_MAPPING[status]} px-2 py-1 rounded-lg`}
                      >
                        {startCase(status)}
                      </span>
                    </div>
                    <div className="w-[10%]">
                      {/* <button
                        disabled={status !== "Granted"}
                        className="bg-[#1A56DB] cursor-pointer disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] gap-2 flex items-center text-white px-2 py-1 rounded-md"
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        View Details
                      </button> */}
                      <button
                        // disabled={status !== "Granted"}
                        className="bg-[#1A56DB] cursor-pointer disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] gap-2 flex items-center text-white px-2 py-1 rounded-md"
                      >
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center p-2 text-red-600">
              Oops no results found !
            </div>
          )}
        </>
      )}

      <div className="py-3 px-3">
        <Pagination
          totalRecords={1000}
          countsPerPage={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default InvoiceList;

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getFormatedData = async (availableRecords = []) => {
  const finalResult = [];
  availableRecords.forEach((item) => {
    const { careContexts } = item || {};
    let formatedCareContexts = {};
    for (let care of careContexts) {
      if (formatedCareContexts[care.type] !== undefined) {
        formatedCareContexts[care.type].push(care);
      } else {
        formatedCareContexts[care.type] = [care];
      }
    }
    const updatedItem = {
      ...item,
      careContexts: formatedCareContexts,
      noOfDocument: careContexts.length,
    };
    finalResult.push(updatedItem);
  });
  return finalResult;
};

const useGetAvailableRecord = ({ careContextsId = [] }) => {
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, abhaId } = patientData || {};

  const [availableRecordData, setAvailableRecordData] = useState([]);
  const [isRecordLoading, setIsRecordLoading] = useState(false);
  const [isRecordLinking, setIsRecordLinking] = useState(false);

  const getAvailableRecord = useCallback(async () => {
    setIsRecordLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/record/getAll/${mrNo}`, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      const result = await getFormatedData(res.data);
      setAvailableRecordData(result);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsRecordLoading(false);
  }, [mrNo]);

  const onLinkRecord = async () => {
    setIsRecordLinking(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/m2/linkCareContext/link`,
        {
          abhaNumber: "",
          abhaAddress: abhaId,
          careContextsId: careContextsId,
          mrNo: mrNo,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );
      getAvailableRecord();
      console.log(res, "res");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsRecordLinking(false);
  };

  useEffect(() => {
    getAvailableRecord();
  }, [mrNo, getAvailableRecord]);

  return {
    availableRecordData,
    isRecordLoading,
    onLinkRecord,
    isRecordLinking,
    getAvailableRecord,
  };
};

export default useGetAvailableRecord;

import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useAddDischargeSummary = ({
  dischargeSummaryInfo = {},
  getAvailableRecord = () => {},
  setActiveHealthTab = () => {},
}) => {
  const [isAddingDischarge, setIsAddingDischarge] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint: chief } = patientData || {};

  const {
    dateOfAdmission,
    timeOfAdmission,
    dateOfDischarge,
    timeOfDischarge,
    chiefComplaint,
    diagnosis,
    proceduresPerformed,
    medication,
    statusWhileDischarge,
    followUpDate,
    adviceOnDischarge,
  } = dischargeSummaryInfo || {};

  const onDischargeSummary = async () => {
    setIsAddingDischarge(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/discharge-summaries`,
        {
          mrNo: mrNo,
          dateOfAdmission: dateOfAdmission,
          timeOfAdmission: timeOfAdmission,
          dateOfDischarge: dateOfDischarge,
          timeOfDischarge: timeOfDischarge,
          chiefComplaint: chiefComplaint,
          diagnosis: diagnosis,
          proceduresPerformed: proceduresPerformed,
          medication: medication,
          statusWhileDischarge: statusWhileDischarge,
          followUpDate: followUpDate,
          adviceOnDischarge: adviceOnDischarge,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: res.data.id,
              hiType: "DischargeSummary",
              display: "Discharge summary",
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chief,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      toast.success("Discharge summary is submitted successfully !");
      setTimeout(() => {
        getAvailableRecord();
        setActiveHealthTab("");
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsAddingDischarge(false);
  };

  return { onDischargeSummary, isAddingDischarge };
};

export default useAddDischargeSummary;

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGetPatientDetails = () => {
  const [patientData, setPatientData] = useState({});
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(true);
  const stateData = useLocation();
  const { state } = stateData || {};
  const { visitId } = state || {};

  const getPatientDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/shizune/visit`, {
        params: {
          id: visitId,
        },
        headers: {
          Authorization: token,
        },
      });
      setPatientData(res.data);
      setIsPatientDataLoading(false);
    } catch (error) {
      // toast.error("Oops somthing went error", error);
      console.error(error, "error");
      setIsPatientDataLoading(false);
    }
  }, [visitId]);

  useEffect(() => {
    getPatientDetails();
  }, [visitId, getPatientDetails]);

  return {
    patientData,
    isPatientDataLoading,
  };
};

export default useGetPatientDetails;

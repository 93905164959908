import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useCreateConsentRequest = ({
  consentInfo,
  setConsentModal = () => {},
  getConsentData,
}) => {
  const [isConsentCreating, setIsConsnetCreating] = useState(false);

  const onCreateConsent = async () => {
    setIsConsnetCreating(true);
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        `/v2/danzo/m3/consent`,
        {
          hiTypes: consentInfo.healthInfoType,
          form: new Date(consentInfo.recordsFrom),
          // to: new Date(consentInfo.recordsTo),
          to: `${consentInfo.recordsTo}T${moment(new Date()).format(
            "hh:mm:ss"
          )}`,
          purpose: consentInfo.purposeOfRequest,
          accessMode: "VIEW",
          abhaAddress: consentInfo.abhaAddress,
          dataEraseAt: new Date(consentInfo.expiryDate),
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );
      toast.success(`Consent Requested Successfully`);
      setTimeout(() => {
        getConsentData();
        setConsentModal(false);
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsConsnetCreating(false);
  };

  return { onCreateConsent, isConsentCreating };
};

export default useCreateConsentRequest;

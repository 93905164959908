import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import file from "../../../../../../images/file.svg";
import React, { useState } from "react";
import useGetSelectedPrescriptionRecord from "../hooks/useGetSelectedPrescriptionRecord";
import SelectedPrescription from "./SelectedPrescription";

const PrescriptionRecord = ({
  Prescription = [],
  handleCheckBoxChange = () => {},
  selectedItem,
  setSelectedItem,
}) => {
  const [isSelectedRecord, setIsSelectedRecord] = useState(false);
  const {
    selectedPrescriptionData,
    getSelectedPrescription,
    isPrescriptionLoading,
  } = useGetSelectedPrescriptionRecord({ setIsSelectedRecord });
  const [slectedName, setSelectedName] = useState(null);

  return (
    <div className="ms-10">
      <div className="flex items-center mt-3 ">
        <div className="w-[95%] items-center gap-3 flex">
          <button
            onClick={() =>
              setSelectedItem((prev) => ({
                ...prev,
                isPrescriptionOpen: !prev.isPrescriptionOpen,
              }))
            }
          >
            <FontAwesomeIcon
              icon={selectedItem.isPrescriptionOpen ? faAngleUp : faAngleDown}
            />
          </button>
          <div>
            <input type="checkbox" />
          </div>
          <div>Prescription</div>
        </div>
        <div>{Prescription.length}</div>
      </div>
      {selectedItem.isPrescriptionOpen ? (
        <div>
          {Prescription?.map((pres, index) => {
            const { id, linked, display, fhirId } = pres || {};
            return (
              <div className="p-4 flex items-center border-b" key={index}>
                <div className="text-[#4C6AF7] w-[78.5%] items-center flex ms-6 gap-3 border-[#D8E3FF]">
                  <div>
                    {!linked && (
                      <input
                        type="checkbox"
                        onChange={() => handleCheckBoxChange(id)}
                      />
                    )}
                  </div>
                  <div className="mb-1">
                    <img src={file} alt="file" />
                  </div>
                  <button
                    onClick={() => {
                      getSelectedPrescription(fhirId);
                      setSelectedName(id);
                    }}
                    disabled={isPrescriptionLoading}
                  >
                    {id} ({display})
                  </button>
                </div>
                <div>{linked === true ? "Linked" : "Un Linked"}</div>
              </div>
            );
          })}
        </div>
      ) : null}
      {isSelectedRecord ? (
        <SelectedPrescription
          isSelectedRecord={isSelectedRecord}
          setIsSelectedRecord={setIsSelectedRecord}
          selectedPrescriptionData={selectedPrescriptionData}
          slectedName={slectedName}
        />
      ) : null}
    </div>
  );
};

export default PrescriptionRecord;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useUpdateDoctorData = () => {
  const [isAddQualification, setIsAddQualification] = useState(false);
  const [isDeleteQualification, setIsDeleteQualification] = useState(false);
  const [isAddExperiance, setIsAddExperiance] = useState(false);
  const [isDeleteExperiance, setIsDeleteExperiance] = useState(false);
  const [isAddOffice, setIsAddOffice] = useState(false);
  const [isDeleteOffice, setIsDeleteOffice] = useState(false);
  const [isAddAward, setIsAddAward] = useState(false);
  const [isDeleteAward, setIsDeleteAward] = useState(false);

  const token = localStorage.getItem("authToken");

  const onAddQualifaication = async (addEducation) => {
    setIsAddQualification(true);
    try {
      await axios.put(`/v2/doctor/addQualification`, addEducation, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
      console.error(error, "error");
    }
    setIsAddQualification(false);
  };

  const onDeleteQualification = async (id) => {
    console.log(id, "id");
    setIsDeleteQualification(true);
    try {
      await axios.delete(
        `/v2/doctor/deleteQualification`,
        {
          params: {
            id: id,
          },
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
      console.error(error, "error");
    }
    setIsDeleteQualification(false);
  };

  const onAddExperiance = async (addExperiance) => {
    setIsAddExperiance(true);
    try {
      await axios.put(`/v2/doctor/addExperiance`, addExperiance, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
      console.log(error, "error");
    }
    setIsAddExperiance(false);
  };

  const onDeleteExperiance = async (id) => {
    setIsDeleteExperiance(true);
    try {
      await axios.delete(
        `/v2/doctor/deleteExperiance`,
        { params: { id: id } },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops! somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
      console.log(error, "error");
    }
    setIsDeleteExperiance(false);
  };

  const onAddOffice = async () => {
    setIsAddOffice(true);
    try {
      axios.put(`/v2/doctor/addOffice`, {
        headers: { Authorization: token },
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsAddOffice(false);
  };

  const onDeleteOffice = async (id) => {
    setIsDeleteOffice(true);
    try {
      axios.delete(
        `/v2/doctor/deleteOffice`,
        { id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsDeleteOffice(false);
  };

  const onAddAward = async () => {
    setIsAddAward(true);
    try {
      axios.put(`/v2/doctor/addAward`, {
        headers: { Authorization: token },
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsAddAward(false);
  };

  const onDeleteAward = async (id) => {
    setIsDeleteAward(true);
    try {
      axios.delete(
        `/v2/doctor/deleteOffice`,
        { id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Oops somthing went wrong !",
        {
          className: "w-max",
          position: "top-center",
        }
      );
    }
    setIsDeleteAward(false);
  };

  return {
    isDeleteQualification,
    isAddQualification,
    onAddQualifaication,
    onDeleteQualification,
    isAddExperiance,
    onAddExperiance,
    isDeleteExperiance,
    onDeleteExperiance,
    onAddOffice,
    isAddOffice,
    onDeleteOffice,
    isDeleteOffice,
    onAddAward,
    isAddAward,
    onDeleteAward,
    isDeleteAward,
  };
};

export default useUpdateDoctorData;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import useGetAllVisit from "../../visit-list/hooks/useGetAllVisit";
import "react-toastify/dist/ReactToastify.css";

const useCheckIn = ({
  appointmentData,
  setAppointmentData = () => {},
  setIsOnClear,
}) => {
  const [isCheckInLoading, setIsCheckInLoading] = useState(false);

  const { getAllPatientVisit } = useGetAllVisit();
  const onCheckIn = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCheckInLoading(true);

    try {
      const token = localStorage.getItem("authToken");
      await axios.post(`/v2/shizune/visit`, appointmentData, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      getAllPatientVisit();
      setAppointmentData({
        timeSlot: "",
        mrNo: "",
        serviceType: "",
        tags: [],
        chiefComplaint: "",
        visitType: "INPERSON",
        department: "GENERAL",
        doctorId: null,
        appointmentDetails: null,
      });
      setIsOnClear(true);
      toast.success("Patient checked in successfully", {
        position: "top-center",
      });
    } catch (error) {
      toast.error("Oops somthing went wrong !" || error, {
        position: "top-center",
      });
      console.error(error, "error");
    }
    setIsCheckInLoading(false);
  };

  return {
    isCheckInLoading,
    onCheckIn,
  };
};

export default useCheckIn;

import React, { useState } from "react";
import Modal from "../../../common-components/Modal";
import { useDispatch } from "react-redux";
import { setPatientRecords } from "../../../../redux-store/slice/patientRecordSlice";

const RequestConsentModal = ({ requestConsent, setRequestConsent }) => {
  const dispatch = useDispatch();
  const [consentData, setConsentData] = useState({
    purposeOfRequest: "",
    reportType: "",
    description: "",
    startDate: "",
    endDate: "",
    status: "requested",
  });
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    setConsentData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setPatientRecords(consentData));
    setConsentData({
      purposeOfRequest: "",
      reportType: "",
      description: "",
      startDate: "",
      endDate: "",
    });
    console.log(consentData, "consent data");
  };

  return (
    <div>
      <Modal
        showModal={requestConsent}
        onClose={() => setRequestConsent(false)}
        modalHeight="min-h-[50%]"
        modalWidth="w-[58.3%]"
      >
        <div>
          <div className="text-[#111928] px-5 font-medium border-b py-3">
            Consent Form for Medical Records
          </div>
          <div className="text-sm px-5 py-3">
            <div className="flex w-full justify-between gap-4">
              <div className="w-1/2">
                <div className="text-[#111928]">Purpose of Request*</div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="purposeOfRequest"
                    onChange={handleChange}
                    value={consentData.purposeOfRequest}
                    placeholder="Purchase of request ..."
                    className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-[#111928]">Report Type*</div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="reportType"
                    onChange={handleChange}
                    value={consentData.reportType}
                    placeholder="Type of report ..."
                    className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                  />
                </div>
              </div>
            </div>
            <div className="w-full py-2">
              <div className="text-[#111928]">Description</div>
              <div className="mt-1">
                <textarea
                  rows={5}
                  name="description"
                  value={consentData.description}
                  onChange={handleChange}
                  className="focus:outline-none rounded-lg px-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
            <div className="flex w-full justify-between gap-4">
              <div className="w-1/2">
                <div className="text-[#111928]">Start Date*</div>
                <div className="mt-1">
                  <input
                    type="datetime-local"
                    name="startDate"
                    onChange={handleChange}
                    value={consentData.startDate}
                    placeholder="Purchase of request ..."
                    className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-[#111928]">End Date*</div>
                <div className="mt-1">
                  <input
                    type="datetime-local"
                    name="endDate"
                    onChange={handleChange}
                    value={consentData.endDate}
                    placeholder="Type of report ..."
                    className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex text-sm justify-between mx-5 pb-5 pt-3">
            <button
              onClick={() => setRequestConsent(false)}
              className="border active:border-[#1C64F2] py-2 px-4 rounded-lg border-[#E7F4FF]"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="bg-[#1C64F2] px-4 py-2 text-white rounded-lg"
            >
              Send Request
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequestConsentModal;

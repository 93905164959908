import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import Qualifications from "./professional-details/Qualifications";
import AddQualificationModal from "./professional-details/AddQualificationModal";
import Experience from "./professional-details/Experience";
import AddExperienceModal from "./professional-details/AddExperienceModal";
import AddSpeciality from "./professional-details/AddSpeciality";
import Specialities from "./professional-details/Specialities";
import Awards from "./professional-details/Awards";
import AddAwardsModal from "./AddAwardsModal";

const ProfessionalDetails = () => {
  const [isAddEducation, setIsAddEducation] = useState(false);
  const [isAddExperience, setIsAddExperience] = useState(false);
  const [isAddSpeciality, setIsAddSpeciality] = useState(false);
  const [isAddAward, setIsAddAward] = useState(false);

  return (
    <div>
      <div
        className="m-8 px-8 py-6 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] font-medium flex justify-between">
          <div>Education</div>
          <div className="flex">
            <button
              onClick={() => setIsAddEducation(true)}
              className="flex border gap-2 items-center justify-center bg-[#1C64F2] text-white item-center px-3 py-1.5 active:animate-bounce rounded-lg text-sm"
            >
              <FontAwesomeIcon icon={faPlus} /> Add
            </button>
          </div>
        </div>
        <Qualifications />
      </div>

      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 flex justify-between font-medium">
          Experience
          <button
            onClick={() => setIsAddExperience(true)}
            className="flex border gap-2 items-center justify-center bg-white border-[#E5E7EB] item-center px-3 py-1.5 active:bg-[#1C64F2] rounded-lg text-sm text-[#1F2A37]"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <Experience />
      </div>
      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 flex justify-between font-medium">
          Specialties
          <button
            onClick={() => setIsAddSpeciality(true)}
            className="flex border gap-2 items-center justify-center bg-white border-[#E5E7EB] item-center px-3 py-1.5 active:bg-[#1C64F2] rounded-lg text-sm text-[#1F2A37]"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <Specialities />
      </div>

      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] flex justify-between mb-3 font-medium">
          Awards and Recognition
          <button
            onClick={() => setIsAddAward(true)}
            className="flex border gap-2 items-center justify-center bg-white border-[#E5E7EB] item-center px-3 py-1.5 active:bg-[#1C64F2] rounded-lg text-sm text-[#1F2A37]"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <Awards />
      </div>
      {isAddEducation && (
        <AddQualificationModal
          isAddEducation={isAddEducation}
          setIsAddEducation={setIsAddEducation}
        />
      )}
      {isAddExperience && (
        <AddExperienceModal
          isAddExperience={isAddExperience}
          setIsAddExperience={setIsAddExperience}
        />
      )}
      {isAddSpeciality && (
        <AddSpeciality
          isAddSpeciality={isAddSpeciality}
          setIsAddSpeciality={setIsAddSpeciality}
        />
      )}
      {isAddAward && (
        <AddAwardsModal isAddAward={isAddAward} setIsAddAward={setIsAddAward} />
      )}
    </div>
  );
};

export default memo(ProfessionalDetails);

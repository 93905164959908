import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useGetAllDrug = () => {
  const [drugList, setDrugList] = useState([]);
  const [isDrugListLoading, setIsDrugListLoading] = useState(false);

  const prescriptionData = useSelector((state) => state.prescriptionData);
  const { diagnosis = [] } = prescriptionData || {};

  const getAllDrugList = useCallback(
    async (inputValue = "") => {
      setIsDrugListLoading(true);
      const token = localStorage.getItem("authToken");
      try {
        // const resp = await axios.get(`/v2/scroll/drug/search`, {
        const resp = await axios.get(
          `/v2/cortex/medications`,
          {
            params: {
              query: inputValue,
              diagnosis: diagnosis[0],
            },
            headers: {
              Authorization: token,

              // `Bearer ${token}`,
              "ngrok-skip-browser-warning": "hgs",
            },
          }
        );
        setDrugList(resp.data?.medications);
      } catch (error) {
        console.log(error, "error");
        toast.error(error?.response?.data?.message);
      }
      setIsDrugListLoading(false);
    },
    [diagnosis]
  );

  useEffect(() => {
    getAllDrugList();
  }, [getAllDrugList]);

  return {
    isDrugListLoading,
    drugList,
    getAllDrugList,
  };
};
export default useGetAllDrug;

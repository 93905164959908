import React, { useEffect } from "react";
import useSavePrescription from "../create-prescription/hooks/useSavePrescription";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../utils/formatDateTime";

const CurrentMedications = () => {
  const { onSavePrescription, isPrescriptionSaving, pastPrescription } =
    useSavePrescription({ method: "get" });

  const patientData = useSelector((state) => state.patientDetails);

  const { mrNo } = patientData || {};

  useEffect(() => {
    onSavePrescription(mrNo);
  }, [onSavePrescription, mrNo]);

  return (
    <div className=" text-[#374151] text-sm">
      {isPrescriptionSaving ? (
        [1, 2, 3, 4, 5].map((_, index) => <Skeleton key={index} />)
      ) : (
        <>
          {pastPrescription.length === 0 ? (
            <div className="p-2 font-light">No Data Found !</div>
          ) : (
            pastPrescription.map((prescription, index) => {
              const { rxList, dateCreated, id } = prescription || {};
              if (index !== 0) {
                return null;
              }

              return (
                <div key={id} className="border-b last:border-b-0 py-2">
                  <div className="flex justify-end">
                    {formatDateTime(dateCreated)}
                  </div>
                  {rxList.map((medicine, index) => {
                    const {
                      drugName,
                      duration,
                      frequency,
                      measurement,
                      dose,
                      timing,
                    } = medicine || {};
                    return (
                      <div className="flex w-full" key={index}>
                        <div className="flex items-center gap-4">
                          <div>
                            {drugName} ({dose}
                            <span className="">{measurement})</span>
                          </div>
                          <div className="mt-1 font-light">
                            {frequency} | {timing} | {duration}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
};

export default CurrentMedications;

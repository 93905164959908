import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useGetWellnessRecord = () => {
  const [wellnessData, setWellnessData] = useState([]);
  const [isWellDataLoading, setIsWellnessDataLoading] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);

  const { mrNo } = patientData || {};
  const getWellnessRecord = useCallback(async () => {
    setIsWellnessDataLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/wellness-records`, {
        params: {
          mrNo: mrNo,
        },
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      const newData = await res.data?.reverse();
      setWellnessData(newData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsWellnessDataLoading(false);
  }, [mrNo]);

  useEffect(() => {
    getWellnessRecord();
  }, [mrNo, getWellnessRecord]);

  return {
    wellnessData,
    isWellDataLoading,
    getWellnessRecord,
  };
};

export default useGetWellnessRecord;

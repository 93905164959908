import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPrescriptionData } from "../../../../../redux-store/slice/prescriptionDataSlice";

const useGetMedicalHandoubts = () => {
  const [medicalHandoubts, setMedicalHandoubts] = useState([]);
  const [isMedicalHandobts, setIsMedicalHandoubts] = useState(false);
  const [diagnosisTag, setDiagnosisTag] = useState([]);
  const [isDiagnosisTagLaoding, setIsDiagnosisLoading] = useState(false);
  const dispatch = useDispatch();

  const prescriptionData = useSelector((state) => state.prescriptionData);

  const { diagnosis = [], medicalTags = [] } = prescriptionData || {};

  const getMedicalHandoubts = useCallback(async () => {
    const value =
      medicalTags[0] === undefined ? diagnosisTag[0] : medicalTags[0];
    if (value === undefined) {
      return;
    }

    setIsMedicalHandoubts(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/getHandouts/${value}`,
        {
          // params: { query: inputValue },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMedicalHandoubts(res.data);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
    setIsMedicalHandoubts(false);
  }, [diagnosisTag, medicalTags]);

  const handleRemoveOption = (optionToRemove) => {
    const updatedHandouts = medicalHandoubts.filter(
      (option) => option.id !== optionToRemove.id
    );
    setMedicalHandoubts(updatedHandouts);
  };

  const getMedicalTags = useCallback(
    async (inputValue) => {
      const value = inputValue !== undefined ? inputValue : diagnosis[0];
      if (value === undefined || value === null || value === "") {
        return;
      }
      setIsDiagnosisLoading(true);

      try {
        const token = localStorage.getItem("authToken");
        const res = await axios.get(
          `https://v2.dev-api.parchaa.com/api/user/handouts/searchTags/${value}`,
          {
            // params: { query: inputValue },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDiagnosisTag(res.data);
      } catch (error) {
        // toast.error(error?.response?.data?.message);
      }
      setIsDiagnosisLoading(false);
    },
    [diagnosis]
  );

  const getHandoutsById = useCallback(async (id) => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/getVeiwUrl/${id}`,
        {
          // params: { query: inputValue },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      window.open(res.data, "_blank");
      // setDiagnosisTag(res.data);
    } catch (error) {
      console.log(error, "error");
      // toast.error(error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    getMedicalHandoubts();
  }, [getMedicalHandoubts, medicalTags, diagnosis]);

  useEffect(() => {
    getMedicalTags();
  }, [getMedicalTags, diagnosis]);

  useEffect(() => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        medicalTags: [],
      })
    );
    // eslint-disable-next-line
  }, [diagnosis]);

  const addHandoubtsToPrint = (item) => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        medicalHandoubts: [item, ...prescriptionData.medicalHandoubts],
      })
    );
  };

  const removeHandobutsToPrint = (item) => {
    const updatedHandouts = prescriptionData.medicalHandoubts.filter(
      (option) => option.id !== item.id
    );
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        medicalHandoubts: updatedHandouts,
      })
    );
  };

  const onAddStampToPrint = () => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        stamp: !prescriptionData.stamp,
      })
    );
  };

  const onAddSignatureToPrint = () => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        signature: !prescriptionData.signature,
      })
    );
  };

  return {
    getMedicalHandoubts,
    medicalHandoubts,
    isMedicalHandobts,
    getMedicalTags,
    diagnosisTag,
    isDiagnosisTagLaoding,
    getHandoutsById,
    handleRemoveOption,
    addHandoubtsToPrint,
    removeHandobutsToPrint,
    onAddSignatureToPrint,
    onAddStampToPrint,
  };
};

export default useGetMedicalHandoubts;

import React, { useContext, useState } from "react";
import { Context } from "../DoctorAccount";
import degreeIcon from "../../../../../../images/degree.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DeleteQualificationModal from "./DeleteQualificationModal";

const Qualifications = () => {
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};
  const { professionalDeatils } = doctorData || {};
  const { qualifications = [] } = professionalDeatils || {};
  const [isDeleteEducation, setIsDeleteEducation] = useState(false);

  return (
    <div>
      {qualifications.map((item) => {
        const { id, degree, institutionName, yearOfCompletion } = item || {};
        return (
          <div
            key={id}
            className="flex last:border-none py-4 border-b items-center w-full"
          >
            <div className="flex w-2/3 gap-5 items-center">
              <img src={degreeIcon} alt="stethoscope" />
              <div className="">
                <div className="text-[#111928] text-sm font-medium">
                  {degree}
                </div>
                <div className="text-[#6B7280] text-sm font-light">
                  {institutionName}
                </div>
              </div>
            </div>
            <div className="w-1/3 flex items-center text-sm text-[#6B7280]">
              {yearOfCompletion}
            </div>
            <div className="flex gap-4">
              <button className="flex items-center active:animate-bounce justify-center bg-white item-center px-2 py-2 rounded-lg text-sm text-[#1F2A37]">
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button
                className="px-2.5 py-1.5 active:animate-bounce rounded-lg"
                onClick={() => setIsDeleteEducation(true)}
              >
                <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
              </button>
            </div>
            {isDeleteEducation && (
              <DeleteQualificationModal
                onClose={() => setIsDeleteEducation(false)}
                showModal={isDeleteEducation}
                id={id}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Qualifications;

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import file from "../../../../../../images/file.svg";
import SelectedOPconsultation from "./SelectedOPconsultation";
import useGetSelectedOPconsultation from "../hooks/useGetSelectedOPconsultation";

const OPconsultation = ({
  OPConsultation = [],
  handleCheckBoxChange = () => {},
  selectedItem = {},
  setSelectedItem = () => {},
}) => {
  const [isSelectedRecord, setIsSelectedRecord] = useState(false);
  const {
    opConsultation,
    isOPselectedOPconsultationLoading,
    getOPconsulation,
  } = useGetSelectedOPconsultation({ setIsSelectedRecord });

  const [selectedName, setSelectedName] = useState(null);

  return (
    <div className="ms-10">
      <div className="flex items-center mt-3 ">
        <div className="flex items-center gap-3 w-[95%]">
          <button
            onClick={() =>
              setSelectedItem((prev) => ({
                ...prev,
                isOPConsultation: !prev.isOPConsultation,
              }))
            }
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </button>
          <div>
            <input type="checkbox" onChange={handleCheckBoxChange} />
          </div>
          <div>OP Consultation</div>
        </div>
        <div className="">{OPConsultation.length}</div>
      </div>
      {selectedItem.isOPConsultation ? (
        <div>
          {OPConsultation?.map((opconsult, index) => {
            const { id, linked, display, fhirId } = opconsult || {};
            return (
              <div className="p-4 items-center flex border-b" key={index}>
                <div className="text-[#4C6AF7]  w-[78.5%] gap-3 items-center flex ms-6 border-[#D8E3FF]">
                  <div>
                    {!linked && (
                      <input
                        type="checkbox"
                        onChange={() => handleCheckBoxChange(id)}
                      />
                    )}
                  </div>
                  <div className="mb-1">
                    <img src={file} alt="file" />
                  </div>
                  <button
                    onClick={() => {
                      getOPconsulation(fhirId);
                      setSelectedName(id);
                    }}
                    disabled={isOPselectedOPconsultationLoading}
                  >
                    {id} ({display})
                  </button>
                </div>
                <div>{linked === true ? "Linked" : "Un Linked"}</div>
              </div>
            );
          })}
        </div>
      ) : null}
      {isSelectedRecord ? (
        <SelectedOPconsultation
          isSelectedRecord={isSelectedRecord}
          setIsSelectedRecord={setIsSelectedRecord}
          opConsultation={opConsultation}
          name={selectedName}
        />
      ) : null}
    </div>
  );
};

export default OPconsultation;

import React from "react";

const FamilyHistory = () => {
  return (
    <div className="w-full">
      <div className="p-2 font-light">No Data Found !</div>
      {/* <div className="flex w-full py-3 text-[#374151] text-sm">
        <div className="w-[50%]">
          <div>Mother</div>
        </div>
        <div className="w-[50%] flex justify-center">Leukemia</div>
      </div>
      <div className="flex w-full text-[#374151] text-sm">
        <div className="w-[50%]">
          <div>Father</div>
        </div>
        <div className="w-[50%] flex justify-center">Diabetes</div>
      </div> */}
    </div>
  );
};

export default FamilyHistory;

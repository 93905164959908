import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useGetLifeStyle = () => {
  const [lifestyleData, setLifeStyleData] = useState([]);
  const [isLifeStyleLoading, setIsLifeStyleLoading] = useState(false);
  const prescriptionData = useSelector((state) => state.prescriptionData);

  const { diagnosis = [] } = prescriptionData || {};

  const getLifeStyleData = useCallback(
    async (inputValue = "") => {
      setIsLifeStyleLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const res = await axios.get(
          `/v2/cortex/lifestyles`,
          {
            params: {
              diagnosis: diagnosis[0],
              query: inputValue,
            },
            headers: { Authorization: token },
          }
        );
        setLifeStyleData(res.data?.lifestyles);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
      setIsLifeStyleLoading(false);
    },
    [diagnosis]
  );

  useEffect(() => {
    getLifeStyleData();
  }, [getLifeStyleData, diagnosis]);

  return {
    getLifeStyleData,
    isLifeStyleLoading,
    lifestyleData,
  };
};

export default useGetLifeStyle;

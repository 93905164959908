import React from "react";
import SearchInput from "../../../common-components/SearchInput";
import RecordList from "./RecordList";

const AbhaRecords = () => {
  return (
    <div
      className="m-8  pt-4 rounded-md"
      style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
    >
      <div>
        <div className="w-[30%] px-4">
          <SearchInput />
        </div>
        <div className="pt-4">
          <RecordList />
        </div>
      </div>
    </div>
  );
};

export default AbhaRecords;

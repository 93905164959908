import React from "react";
import PrimaryButton from "../../../../common-components/Buttons/PrimaryButton";
import { ToastContainer } from "react-toastify";

const consentList = [
  {
    id: 1,
    purposeOfRequest: "Care Management",
    status: "Granted",
    creationDate: "25/05/2024",
    expiryDate: "13/02/2024",
    grantedDate: "24/12/2023",
  },
  {
    id: 2,
    purposeOfRequest: "Prescription",
    status: "Requested",
    creationDate: "25/05/2024",
    expiryDate: "13/02/2024",
  },
  {
    id: 3,
    purposeOfRequest: "Break the Glass",
    status: "Revoked",
    creationDate: "25/05/2024",
    expiryDate: "13/02/2024",
  },
  {
    id: 4,
    purposeOfRequest: "Healthcare Payment",
    status: "Denied",
    creationDate: "25/05/2024",
    expiryDate: "13/02/2024",
  },
];
const STATUS_COLOR_MAPPING = {
  Denied: "bg-[#FDE8E8] text-[#9B1C1C]",
  Requested: "bg-[#FDF6B2] text-[#723B13]",
  Granted: "bg-[#DEF7EC] text-[#03543F]",
  Revoked: "bg-[#FDE8E8] text-[#9B1C1C]",
};

const ConsentManager = ({ setConsentModal = () => {} }) => {
  return (
    <div
      className="bg-white p-4 text-[#2D2E33] rounded-lg text-sm overflow-x-scroll"
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <ToastContainer />
      <div className="flex justify-between px-1">
        <div className="font-medium">Consents List</div>
        <div>
          <PrimaryButton
            buttonName="New Consent Request"
            onClick={() => setConsentModal(true)}
          />
        </div>
      </div>
      <div className="bg-[#ECECED] font-medium mt-4 flex py-3 rounded-lg px-5">
        <div className="w-1/2">Purpose of Request</div>
        <div className="w-[20%]">Status</div>
        <div className="w-[20%]">Creation Date</div>
        <div className="w-[10%]">Expiry Date</div>
      </div>
      <div>
        {consentList.map((item, index) => {
          return (
            <div key={index} className="border-b items-center flex p-4">
              <div className="w-[46%]">{item.purposeOfRequest}</div>
              <div className="w-[24%]">
                <div
                  className={`${
                    STATUS_COLOR_MAPPING[item.status]
                  } w-8/12 flex justify-center py-2 px-2 rounded-lg`}
                >
                  {item.status}{" "}
                  {item.status === "Granted"
                    ? `(on ${item.grantedDate})`
                    : null}
                </div>
              </div>
              <div className="w-[20%]">{item.creationDate}</div>
              <div className="w-[10%]">{item.expiryDate}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConsentManager;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useGetConsentStatus = ({ getConsentData }) => {
  const [isConsentStatus, setIsConsentStatus] = useState(false);
  const [isDocumentFetching, setIsDocumentFetching] = useState(false);

  const onConsentStatus = async (consentId) => {
    setIsConsentStatus(true);
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(`/v2/danzo/m3/consent/status/${consentId}`, undefined, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      getConsentData();
      toast.success(`Fetched Successfully`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsConsentStatus(false);
  };

  const onFetchDocuments = async (artifactId) => {
    setIsDocumentFetching(true);
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(`/v2/danzo/m3/consent/fetch/${artifactId}`, undefined, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      getConsentData();
      toast.success(`Fetched Successfully`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsDocumentFetching(false);
  };

  return {
    isConsentStatus,
    onConsentStatus,
    onFetchDocuments,
    isDocumentFetching,
  };
};

export default useGetConsentStatus;

import React from "react";
import ChiefComplaints from "../../../../images/complaint-round-svgrepo-com.svg";
import Pin from "../../../../images/Pin.svg";
import Pill from "../../../../images/pill.svg";
import ExpandArrow from "../../../../images/arrow-up-right-down-left.svg";
import History from "../../../../images/History.svg";
import Clock from "../../../../images/clock.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VitalViolet from "../../../../images/Vitalviolet.svg";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import PresentIllnesHistory from "../patient-details-in-modal/PresentIllnesHistory";
import DifferentialDiagnisis from "../patient-details-in-modal/DifferentialDiagnisis";
import Allergies from "../patient-details-in-modal/Allergies";
import PastMedications from "../patient-details-in-modal/PastMedications";
import PreventiveCare from "../patient-details-in-modal/PreventiveCare";
import PastVitals from "../patient-details-in-modal/PastVitals";
import CurrentMedications from "../patient-details-in-modal/CurrentMedications";
import MedicalHistory from "../patient-details-in-modal/MedicalHistory";
import PastSurgeries from "../patient-details-in-modal/PastSurgeries";
import FamilyHistory from "../patient-details-in-modal/FamilyHistory";
import SocialHistory from "../patient-details-in-modal/SocialHistory";
import PastVisitSummary from "../patient-details-in-modal/PastVisitSummary";
import Immunization from "../patient-details-in-modal/Immunization";
import { useSelector } from "react-redux";
import { startCase } from "../../../utils/startCase";
import HPIquestionare from "../patient-details-in-modal/HPIquestionare";

const PatientMedicalDetails = ({ setShowExpandModal }) => {
  const patientData = useSelector((state) => state.patientDetails);
  const { chiefComplaint } = patientData || {};
  return (
    <div className="w-full gap-6 flex">
      <div className="w-[50%]">
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 mt-4 rounded-lg"
        >
          <div className="flex justify-between items-center">
            <div className="bg-[#FDF6B2] gap-2 w-fit rounded-lg px-2 py-1 text-[#723B13] flex items-center text-sm">
              <img src={ChiefComplaints} alt="ChiefComplaints" />
              Chief Complaint
            </div>
            <div className="bg-[#FDF6B2] flex justify-center rounded-full p-2">
              <img src={Pin} alt="pin" />
            </div>
          </div>
          <div className="mt-3">
            <span className="text-[#E02424] font-semibold">
              {startCase(chiefComplaint)}
            </span>
          </div>
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white mt-6 p-4 rounded-lg"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <img src={History} alt="pill" />
              <span>History of Present Illness</span>
            </div>
            <button onClick={() => setShowExpandModal("hopi")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PresentIllnesHistory setShowExpandModal={setShowExpandModal} />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#723B13] bg-[#FDF6B2]">
              <img src={Clock} alt="clock" />
              <span>Differential Diagnosis</span>
            </div>
            <button
              onClick={() => setShowExpandModal("differential_diagnosis")}
            >
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <DifferentialDiagnisis />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#9B1C1C] bg-[#FDE8E8]">
              <img src={Clock} alt="clock" />
              <span>Allergies</span>
            </div>
            <button onClick={() => setShowExpandModal("allergies")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <Allergies />
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5 max-h-56 overflow-y-scroll"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <FontAwesomeIcon icon={faClock} color="#5521B5" />
              <span>Past Medication</span>
            </div>
            <button onClick={() => setShowExpandModal("past_medications")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PastMedications />
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#03543F] bg-[#DEF7EC]">
              <FontAwesomeIcon icon={faClock} color="#03543F" />
              <span>Preventive Care</span>
            </div>
            <button onClick={() => setShowExpandModal("preventive_care")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PreventiveCare />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <img src={VitalViolet} alt="VitalViolet" />
              <span>Past Vitals</span>
            </div>
            <button onClick={() => setShowExpandModal("past_vitals")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PastVitals />
        </div>
      </div>
      <div className="w-[50%]">
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white max-h-96 overflow-y-scroll mt-4 mb-5 p-4 rounded-lg"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <img src={History} alt="pill" />
              <span>History of Present Illness Questionnaire</span>
            </div>
            <button onClick={() => setShowExpandModal("paragraph")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <HPIquestionare setShowExpandModal={setShowExpandModal} />
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg h-[15%] text-wrap overflow-hidden"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#03543F] bg-[#DEF7EC]">
              <img src={Pill} alt="pill" />
              <span>Current Medications</span>
            </div>
            <button onClick={() => setShowExpandModal("current_medications")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <CurrentMedications />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#9B1C1C] bg-[#FDE8E8]">
              <FontAwesomeIcon icon={faClock} color="#9B1C1C" />
              <span>Medical History</span>
            </div>
            <button onClick={() => setShowExpandModal("medical_history")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <MedicalHistory />
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#9B1C1C] bg-[#FDE8E8]">
              <FontAwesomeIcon icon={faClock} color="#9B1C1C" />
              <span>Past Surgeries</span>
            </div>
            <button onClick={() => setShowExpandModal("past_surgeries")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PastSurgeries />
        </div>
        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <FontAwesomeIcon icon={faClock} color="#5521B5" />
              <span>Family History</span>
            </div>
            <button onClick={() => setShowExpandModal("family_history")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <FamilyHistory />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#9B1C1C] bg-[#FDE8E8]">
              <FontAwesomeIcon icon={faClock} color="#9B1C1C" />
              <span>Social History</span>
            </div>
            <button onClick={() => setShowExpandModal("social_history")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <SocialHistory />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#5521B5] bg-[#EDEBFE]">
              <FontAwesomeIcon icon={faClock} color="#5521B5" />
              <span>Past Visit Summary</span>
            </div>
            <button onClick={() => setShowExpandModal("past_visit_summary")}>
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <PastVisitSummary />
        </div>

        <div
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}
          className="bg-white p-4 rounded-lg text-sm mt-5"
        >
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2 w-fit py-1 px-2 rounded-lg text-[#9B1C1C] bg-[#FDE8E8]">
              <FontAwesomeIcon icon={faClock} color="#9B1C1C" />
              <span>Immunization/Injections</span>
            </div>
            <button
              onClick={() => setShowExpandModal("immunization_injections")}
            >
              <img src={ExpandArrow} alt="ExpandArrow" />
            </button>
          </div>
          <Immunization />
        </div>
      </div>
    </div>
  );
};

export default PatientMedicalDetails;

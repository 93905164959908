import React, { memo, useContext } from "react";
import { filterListContext } from "./HIPdashboard";

const AppliedFilters = () => {
  const filterState = useContext(filterListContext);
  const { selectedFilters, handleRemoveFilter } = filterState || {};
  const {
    visitType = [],
    serviceType = [],
    visitStatus = [],
    tags = [],
    department = [],
  } = selectedFilters || {};
  return (
    <div className="flex ">
      {serviceType.map((item, index) => {
        return (
          <div
            key={item.value}
            className="px-3 flex-shrink-0 mx-2 gap-2 py-2 rounded-lg text-[#000000] bg-[#E5E7EB]"
          >
            {item.label}{" "}
            <button
              className="text-sm font-semibold ms-2"
              onClick={() => handleRemoveFilter("serviceType", index)}
            >
              &#10005;
            </button>
          </div>
        );
      })}
      {visitStatus.map((item, index) => {
        return (
          <div
            key={item.value}
            className="px-3 mx-2 flex-shrink-0 gap-2 py-2 rounded-lg text-[#000000] bg-[#E5E7EB]"
          >
            {item.label}{" "}
            <button
              className="text-sm font-semibold ms-2"
              onClick={() => handleRemoveFilter("visitStatus", index)}
            >
              &#10005;
            </button>
          </div>
        );
      })}
      {visitType.map((item, index) => {
        return (
          <div
            key={item.value}
            className="px-3 flex-shrink-0 mx-2 gap-2 py-2 rounded-lg text-[#000000] bg-[#E5E7EB]"
          >
            {item.label}{" "}
            <button
              onClick={() => handleRemoveFilter("visitType", index)}
              className="text-sm font-semibold ms-2"
            >
              &#10005;
            </button>
          </div>
        );
      })}
      {department.map((item, index) => {
        return (
          <div
            key={item.value}
            className="px-3 flex-shrink-0 mx-2 gap-2 py-2 rounded-lg text-[#000000] bg-[#E5E7EB]"
          >
            {item.label}{" "}
            <button
              onClick={() => handleRemoveFilter("department", index)}
              className="text-sm font-semibold ms-2"
            >
              &#10005;
            </button>
          </div>
        );
      })}
      {tags.map((item, index) => {
        return (
          <div
            key={item.value}
            className="px-3 flex-shrink-0 mx-2 gap-2 py-2 rounded-lg text-[#000000] bg-[#E5E7EB]"
          >
            {item.label}{" "}
            <button
              onClick={() => handleRemoveFilter("tags", index)}
              className="text-sm font-semibold ms-2"
            >
              &#10005;
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default memo(AppliedFilters);

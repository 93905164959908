import React from "react";
import Modal from "../../../../../common-components/Modal";
import backArrow from "../../../../../../images/back_arrow.svg";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";

const SelectedImmunisation = ({
  isSelectedRecord,
  setIsSelectedRecord,
  immunisationData,
  name,
}) => {
  const {
    dateOfVaccination,
    doseNumber,
    lotNumber,
    manufacturer,
    vaccineCode,
    route,
    dateCreated,
  } = immunisationData || {};

  return (
    <div>
      <Modal
        showModal={isSelectedRecord}
        onClose={() => setIsSelectedRecord(false)}
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
      >
        <div className="m-4">
          <div className="flex justify-between w-[60%]">
            <img src={backArrow} alt="backArrow" />
            <div>{name} (Immunisation Record))</div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Vaccination</div>

            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex justify-between flex-wrap mx-3">
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Date of Vaccination : </div>
              <div>{dateOfVaccination}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] "> Vaccine Code :</div>
              <div>{vaccineCode}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">LOT Number :</div>
              <div>{lotNumber}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Manufacturer :</div>
              <div>{manufacturer}</div>
            </div>
          </div>
          <div className="flex m-3 w-1/2 justify-between">
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Dose Number :</div>
              <div>{doseNumber}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Route :</div>
              <div>{route}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectedImmunisation;

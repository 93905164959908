import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useCreateRecord = ({
  recordInfo,
  getAvailableRecord,
  setIsCreateRecordModal,
}) => {
  const [isRecordCreating, setIsRecordCreating] = useState(false);
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint } = patientData || {};

  const onRecordCreate = async () => {
    setIsRecordCreating(true);
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: `parchaa${mrNo}`,
              hiType: recordInfo.hiTypes,
              display: recordInfo.displayName,
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chiefComplaint,
        },
        {
          headers: { Authorization: token },
        }
      );
      toast.success("Record Created Successfully !");
      setTimeout(() => {
        getAvailableRecord();
        setIsCreateRecordModal(false);
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsRecordCreating(false);
  };
  return {
    onRecordCreate,
    isRecordCreating,
  };
};

export default useCreateRecord;

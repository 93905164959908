import React, { memo, useRef } from "react";
// import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import ParchaaLogo from "../../../../images/parchaa.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { startCase } from "../../../utils/startCase";

import {
  formatDateTime,
  formatTime,
  formatTimeWithDay,
} from "../../../utils/formatDateTime";
import { firstLetterCapital } from "../../../utils/firstLetterCapital";
import moment from "moment";
import useSavePrescription from "./hooks/useSavePrescription";
import { ToastContainer } from "react-toastify";
import useGetAllHandoubt from "./hooks/useGetAllHandoubt";
import stampIcon from "../../../../images/stamp.svg";
import signatureIcon from "../../../../images/doctor_signature.svg";
import Skeleton from "react-loading-skeleton";
// import useSaveFhir from "./hooks/useSaveFhir";

const TemplatePreview = () => {
  const navigate = useNavigate();
  const prescriptionData = useSelector((state) => state.prescriptionData);
  const contentRef = useRef(null);
  const templateState = useLocation();
  const { state } = templateState || {};
  const { withHeader } = state || {};

  const {
    chiefComplaint = "",
    diagnosis = [],
    rxList = [],
    labInvestigations = [],
    instructions = [],
    lifeStyleRecommendations = [],
    followUp = "",
    followUpDate = "",
    stamp = false,
    signature = false,
    medicalHandoubts = [],
  } = prescriptionData || {};

  const patientData = useSelector((state) => state.patientDetails);

  const { abhaId, age, mrNo, gender, name, phoneNumber, visitId } =
    patientData || {};

  const { onSavePrescription, isPrescriptionSaving } = useSavePrescription({
    method: "post",
    contentRef,
  });

  const { allHandoubts, isAllHandoubtsLoading } = useGetAllHandoubt();

  // const { onSaveIntoFHIR, isSaving } = useSaveFhir({ contentRef });

  // const handleDownload = async () => {
  //   const element = contentRef.current;
  //   const opt = {
  //     margin: 6,
  //     filename: "prescription.pdf",
  //     image: { type: "jpeg", quality: 1 },
  //     html2canvas: { scale: 2, useCORS: true },
  //     jsPDF: {
  //       orientation: "p",
  //       unit: "mm",
  //       format: "a4",
  //       putOnlyUsedFonts: true,
  //       floatPrecision: 16, // or "smart", default is 16
  //     },
  //   };
  //   html2pdf().set(opt).from(element).save();
  // };

  return (
    <div className="bg-[#111928] w-full overflow-y-scroll flex flex-col items-center justify-center">
      <ToastContainer />
      <div
        className="bg-white mt-10 mb-8 px-6 py-10"
        style={{
          width: "210mm", // A4 width
          minHeight: "297mm", // A4 height
        }}
      >
        <div ref={contentRef} className="text-[#1F2A37] relative text-sm">
          <div className="h-20">
            {withHeader ? (
              <div className="flex w-full">
                <div className="flex w-[60%]">
                  <div className="w-[20%] flex justify-center">
                    <img src={ParchaaLogo} alt="ParchaaLogo" />
                  </div>
                  <div className="w-[80%] mx-8">
                    <div className="font-semibold">Dr. Saumya Rawat</div>
                    <div className="font-light">
                      <div>General Physician</div>
                      <div>Reg.No: PSI2001144</div>
                    </div>
                  </div>
                </div>
                <div className="w-[41%]">
                  <div className="font-semibold">Parchaa Healthcare AI</div>
                  <div className="font-light flex text-sm">
                    C 9, SDA Market Opposite IIT Gate, Block C 5 Safdarjung
                    Development Area, Hauz Khas, New Delhi, Delhi 110016, India.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <hr className="my-5"></hr>
          <div className="font-semibold">Patient Details: </div>
          <div className=" flex justify-between">
            <div>
              <div>
                <span>Patient Name:</span>
                <span className="font-light ms-1">
                  {firstLetterCapital(name)}
                </span>
              </div>
              <div>
                <span>Age:</span>
                <span className="font-light ms-1">{age} Years</span>
              </div>
              <div>
                <span>Gender:</span>
                <span className="font-light ms-1">
                  {firstLetterCapital(gender)}
                </span>
              </div>
              {abhaId !== null && (
                <div>
                  <span>ABHA Address:</span>
                  <span className="font-light ms-1">{abhaId || "-"}</span>
                </div>
              )}
            </div>
            <div>
              <div>
                <span>Date:</span>
                <span className="font-light ms-1">
                  {formatTimeWithDay(new Date())}, {formatDateTime(new Date())}
                </span>
              </div>
              <div>
                <span>Time:</span>
                <span className="font-light ms-1">
                  {formatTime(new Date())}
                </span>
              </div>
              <div>
                <span>Phone:</span>
                <span className="font-light ms-1">{phoneNumber || "-"}</span>
              </div>
              <div>
                <span>MR No.:</span>
                <span className="font-light ms-1">{mrNo || "-"}</span>
              </div>
            </div>
          </div>

          <div className="flex my-2">
            <div className="font-semibold">Chief Complaint:</div>
            <span className="font-light gap-2 flex ms-1">{chiefComplaint}</span>
          </div>
          {diagnosis?.length > 0 && (
            <div className="flex my-2">
              <div className="font-semibold">Diagnosis:</div>
              <span className="font-light flex-wrap gap-2 flex ms-1">
                {(diagnosis || []).map((compliants, index) => (
                  <span className="flex flex-wrap" key={index}>
                    {startCase(compliants)}
                    <span
                      className={`${
                        diagnosis.length - 1 === index ? "hidden" : ""
                      }`}
                    >
                      ,
                    </span>
                  </span>
                ))}
              </span>
            </div>
          )}
          {rxList[0].drugName !== "" && (
            <div className="my-4">
              <div className="flex w-full bg-[#F3F4F6] py-2 px-4">
                <div className="w-[50%]">Medicine</div>
                <div className="w-[20%]">Timing</div>
                <div className="w-[20%]">Frequency</div>
                <div className="w-[10%]">Duration</div>
              </div>
              {rxList.map((item, index) => {
                const {
                  drugName,
                  dose,
                  duration,
                  frequency,
                  measurement,
                  timing,
                  notes,
                  companyLingo,
                } = item || {};
                return (
                  <div
                    className="border-b w-full text-sm px-4 py-2  justify-start font-light"
                    key={index}
                  >
                    <div className="flex w-full gap-2">
                      <div className="w-[50%] pe-4">
                        <div
                          className={`gap-1  ${
                            drugName.length < 30 ? "flex flex-wrap" : ""
                          } items-center`}
                        >
                          <span className="font-medium flex flex-wrap">
                            {companyLingo === "" || companyLingo === null
                              ? drugName
                              : companyLingo}
                          </span>
                          <span>{dose}</span>
                          <span>{measurement}</span>
                        </div>
                      </div>
                      <div className="w-[20%] pe-4">{startCase(timing)}</div>
                      <div className="w-[20%] pe-4">{frequency}</div>
                      <div className="w-[10%]">{duration}</div>
                    </div>
                    {companyLingo === "" || companyLingo === null ? null : (
                      <div>
                        <span className="w-[10%]"> Composition</span>:{" "}
                        {drugName} {dose} {measurement}
                      </div>
                    )}
                    {notes === "" || notes === null ? null : (
                      <div className="w-full">
                        <span>Note</span>: {notes}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {labInvestigations?.length > 0 && (
            <div className="my-2 gap-2">
              <div className="font-semibold">Lab Investigations: </div>
              <div className="font-light">
                {(labInvestigations || []).map((item, index) => (
                  <div className="" key={index}>
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}

          {lifeStyleRecommendations !== "" && (
            <div className="my-2">
              <div className="font-semibold">Lifestyle Recommendations:</div>
              <div className="font-light ">
                {/* {(instructions.trim().split("\n") || []).map((item, index) => ( */}
                {(lifeStyleRecommendations || []).map((item, index) => (
                  <div className="flex items-center" key={index}>
                    {/* <span className="bg-[#374151] me-1.5 flex rounded-full h-1 w-1"></span> */}
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {instructions !== "" && (
            <div className="my-2">
              <div className="font-semibold">Admission Advice:</div>
              {/* <span className="font-light gap-2 flex ms-1">{notes}</span> */}
              <div className="font-light ">
                {/* {(instructions?.trim().split("\n") || []).map((item, index) => ( */}
                {(instructions || []).map((item, index) => (
                  <div className="flex items-center" key={index}>
                    {/* <span className="bg-[#374151] me-1.5 flex rounded-full h-1 w-1"></span> */}
                    {startCase(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {followUp !== "" && (
            <div className="my-2 gap-2">
              <div className="font-semibold">Follow Up:</div>
              <div className="font-light ">
                {followUp} ({moment(followUpDate).format("DD-MM-yyyy")})
              </div>
            </div>
          )}
          <div className=" flex justify-end">
            {stamp ? (
              <div className="flex relative justify-end">
                <img src={stampIcon} alt="stamp" height={100} width={100} />
              </div>
            ) : null}
            {signature ? (
              <div className="flex absolute bottom-0 justify-end">
                <img src={signatureIcon} alt="signature" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {medicalHandoubts.length > 0 ? (
        <div className="w-full pb-24 flex justify-center items-center">
          {isAllHandoubtsLoading ? (
            <Skeleton height={300} width={700} />
          ) : (
            <iframe
              src={allHandoubts}
              width={800}
              height={1000}
              title="medical handouts"
            />
          )}
        </div>
      ) : null}
      <div
        style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
        className="flex py-4 bg-white w-full justify-between px-4 fixed bottom-0"
      >
        <button
          onClick={() =>
            navigate("/doctor/createPrescription", {
              state: { visitId: visitId },
            })
          }
          className="flex border items-center justify-center bg-white
        border-[#E5E7EB] item-center px-4 py-2 rounded-lg text-sm
        text-[#1F2A37]"
        >
          Cancel
        </button>
        <button
          disabled={isPrescriptionSaving}
          className="bg-[#1A56DB] disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] flex justify-center border border-[#1A56DB] item-center px-4 py-2 rounded-lg text-sm text-[#FFFFFF]"
          onClick={() => onSavePrescription(mrNo)}
          // onClick={handleDownload}

          // onClick={onSaveIntoFHIR}
          // disabled={isSaving}
        >
          Generate PDF
        </button>
      </div>
    </div>
  );
};

export default memo(TemplatePreview);

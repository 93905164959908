import React, { useState } from "react";
import Modal from "../../../../../common-components/Modal";

const AddSpeciality = ({ isAddSpeciality, setIsAddSpeciality }) => {
  const [addSpeciality, setAddSpeciality] = useState({ speciality: "" });
  const handleChange = (e) => {
    const { value, name } = e.target || {};
    setAddSpeciality({ [name]: value });
  };

  return (
    <div>
      <Modal
        showModal={isAddSpeciality}
        modalWidth="w-1/2"
        modalHeight="min-h-1/2"
        onClose={() => setIsAddSpeciality(false)}
      >
        <div className="px-5 py-3 font-semibold text-[#222222]">Speciality</div>
        <div className="px-5">
          <div className="text-sm w-full mb-2 flex gap-4">
            <div className="mt-1 w-1/2 gap-5 flex">
              <input
                name="speciality"
                value={addSpeciality.speciality}
                onChange={handleChange}
                type="text"
                placeholder="Speciality"
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="flex py-5 text-sm justify-between">
            <button
              onClick={() => setIsAddSpeciality(false)}
              className="border-[#D1D5DB] border rounded-lg text-[#444444] px-5 py-2"
            >
              Cancel
            </button>
            <button
              // onClick={() => onAddExperiance(addExperiance)}
              // disabled={isAddExperiance}
              className="bg-[#3F83F8] rounded-lg disabled:text-[#6B7280] text-white px-5 py-2"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddSpeciality;

import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { startCase } from "../../../../utils/startCase";
import ProfileImage from "../../../../../images/jpk.jpg";

const STATUS_COLOR_MAPPING = {
  "Follow-up": "bg-[#DEF7EC] text-[#03543F]",
  "Blood Result": "bg-[#EDEBFE] text-[#5521B5]",
  CANCELLED: "bg-#FDE8E8 text-[#9B1C1C]",
  "Awaiting Intake": "bg-[#FDF6B2] text-[#723B13]",
};

const NOTES_LIST = [
  {
    title: "Intake form",
    addedBy: "Dr. saumya rawat",
    addedAt: "02/07/2024",
    tags: ["Blood Result", "Follow-up"],
    description:
      "This information will help in understanding your past and present difficulties to develop an appropriate treatment plan. You may choose to decline to...",
  },
  {
    title: "Intake form",
    addedBy: "Dr. saumya rawat",
    addedAt: "02/07/2024",
    tags: ["Blood Result", "Follow-up"],
    description:
      "This information will help in understanding your past and present difficulties to develop an appropriate treatment plan. You may choose to decline to...",
  },
  {
    title: "Intake form",
    addedBy: "Dr. saumya rawat",
    addedAt: "02/07/2024",
    tags: ["Awaiting Intake", "Follow-up"],
    description:
      "This information will help in understanding your past and present difficulties to develop an appropriate treatment plan. You may choose to decline to...",
  },
  {
    title: "Intake form",
    addedBy: "Dr. saumya rawat",
    addedAt: "02/07/2024",
    tags: ["Blood Result", "Follow-up"],
    description:
      "This information will help in understanding your past and present difficulties to develop an appropriate treatment plan. You may choose to decline to...",
  },
];

const NotesList = () => {
  return (
    <div className="px-4 w-full gap-4 flex pb-6">
      {NOTES_LIST.map((item, index) => {
        return (
          <div key={index} className="text-sm w-1/4 border rounded p-2">
            <div className="text-[#111928] text-lg font-medium">
              {item.title}
            </div>
            <div className="flex text-sm items-center text-[#6B7280]">
              Added by
              <span className="text-[#1A56DB] mx-1">{item.addedBy},</span>
              {item.addedAt}
            </div>
            <div className="flex gap-2 my-2">
              {item.tags.map((tag, tagindex) => (
                <div key={tagindex}>
                  <span
                    className={`${STATUS_COLOR_MAPPING[tag]} px-2 py-1 rounded-lg`}
                  >
                    {startCase(tag)}
                  </span>
                </div>
              ))}
            </div>
            <div className="text-lg flex items-center gap-2 text-[#111928]">
              <FontAwesomeIcon icon={faFileLines} /> Description
            </div>
            <div className="text-[#6B7280] text-sm font-light">
              This information will help in understanding your past and present
              difficulties to develop an appropriate treatment plan. You may
              choose to decline to...
            </div>
            <div className="my-2 text-sm">
              <button className="text-[#1A56DB]">View Details</button>
            </div>
            <div className="flex items-center gap-2 w-full">
              <span>
                <img
                  src={ProfileImage}
                  alt="ProfileImage"
                  className="rounded-full flex h-10 w-10 object-cover"
                />
              </span>
              <span className="text-[#6B7280]  font-light text-xs">
                Viewable by Medical Staff
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NotesList;

import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useAddImmunisationRecord = ({
  immunisationInfo = {},
  getAvailableRecord = () => {},
  setActiveHealthTab = () => {},
}) => {
  const [isAddImmunisationLoading, setIsAddImmunisationLoading] =
    useState(false);
  const {
    dateOfVaccination,
    vaccineCode,
    manufacturer,
    lotNumber,
    doseNumber,
    route,
  } = immunisationInfo || {};
  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, chiefComplaint } = patientData || {};

  const onAddImmunisation = async () => {
    setIsAddImmunisationLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.post(
        `/v2/danzo/immunization-records`,
        {
          mrNo: mrNo,
          dateOfVaccination: dateOfVaccination,
          vaccineCode: vaccineCode,
          manufacturer: manufacturer,
          lotNumber: lotNumber,
          doseNumber: doseNumber,
          route: route,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      await axios.post(
        `/v2/danzo/record/create`,
        {
          patientMrNo: mrNo,
          careContexts: [
            {
              fhirId: res.data.id,
              hiType: "ImmunizationRecord",
              display: "Immunisation Record",
            },
          ],
          visitDate: moment(new Date()).format("yyyy-MM-DD"),
          hospitalName: "Parchaa Healthcare AI",
          chiefComplaint: chiefComplaint,
        },
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );

      toast.success("Immunisation record is submitted successfully !");
      setTimeout(() => {
        getAvailableRecord();
        setActiveHealthTab("");
      }, 1000);

      setIsAddImmunisationLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsAddImmunisationLoading(false);
  };

  return { isAddImmunisationLoading, onAddImmunisation };
};

export default useAddImmunisationRecord;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LandingPage from "./page-components/components/landing-page/LandingPage";
import Welcome from "./page-components/components/welcome/Welcome";
import Doctor from "./page-components/components/doctor/Doctor";
import CreatePrescription from "./page-components/components/doctor/create-prescription/CreatePrescription";
import TemplatePreview from "./page-components/components/doctor/create-prescription/TemplatePreview";
import MyAccount from "./page-components/components/hip/my-account/MyAccount";
import PatientProfile from "./page-components/components/hip/patient-profile/PatientProfile";
import Settings from "./page-components/components/hip/settings/Settings";
import HealthPage from "./page-components/utils/health";

const Routing = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/doctor" element={<Doctor />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/health" element={<HealthPage />} />

          <Route
            path="/doctor/createPrescription"
            element={<CreatePrescription />}
          />
          <Route path="/templatePreview" element={<TemplatePreview />} />
          <Route path="/myAccount" element={<MyAccount />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/patientProfile" element={<PatientProfile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Routing;

import React from "react";
import Modal from "../../../../../common-components/Modal";
import backArrow from "../../../../../../images/back_arrow.svg";
import {
  formatDateTime,
  formatTime,
} from "../../../../../utils/formatDateTime";

const SelectedWellnessRecord = ({
  wellnessData,
  isSelectedRecord,
  setIsSelectedRecord,
  name,
}) => {
  const {
    dateCreated,
    breathRate,
    diastolicPressure,
    doYouSmoke,
    height,
    hoursOfSleep,
    systolicPressure,
    temperature,
    weight,
  } = wellnessData || {};

  return (
    <div>
      <Modal
        showModal={isSelectedRecord}
        onClose={() => setIsSelectedRecord(false)}
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
      >
        <div className="m-4">
          <div className="flex justify-between w-[60%]">
            <img src={backArrow} alt="backArrow" />
            <div>{name} (Wellness Record)</div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Vitals</div>
            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex justify-between mx-3">
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Height : </div>
              <div>{height}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Weight</div>
              <div>{weight}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Temperature</div>
              <div>{temperature}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Breath Rate</div>
              <div>{breathRate}</div>
            </div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Blood Pressure</div>
            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex mx-3">
            <div className="flex w-1/2 gap-3">
              <div className="text-[#5E6066] ">Systolic Pressure : </div>
              <div>{systolicPressure}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">Diastolic Pressure</div>
              <div>{diastolicPressure}</div>
            </div>
          </div>
          <div className="p-2 my-3 flex bg-[#ECECED] rounded">
            <div className="w-1/2">Lifestyle</div>
            <span>
              {formatDateTime(dateCreated)} | {formatTime(dateCreated)}
            </span>
          </div>
          <div className="flex mx-3">
            <div className="flex w-1/2 gap-3">
              <div className="text-[#5E6066] ">Do you smoke : </div>
              <div>{doYouSmoke ? "Yes" : "No"}</div>
            </div>
            <div className="flex gap-3">
              <div className="text-[#5E6066] ">
                How many hours you sleep? :{" "}
              </div>
              <div>{hoursOfSleep}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectedWellnessRecord;

import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  initialState,
  setPrescriptionData,
} from "../../../../../redux-store/slice/prescriptionDataSlice";

const useEndVisit = ({ onClose = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEndVisitLoading, setIsEndVisitLoading] = useState(false);
  const visitId = useSelector((state) => state.patientDetails.visitId);
  const onEndVisit = async (e, status) => {
    e.preventDefault();
    setIsEndVisitLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        `/v2/shizune/token/editStatus/${visitId}/${status}`,
        {
          status: "COMPLETED",
          id: visitId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Visit Ended Successfully !");
      setTimeout(() => {
        onClose();
        dispatch(setPrescriptionData(initialState));
        navigate("/welcome");
      }, 1000);
    } catch (error) {
      // toast.error("Oops somthing went wrong ! ", error);
      console.error(error, "error");
    }
    setIsEndVisitLoading(false);
  };
  return {
    onEndVisit,
    isEndVisitLoading,
  };
};

export default useEndVisit;

import React from "react";
import Modal from "../../../../common-components/Modal";
import CreateNewNote from "../../../doctor/side-bar/CreateNewNote";

const CreateNewNoteModal = ({ createNewNoteModal, setCreateNewNoteModal }) => {
  return (
    <div>
      <Modal
        showModal={createNewNoteModal}
        onClose={() => setCreateNewNoteModal(false)}
        modalWidth="w-[70%]"
        modalHeight="h-min-[50%]"
      >
        <div className="m-5">
          <CreateNewNote />
        </div>
      </Modal>
    </div>
  );
};

export default CreateNewNoteModal;

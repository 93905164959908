import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useGetConsentRecord = () => {
  const patientData = useSelector((state) => state.patientDetails);

  const { abhaId } = patientData || {};

  const [consentData, setConsentData] = useState([]);
  const [isConsentDataLoading, setIsConsentDataLoading] = useState(false);

  const getConsentData = useCallback(async () => {
    setIsConsentDataLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/m3/consent/${abhaId}`, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      setConsentData(res.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsConsentDataLoading(false);
  }, [abhaId]);

  useEffect(() => {
    if (abhaId !== null) {
      getConsentData();
    }
  }, [abhaId, getConsentData]);

  return { consentData, isConsentDataLoading, getConsentData };
};

export default useGetConsentRecord;

import React, { useState } from "react";
import InvoiceList from "./InvoiceList";
import SearchInput from "../../../../common-components/SearchInput";

const Invoice = () => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    const { value } = e.target || {};
    setInputValue(value);
  };

  return (
    <div
      className="m-8 pt-4 rounded-md"
      style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
    >
      <div>
        <div className="w-[30%] px-4">
          <SearchInput inputValue={inputValue} onChange={handleChange} />
        </div>
        <div className="pt-4">
          <InvoiceList />
        </div>
      </div>
    </div>
  );
};

export default Invoice;

import React from "react";

const ShortCutInfo = () => {
  return (
    <div className="bg-white text-sm p-3 pt-1 border rounded-lg">
      <p className="font-medium mb-0.5">Quick Access Shortcuts</p>

      <div className="flex">
        <div className="me-1">control + t : </div>
        <div className="ms-1"> Open Template</div>
      </div>
      <div className="flex">
        <div className="me-1"> control + m : </div>
        <div className="ms-1">Add Medication Field</div>
      </div>
      <div className="flex">
        <div className="me-1">control + s : </div>
        <div className="ms-1">Save Template </div>
      </div>
      <div className="flex">
        <div className="me-1">control + p : </div>
        <div className="ms-1">Preview with Header</div>
      </div>
      <div className="flex">
        <div className="me-1">control + c : </div>
        <div className="ms-1">Clear All</div>
      </div>
    </div>
  );
};

export default ShortCutInfo;

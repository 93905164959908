import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsSaveTemplate } from "../../../../../redux-store/slice/saveTemplateModal";

const useSaveTemplate = () => {
  const dispatch = useDispatch();
  const prescriptionData = useSelector((state) => state.prescriptionData);
  const [templateData, setTemplateData] = useState({});
  const [isSaveTemplateLoading, setIsSaveTemplateLoading] = useState(false);

  const onSaveTemplate = async (e) => {
    e.preventDefault();
    setIsSaveTemplateLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const res = await axios.post(
        `/v2/scroll/customTemplate`,
        prescriptionData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTemplateData(res.data);
      toast.success("Template saved successfully !", {
        position: "top-center",
      });
      setTimeout(() => {
        dispatch(setIsSaveTemplate(false));
      }, 2000);
    } catch (error) {
      toast.error("Oops somthing went wrong", {
        position: "top-center",
      });
      console.error(error, "error");
    }
    setIsSaveTemplateLoading(false);
  };

  return {
    onSaveTemplate,
    templateData,
    isSaveTemplateLoading,
  };
};

export default useSaveTemplate;

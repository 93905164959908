import React, { memo } from "react";
import patientPhoto from "../../../../images/Avatar.png";
import { faFileShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { REACT_APP_ABHA_ADDRESS_SUFFIX } = process.env;

const PatientTab = ({
  patientData,
  activeProfileTab,
  setActiveProfileTab,
  setRequestConsent,
}) => {
  const {
    abhaId,
    name = "",
    // mail,
    // gender,
    // patientMRNo,
    // chiefComplaint,
    // age,
    // bloodGroup,
    // addressLine,
  } = patientData || {};
  return (
    <div
      className="m-8 px-4 pt-4 rounded-md"
      style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
    >
      <div className="w-full justify-between flex">
        <div className="ms-4 gap-5 flex">
          <img src={patientPhoto} alt="patientPhoto" height={100} width={100} />
          <div>
            <div className="text-[#111928] flex gap-2 items-center text-lg font-semibold">
              {name}
            </div>
            <div className="text-[#4B5563] font-normal">
              {abhaId ||
                `${name
                  ?.toLowerCase()
                  .replace(/\s+/g, "")}${REACT_APP_ABHA_ADDRESS_SUFFIX}`}
            </div>
          </div>
        </div>
        <div className="gap-4 mt-3">
          <button
            onClick={() => setRequestConsent(true)}
            className=" bg-[#1C64F2] disabled:bg-[#E5E7EB] disabled:border-[#E5E7EA] disabled:text-[#1F2A37] border border-[#1A56DB] px-3 py-1.5 rounded-lg text-sm text-[#FFFFFF]"
          >
            <FontAwesomeIcon icon={faFileShield} className="me-2" />
            Request Consent
          </button>
        </div>
      </div>
      <div className="mt-2 ms-3">
        <button
          className={`${
            activeProfileTab === "Overview"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("Overview")}
        >
          Overview
        </button>
        <button
          className={`${
            activeProfileTab === "xray"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("xray")}
        >
          Images & X-Ray
        </button>
        <button
          className={`${
            activeProfileTab === "Medications"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("Medications")}
        >
          Medications
        </button>
        <button
          className={`${
            activeProfileTab === "Invoice"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("Invoice")}
        >
          Invoice
        </button>
        <button
          className={`${
            activeProfileTab === "abha"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("abha")}
        >
          Consent List
        </button>
        <button
          className={`${
            activeProfileTab === "Notes"
              ? "text-[#1C64F2] border-b border-[#1C64F2]"
              : "text-[#6B7280]"
          } text-sm w-[15%] me-3 py-3`}
          onClick={() => setActiveProfileTab("Notes")}
        >
          Notes
        </button>
      </div>
    </div>
  );
};

export default memo(PatientTab);

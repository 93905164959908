import axios from "axios";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatientVisitList } from "../../../../../../redux-store/slice/patientVisitList";
import { toast } from "react-toastify";
import { filterListContext } from "../HIPdashboard";

const useGetAllVisit = () => {
  const dispatch = useDispatch();
  const currentDate = useSelector((state) => state.patientVisitDate);
  const [isPatientVisitLoading, setIsPatientVisitLoading] = useState(true);
  const filterState = useContext(filterListContext);
  const { selectedFilters } = filterState || {};
  const {
    visitStatus = [],
    visitType = [],
    tags = [],
    department = [],
    serviceType = [],
  } = selectedFilters || {};

  const params = useMemo(() => {
    const searchParams = new URLSearchParams();
    const appendArrayToParams = (key, array) => {
      array.forEach((item) => {
        searchParams.append(key, item.value);
      });
    };
    searchParams.append("visitDate", currentDate);
    appendArrayToParams("visitStatus", visitStatus);
    appendArrayToParams("visitType", visitType);
    appendArrayToParams("tags", tags);
    appendArrayToParams("department", department);
    appendArrayToParams("serviceType", serviceType);

    return searchParams;
  }, [currentDate, visitStatus, visitType, tags, department, serviceType]);

  const getAllPatientVisit = useCallback(async () => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/shizune/token/search`, {
        params: params,
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      dispatch(setPatientVisitList(res.data));
    } catch (error) {
      toast.error("Oops somthing went wrong !", error);
      console.log(error, "error");
      dispatch(setPatientVisitList([]));
    }
    setIsPatientVisitLoading(false);
  }, [dispatch, params]);

  return {
    isPatientVisitLoading,
    getAllPatientVisit,
    params,
  };
};

export default useGetAllVisit;

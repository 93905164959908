import React from "react";

const SocialHistory = () => {
  return (
    <div className="w-full">
      <div className="p-2 font-light">No Data Found !</div>
      {/* <div className="w-full mt-3 text-[#374151] text-sm">
        <div className="text-[#111928] font-medium">
          <div>Smoking</div>
        </div>
        <div className=" font-light">Every day | 2 PPD | 40 packs-year</div>
      </div>
      <div className="w-full my-2 text-[#374151] text-sm">
        <div className="text-[#111928] font-medium">
          <div>Smokeless Tobacco</div>
        </div>
        <div className=" font-light">Unknown</div>
      </div>
      <div className="w-full py-1 text-[#374151] text-sm">
        <div className="text-[#111928] font-medium">
          <div>Alcohol</div>
        </div>
        <div className=" font-light">6.0 Standard drinks / week</div>
      </div> */}
    </div>
  );
};

export default SocialHistory;

import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useGetSelectedOPconsultation = ({ setIsSelectedRecord = () => {} }) => {
  const [opConsultation, setOPconsultation] = useState({});
  const [isOPselectedOPconsultationLoading, setIsOPconsultationLoading] =
    useState(false);

  const getOPconsulation = async (id) => {
    setIsOPconsultationLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/op-consultations/${id}`, {
        headers: { Authorization: token, "ngrok-skip-browser-warning": "hgs" },
      });
      setOPconsultation(res.data);
      setIsSelectedRecord(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsOPconsultationLoading(false);
  };

  return {
    opConsultation,
    isOPselectedOPconsultationLoading,
    getOPconsulation,
  };
};

export default useGetSelectedOPconsultation;

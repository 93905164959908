import React, { useContext, useState } from "react";
import { Context } from "../DoctorAccount";
import stethoscope from "../../../../../../images/stethoscope--instrument-health-medical-stethoscope.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DeleteExperienceModal from "./DeleteExperienceModal";

const Experience = () => {
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};
  const { professionalDeatils } = doctorData || {};
  const { experiences = [] } = professionalDeatils || {};
  const [isDeleteExperiance, setIsDeleteExperience] = useState(false);

  return (
    <div>
      {experiences.map((item) => {
        const { id, title, startYear, endYear, location } = item || {};
        return (
          <div
            key={id}
            className="flex last:border-none py-4 border-b items-center w-full"
          >
            <div className="flex w-2/3 gap-5 items-center">
              <img src={stethoscope} alt="stethoscope" />
              <div className="">
                <div className="text-[#111928] text-sm font-medium">
                  {title}
                </div>
                <div className="text-[#6B7280] text-sm font-light">
                  {location}
                </div>
              </div>
            </div>
            <div className="w-1/3 flex items-center text-sm text-[#6B7280]">
              {startYear} - {endYear}
            </div>
            <div className="flex gap-4">
              <button className="flex items-center active:animate-bounce justify-center bg-white item-center px-2 py-2 rounded-lg text-sm text-[#1F2A37]">
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button
                className="px-2.5 py-1.5 active:animate-bounce rounded-lg"
                onClick={() => setIsDeleteExperience(true)}
              >
                <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
              </button>
            </div>
            {isDeleteExperiance && (
              <DeleteExperienceModal
                onClose={() => setIsDeleteExperience(false)}
                showModal={isDeleteExperiance}
                id={id}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Experience;

import React from "react";
import SecondaryButton from "../../../../common-components/Buttons/SecondaryButton";

const HealthRecordButton = ({ setActiveHealthTab = () => {} }) => {
  return (
    <div
      className="bg-white p-4 rounded-lg text-sm overflow-x-scroll"
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="text-[#2D2E33] font-medium mb-2">Add New Record:</div>
      <div className="flex flex-col gap-2">
        <SecondaryButton
          onClick={() => setActiveHealthTab("wellness_record")}
          width="min-w-40"
          showIcon
          buttonName="Wellness Record"
          paddingX=""
        />
        <SecondaryButton
          width="min-w-40"
          onClick={() => setActiveHealthTab("health_document")}
          showIcon
          buttonName="Health Document"
          paddingX=""
        />
        <SecondaryButton
          onClick={() => setActiveHealthTab("add_immunisation_record")}
          showIcon
          buttonName="Immunisation"
          width="min-w-36"
          paddingX=""
        />
        <SecondaryButton
          showIcon
          onClick={() => setActiveHealthTab("op_consultation")}
          buttonName="OP Consultation"
          width="min-w-40"
          paddingX=""
        />
        <SecondaryButton
          showIcon
          buttonName="Diagnostic Report"
          onClick={() => setActiveHealthTab("diagnostic_report")}
          width="min-w-40"
          paddingX=""
        />
        <SecondaryButton
          showIcon
          buttonName="Discharge Summary"
          onClick={() => setActiveHealthTab("discharge_summary")}
          width="min-w-44"
          paddingX=""
        />
      </div>
    </div>
  );
};

export default HealthRecordButton;

import React from "react";

const Immunization = () => {
  return (
    <div className="w-full">
      <div className="p-2 font-light">No Data Found !</div>
      {/* <div className="w-full text-[#6B7280] font-light flex mb-1 mt-2">
        <div className="w-1/3"></div>
        <div className="w-1/3">Date</div>
        <div className="w-1/3">Next Due</div>
      </div>
      <div className="w-full flex">
        <div className="w-1/3 text-[#1F2A37] font-medium text-sm">
          Influenza Adult
        </div>
        <div className="w-1/3 text-[#1F2A37] font-medium text-sm">
          30/05/2024
        </div>
        <div className="w-1/3  text-[#E02424] font-medium text-sm">
          10/06/2024
        </div>
      </div>
      <div className="w-full flex pt-3 ">
        <div className="w-1/3  text-[#1F2A37] font-medium text-sm">
          Pfizer COVID-19
        </div>
        <div className="w-1/3  text-[#1F2A37] font-medium text-sm">
          30/05/2024
        </div>
        <div className="w-1/3 text-[#E02424] font-medium text-sm">
          10/06/2024
        </div>
      </div> */}
    </div>
  );
};

export default Immunization;

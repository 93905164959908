import React from "react";

const Allergies = () => {
  return (
    <div>
      {/* <div className="pt-2 flex items-center">
        Sulfa
        <span className="ms-1 font-light">(Sulfonamide Antibiotics)</span>
      </div>
      <div className="pt-3 flex items-center">
        Swelling <span className="ms-1 font-light">(Other), Rash</span>
      </div> */}
      <div className="p-2 font-light">No Data Found !</div>
    </div>
  );
};

export default Allergies;

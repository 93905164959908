import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useLinkingTokenToABHA = () => {
  const [linkingStatus, setLinkingStatus] = useState({});
  const [isLinkingStatusLoading, setIsLinkingStatusLoading] = useState(false);
  const [isLinkingToken, setIsLinkingToken] = useState(false);

  const patientData = useSelector((state) => state.patientDetails);
  const { mrNo, abhaId } = patientData || {};

  const getLinkingStatus = useCallback(async () => {
    setIsLinkingStatusLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(`/v2/danzo/m2/linkingToken/status/${mrNo}`, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });

      setLinkingStatus(res.data);
    } catch (error) {
      console.log(error, "error jp");
      toast.error(error?.response?.data?.message);
    }
    setIsLinkingStatusLoading(false);
  }, [mrNo]);

  const onGenerateToken = useCallback(async () => {
    setIsLinkingToken(true);
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        `/v2/danzo/m2/linkingToken/generate/${mrNo}`,
        undefined,
        {
          headers: {
            Authorization: token,
            "ngrok-skip-browser-warning": "hgs",
          },
        }
      );
      getLinkingStatus();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsLinkingToken(false);
  }, [mrNo, getLinkingStatus]);

  useEffect(() => {
    if (abhaId !== null) {
      getLinkingStatus();
    }
  }, [abhaId, getLinkingStatus]);

  return {
    linkingStatus,
    isLinkingStatusLoading,
    isLinkingToken,
    onGenerateToken,
  };
};

export default useLinkingTokenToABHA;

export const BLOOD_GROUP_OPTIONS = [
  { label: "O Negative (O-)", value: "O-" },
  { label: "O Positive (O+)", value: "O+" },
  { label: "A Negative (A-)", value: "A-" },
  { label: "A Positive (A+)", value: "A+" },
  { label: "B Negative (B-)", value: "B-" },
  { label: "B Positive (B+)", value: "B+" },
  { label: "AB Negative (AB-)", value: "AB-" },
  { label: "AB Positive (AB+)", value: "AB+" },
  { label: "Don't Know", value: "Don't Know" },
];

export const CITY_OPTIONS = [
  {
    lab: "Ghazipur",
    val: "GCT",
  },
  {
    lab: "Varansi",
    val: "BSB",
  },
  {
    lab: "Kolkata",
    val: "KOAA",
  },
  {
    lab: "Delhi",
    val: "ANBT",
  },
];

export const MARRITAL_STATUS = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Divorced",
    value: "divorced",
  },
  {
    label: "Widowed",
    value: "widowed",
  },
  {
    label: "Separated",
    value: "separated",
  },
  {
    label: "Unknown",
    value: "unknown",
  },
];

export const VIEW_MORE_OPTIONS = [
  { label: "View Details", value: "view_details" },
  { label: "Billing", value: "billing" },
  { label: "Invoice", value: "invoice" },
  { label: "Upload Medical Record", value: "upload_medical_record" },
  { label: "Refer a Doctor", value: "refer_a_doctor" },
  { label: "End Visit", value: "end_visit" },
  { label: "Reschedule", value: "reschedule" },
  { label: "Cancel Appointment", value: "cancel_appointment" },
];

export const TAGS = [
  // { label: "New Patient", value: "NEWPATIENT" },
  // { label: "Follow Up", value: "FOLLOWUP" },
  {
    label: "Undergoing Procedure",
    value: "UNDERGOINGPROCEDURE",
  },
  {
    label: "Priority",
    value: "PRIORITY",
  },
  {
    label: "To show report",
    value: "TOSHOWREPORT",
  },
  {
    label: "2nd Opinion",
    value: "SECONDOPINION",
  },
  {
    label: "Emergency",
    value: "EMERGENCY",
  },
];

export const VISIT_TYPE = [
  {
    label: "In-Person",
    value: "INPERSON",
  },
  {
    label: "Appointment",
    value: "APPOINTMENT",
  },
  {
    label: "Follow-Up",
    value: "FOLLOWUP",
  },
];

export const VISIT_STATUS = [
  { label: "Upcoming", value: "UPCOMING" },
  { label: "In-Queue", value: "INQUEUE" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Completed", value: "COMPLETED" },
  // { label: "No-Show", value: "NOSHOW" },
];

export const DEPARTMENT = [
  { label: "Anesthesiology", value: "ANESTHESIOLOGY" },
  // { label: "OPD", value: "OPD" },
  { label: "Cardiology", value: "CARDIOLOGY" },
  { label: "Dermatology", value: "DERMATOLOGY" },
  { label: "Emergency", value: "EMERGENCY" },
  { label: "Endocrinology", value: "ENDOCRINOLOGY" },
  { label: "Gastroenterology", value: "GASTROENTEROLOGY" },
  { label: "General", value: "GENERAL" },
  { label: "Neurology", value: "NEUROLOGY" },
  { label: "Onology", value: "ONCOLOGY" },
  { label: "Pediatrics", value: "PEDIATRICS" },
  { label: "Pulmonology", value: "PULMONOLOGY" },
];

export const SERVICE_TYPE = [
  {
    label: "Consultation",
    value: "CONSULTATION",
  },
  {
    label: "Out Patient (OPD)",
    value: "OPD",
  },
  {
    label: "In Patient",
    value: "INPATIENT",
  },
  {
    label: "Day care",
    value: "DAYCARE",
  },
];

export const CHIEF_COMPLAINTS = [
  {
    label: "Headache",
    value: "Headache",
  },
  {
    label: "Head Burn",
    value: "Head Burn",
  },
  {
    label: "Head Lice",
    value: "Head Lice",
  },
  {
    label: "Head Injury",
    value: "Head Injury",
  },
  { label: "Headache Disorder", value: "Headache Disorder" },
];

export const PATIENT_LIST = [
  {
    label: "Jai",
    value: "jai",
  },
  {
    label: "Jai verma",
    value: "Jai verma",
  },
  {
    label: "Jai verma",
    value: "jai_verma",
  },
  {
    label: "Jaiprakash",
    value: "jaiprakash",
  },
];

export const SELECT_AGE = [
  {
    label: "Years",
    value: "Years",
  },
  {
    label: "Month",
    value: "Month",
  },
  {
    label: "Day",
    value: "Day",
  },
];

export const MEDICATION_MEASUREMENT = [
  {
    label: "mg",
    value: "mg",
  },
  {
    label: "µg",
    value: "µg",
  },
  {
    label: "g",
    value: "g",
  },
  {
    label: "mL",
    value: "mL",
  },
  {
    label: "ng",
    value: "ng",
  },
];

export const TIMING = [
  {
    label: "After breakfast",
    value: "After breakfast",
  },
  {
    label: "Empty Stomach",
    value: "Empty Stomach",
  },
  {
    label: "After meal",
    value: "After meal",
  },
  {
    label: "Bed Time",
    value: "Bed Time",
  },
  { label: "SOS (when required)", value: "SOS (when required)" },
];

export const FREQUENCY_COUNT = [
  { label: "1-0-1", value: "1-0-1" },
  { label: "0-1-0", value: "0-1-0" },
  { label: "1-1-1", value: "1-1-1" },
  { label: "1-0-0", value: "1-0-0" },
  { label: "0-0-1", value: "0-0-1" },
  { label: "2-0-2", value: "2-0-2" },
  { label: "2-2-2", value: "2-2-2" },
  { label: "0-2-0", value: "0-2-0" },
  { label: "0-0-2", value: "0-0-2" },
  { label: "2-0-0", value: "2-0-0" },
  { label: "0-0-1/2", value: "0-0-1/2" },
  { label: "1/2-0-1/2", value: "1/2-0-1/2" },
  { label: "1/2-1/2-1/2", value: "1/2-1/2-1/2" },
  { label: "0-1/2-0", value: "0-1/2-0" },
  { label: "1/2-0-0", value: "1/2-0-0" },
];

export const DURATION = [
  { label: "1 day", value: "1 day" },
  { label: "5 days", value: "5 days" },
  { label: "7 days", value: "7 days" },
  { label: "2 days", value: "2 days" },
  { label: "3 days", value: "3 days" },
  { label: "4 days", value: "4 days" },
  { label: "6 days", value: "6 days" },
  { label: "8 days", value: "8 days" },
  { label: "9 days", value: "9 days" },
  { label: "10 days", value: "10 days" },
  { label: "11 days", value: "11 days" },
  { label: "12 days", value: "12 days" },
  { label: "13 days", value: "13 days" },
  { label: "14 days", value: "14 days" },
  { label: "15 days", value: "15 days" },
  { label: "20 days", value: "20 days" },
  { label: "30 days", value: "30 days" },
];

export const FOLLOW_UP_DAYS = [
  { label: "6 days", value: "6 days" },
  { label: "5 days", value: "5 days" },
  { label: "15 days", value: "15 days" },
  { label: "10 days", value: "10 days" },
  { label: "3 days", value: "3 days" },
];

export const LAB_TEST = [
  { label: "MRI", value: "MRI" },
  { label: "BLOOD", value: "BLOOD" },
  { label: "Dengoo", value: "Dengoo" },
  { label: "test1", value: "test1" },
];

export const DIFFERENTIAL_DIAGNOSIS = [
  { label: "Acute Appendicitis", value: "Acute Appendicitis" },
  { label: "Gastroenteritis", value: "Gastroenteritis" },
  { label: "Cholecystitis", value: "Cholecystitis" },
  { label: "Peptic Ulcer Disease", value: "Peptic Ulcer Disease" },
  { label: "Pancreatitis", value: "Pancreatitis" },
  { label: "Diverticulitis", value: "Diverticulitis" },
  { label: "Urinary Tract Infection", value: "Urinary Tract Infection" },
  { label: "Ectopic Pregnancy", value: "Ectopic Pregnancy" },
  { label: "Irritable Bowel Syndrome", value: "Irritable Bowel Syndrome" },
  { label: "Crohn's Disease", value: "Crohn's Disease" },
];

export const DOSE = [
  { label: "500", value: "500" },
  { label: "650", value: "650" },
  { label: "400", value: "400" },
  { label: "250", value: "250" },
];

export const MEDICATIONS = [
  { label: "Aspirin", value: "Aspirin" },
  { label: "Ibuprofen", value: "Ibuprofen" },
  { label: "Acetaminophen", value: "Acetaminophen" },
  { label: "Amoxicillin", value: "Amoxicillin" },
  { label: "Ciprofloxacin", value: "Ciprofloxacin" },
  { label: "Metformin", value: "Metformin" },
  { label: "Atorvastatin", value: "Atorvastatin" },
  { label: "Lisinopril", value: "Lisinopril" },
  { label: "Omeprazole", value: "Omeprazole" },
  { label: "Levothyroxine", value: "Levothyroxine" },
  { label: "Simvastatin", value: "Simvastatin" },
  { label: "Metoprolol", value: "Metoprolol" },
  { label: "Losartan", value: "Losartan" },
  { label: "Albuterol", value: "Albuterol" },
  { label: "Gabapentin", value: "Gabapentin" },
  { label: "Hydrochlorothiazide", value: "Hydrochlorothiazide" },
  { label: "Clopidogrel", value: "Clopidogrel" },
  { label: "Montelukast", value: "Montelukast" },
  { label: "Sertraline", value: "Sertraline" },
  { label: "Furosemide", value: "Furosemide" },
];

export const GENDER = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
  { label: "Others", value: "OTHER" },
];

export const DOCTOR_SPECIALIZATION = [
  "Cardiology",
  "Dermatology",
  "Neurology",
  "Pediatrics",
  "Orthopedics",
  "Ophthalmology",
  "Gynecology",
  "Oncology",
  "Psychiatry",
  "Radiology",
  "Endocrinology",
  "Gastroenterology",
  "Hematology",
  "Nephrology",
  "Pulmonology",
  "Rheumatology",
  "Urology",
  "Anesthesiology",
  "Emergency Medicine",
  "Family Medicine",
  "Pathology",
  "Plastic Surgery",
  "General Surgery",
  "Vascular Surgery",
  "Neurosurgery",
];

export const DOCTOR_LIST = [
  "Dr. Devi Shetty",
  "Dr. Naresh Trehan",
  "Dr. Randeep Guleria",
  "Dr. Arvind Kumar",
  "Dr. B. C. Roy",
  "Dr. Prathap C. Reddy",
  "Dr. K. M. Cherian",
  "Dr. Lalit Kant",
  "Dr. Ramanathan V. Lakshmanan",
  "Dr. A. Velumani",
  "Dr. Sudarshan Ballal",
  "Dr. Farokh Udwadia",
  "Dr. Shashank Joshi",
  "Dr. S. Natarajan",
  "Dr. Ajay Kumar",
  "Dr. Vandana Luthra",
  "Dr. Ashok Seth",
  "Dr. Y. K. Mishra",
  "Dr. Padmavati Iyer",
  "Dr. S. K. Sarin",
  "Dr. Mammen Chandy",
  "Dr. Alok Srivastava",
  "Dr. Rakesh Yadav",
  "Dr. Rajesh Parikh",
  "Dr. N. S. Laud",
];

export const CATEGORY = [
  "Progress Note",
  "Lab Results",
  "Diagnosis",
  "Prescription",
  "Consultation Notes",
  "Follow-up instructions",
  "Surgical notes",
  "Discharge Summary",
];
export const VIEW_ABLE_PERSON_LIST = [
  "Doctors",
  "Nurses",
  "Lab Technicians",
  "Patient",
  "Admins",
  "All Medical Staff",
];
